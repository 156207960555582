<div
  *ngrxLet="{
    language: facade.language$,
    teams: facade.teamsOptions$,
    loading: facade.loading$,
    weeklyMeetingOnlyFeature: facade.weeklyMeetingOnlyFeature$,
    allMeetingsFeature: facade.allMeetingsFeature$,
    isManageOrAbove: facade.isManageeOrAbove$,
  } as vm">
  <div class="card-wrp terrafy-main-content">
    <ninety-active-meeting></ninety-active-meeting>
  </div>

  <ninety-meeting-schedules></ninety-meeting-schedules>
</div>
