import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

/**
 * An item to be rendered in a grid layout. See GridLayoutComponent
 *
 * @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket.
 */
@Component({
  selector: 'ninety-grid-item',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="grid-stack-item-content">
      <div class="grid-item-scrollable-content">
        <ng-content></ng-content>
      </div>

      <footer class="grid-item-draggable-icon-footer"></footer>
    </div>
  `,
})
export class GridItemComponent implements OnInit {
  public static readonly GRID_ITEM_CSS_CLASS = 'grid-stack-item';

  constructor(private el: ElementRef, private renderer2: Renderer2) {}

  ngOnInit(): void {
    this.renderer2.addClass(this.el.nativeElement, GridItemComponent.GRID_ITEM_CSS_CLASS);
  }
}
