<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="rocks" />
      <span data-cy="rock-language_card-title">{{ rock.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="rock">
      <terra-form-field>
        <terra-form-label>Page Name</terra-form-label>
        <terra-text-input
          formControlName="route"
          required
          [attr.aria-label]="rock.route + ' Page Name'"
          data-cy="rock-language_page-name-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('route') !== resetLanguage?.route">
            <terra-icon icon="reset" (click)="resetOne.emit('route')" data-cy="rock-language_page-name-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ rock.item }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="item"
          required
          [attr.aria-label]="rock.item"
          data-cy="rock-language_singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('item') !== resetLanguage?.item">
            <terra-icon icon="reset" (click)="resetOne.emit('item')" data-cy="rock-language_singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ rock.items }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="items"
          required
          [attr.aria-label]="rock.items"
          data-cy="rock-language_plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('items') !== resetLanguage?.items">
            <terra-icon icon="reset" (click)="resetOne.emit('items')" data-cy="rock-language_plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ rock.company }} (Leadership team)</terra-form-label>
        <terra-text-input
          formControlName="company"
          required
          [attr.aria-label]="rock.company"
          data-cy="rock-language_company-leadership-team-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('company') !== resetLanguage?.company">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('company')"
              data-cy="rock-language_company-leadership-team-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ rock.departmental }} (non-Leadership team)</terra-form-label>
        <terra-text-input
          formControlName="departmental"
          required
          [attr.aria-label]="rock.departmental"
          data-cy="rock-language_departmental-team-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('departmental') !== resetLanguage?.departmental">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('departmental')"
              data-cy="rock-language_departmental-team-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
    </div>
  </mat-card-content>
</mat-card>
