import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep as _cloneDeep, cloneDeep } from 'lodash';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, switchMap, tap } from 'rxjs/operators';

import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { CognitoMfaType, IdentityProviderService } from '@ninety/ui/legacy/core/services/identity-provider.service';
import { NotifyService } from '@ninety/ui/legacy/core/services/notify.service';
import { SessionService } from '@ninety/ui/legacy/core/services/session.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { WarningConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/warning-confirm-dialog.component';
import { ColorBranding, Company } from '@ninety/ui/legacy/shared/models/company/company';
import { NinetyFeatures } from '@ninety/ui/legacy/shared/models/company/company-pricing-tiers';
import { CompanySettings } from '@ninety/ui/legacy/shared/models/company/company-settings';
import { EosReferrerOptions } from '@ninety/ui/legacy/shared/models/company/eos-referrer-options';
import { EosTypeOptions } from '@ninety/ui/legacy/shared/models/company/eos-type-options';
import { MeasurableWeeklyStartDays } from '@ninety/ui/legacy/shared/models/company/measurable-weekly-start-days';
import { MasterySettings } from '@ninety/ui/legacy/shared/models/mastery/mastery';
import { Vto } from '@ninety/ui/legacy/shared/models/vto/vto';
import { CompanyUsersStateActions } from '@ninety/ui/legacy/state/app-entities/company-users/company-users-state.actions';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { CompanyActions } from '@ninety/ui/legacy/state/app-global/company/company-state.actions';
import {
  selectCompany,
  selectCompanySettings,
} from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { selectHasHelpfulPermission } from '@ninety/ui/legacy/state/app-global/helpful-permissions/helpful-permissions.selectors';
import { extractValueFromStore, selectCurrentUserId } from '@ninety/ui/legacy/state/index';
import { VtoService } from '@ninety/vto/services/vto.service';

import { UploadCompanyLogoDialogComponent } from './upload-company-logo-dialog/upload-company-logo-dialog.component';

@Component({
  selector: 'ninety-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit, OnDestroy {
  spinner = false;
  updateSub = new Subject<{ key: keyof Company; value: any }>();
  subscriptions = new Subscription();
  eosTypeOptions = EosTypeOptions;
  referrerOptions: string[];
  measurableWeeklyStartDays = MeasurableWeeklyStartDays;
  companySltVto: Vto;
  readonly hasHelpfulCompaniesPermissions$ = this.store.select(selectHasHelpfulPermission('companies'));

  useImageUploaderForCompany$: Observable<boolean>;

  company$ = this.store.select(selectCompany).pipe(map(company => cloneDeep(company)));

  companySettings$ = this.store.select(selectCompanySettings);
  colorBranding$ = this.companySettings$.pipe(map(settings => settings.colorBranding));

  protected readonly NinetyFeatures = NinetyFeatures;

  constructor(
    public stateService: StateService,
    public companyService: CompanyService,
    public idpService: IdentityProviderService,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private notifyService: NotifyService,
    private sessionService: SessionService,
    private vtoService: VtoService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private featureFlagFacade: FeatureFlagFacade
  ) {
    this.useImageUploaderForCompany$ = this.featureFlagFacade.getFlag(FeatureFlagKeys.useImageUploaderForCompany);
  }

  ngOnInit() {
    this.referrerOptions = EosReferrerOptions;
    this.vtoService.getTeamVtoAndSetSltVto(this.stateService.company.seniorLeadershipTeamId, true, false).subscribe({
      next: (vto: Vto) => {
        this.companySltVto = vto;
      },
    });

    this.subscriptions.add(
      this.updateSub.pipe(debounceTime(1000)).subscribe({
        next: ({ key, value }) => this.updateCompany(key, value),
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateCompany(key: keyof Company, value: any): void {
    this.updateEosAndReferralType(key);

    this.companyService.updateWithKeyValue(key, value).subscribe();
  }

  onMasteryUpdated(mastery: MasterySettings): void {
    this.companyService.updateSettings({ mastery: _cloneDeep(mastery) }).subscribe();
  }

  updateSettings<K extends keyof CompanySettings>(prop: K, value: CompanySettings[K]): void {
    this.companyService.updateSettingsWithKeyValue(prop, value).subscribe();
  }

  updateEosAndReferralType(key: keyof Company): void {
    // set "other types" to empty if "regular types" change from 'Other'
    if (key === 'eosType') {
      if (this.stateService.company.eosType !== 'Other' && this.stateService.company.eosOtherType !== '') {
        this.updateSub.next({ key: 'eosOtherType', value: '' });
      }
    } else if (key === 'referralType') {
      if (this.stateService.company.referralType !== 'Other' && this.stateService.company.referralOtherType !== '') {
        this.updateSub.next({ key: 'referralOtherType', value: '' });
      }
    }
  }

  updateAccountabilityChartSettings(value: boolean): void {
    this.companyService.updateSettings({ ['privateAccountabilityChart']: value }).subscribe();
  }

  updateMilestonesTodos(event: boolean): void {
    this.companyService.updateSettingsWithKeyValue('preventMilestonesTurningIntoTodos', !event).subscribe();
  }

  openCompanyLogoDialog(): void {
    // passing in null for templateId - needed for hotfix
    // can go back in after and investigate if we need to pass
    // in any partner stuff there
    this.dialog
      .open(UploadCompanyLogoDialogComponent, {
        data: {
          companyId: this.stateService.companyId,
          templateId: null,
        },
      })
      .afterClosed()
      .pipe(filter(url => url?.length))
      .subscribe();
  }

  deleteCompany(): void {
    this.dialog
      .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, {
        data: {
          title: `Delete Company: ${this.stateService.company.name}`,
          message: '<strong>Caution:</strong> This action cannot be undone!',
          additionalMessage: `We are sorry to see you go! Are you sure you want to cancel your subscription?
        Let us know know why you are leaving and if there is anything we can do to change your mind.`,
          confirmButtonText: 'I understand...Delete Anyway',
          cancelButtonText: 'No, Stay!',
          confirmButtonIcon: 'face-frown',
          cancelButtonIcon: 'face-smile',
        },
      })
      .afterClosed()
      .pipe(
        filter(result => !!result),
        tap(() => {
          this.spinner = true;
          this.cdr.markForCheck();
        }),
        switchMap(() => this.companyService.delete()),
        tap(() => {
          this.spinner = false;
          this.cdr.markForCheck();

          const userId = extractValueFromStore(this.store, selectCurrentUserId);
          this.store.dispatch(CompanyUsersStateActions.deleteCurrentCompanyUser({ userId }));

          this.sessionService.switchCompany();

          this.router.navigate(['/create-company']);
        }),
        catchError((err: unknown) => {
          this.spinner = false;
          this.cdr.markForCheck();

          return throwError(err);
        })
      )
      .subscribe();
  }

  updateSmsSetting(event: boolean): void {
    if (event) this.companyService.updateSettingsWithKeyValue('smsEnabled', true).subscribe();
    else {
      this.dialog
        .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, {
          data: {
            title: 'Are you sure you want to turn off SMS?',
            confirmButtonText: 'Disable SMS',
            additionalMessage: `Completing this action will turn off all texting features for existing users of your company.
          Please press CANCEL if you would like users to continue to have SMS permissions.`,
          },
        })
        .afterClosed()
        .pipe(
          tap(disableHenryx => {
            if (!disableHenryx) {
              this.store.dispatch(CompanyActions.updateCompanySetting({ settings: { smsEnabled: true } }));
              this.cdr.markForCheck();
            }
          }),
          filter(disableHenryx => !!disableHenryx),
          switchMap(() => this.companyService.updateSettingsWithKeyValue('smsEnabled', false))
        )
        .subscribe();
    }
  }

  updateMfaSetting(event: boolean): void {
    if (event) {
      this.stateService.company.settings.requireMfa = true;
      this.spinner = true;
      this.cdr.markForCheck();
      this.companyService
        .updateSettingsWithKeyValue('requireMfa', true)
        .pipe(
          switchMap(() => this.idpService.mfaPreference),
          finalize(() => {
            this.cdr.markForCheck();
          })
        )
        .subscribe({
          next: mfaPref => {
            this.spinner = false;
            if (mfaPref === CognitoMfaType.NONE) {
              this.notifyService.showError(
                `For full access, please complete MFA setup in My Account`,
                'MFA Setup Required'
              );
            }
          },
          error: (err: unknown) => {
            this.spinner = false;
            this.store.dispatch(CompanyActions.updateCompanySetting({ settings: { requireMfa: false } }));
            this.errorService.notify(err, 'Failed to update MFA Setting');
          },
        });
    } else {
      this.dialog
        .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, {
          data: {
            title: 'Are you sure you want to disable MFA?',
            confirmButtonText: 'Disable MFA',
            additionalMessage: `This will allow users of your company to opt-in to MFA`,
          },
        })
        .afterClosed()
        .pipe(
          tap(disableMfa => {
            if (!disableMfa) {
              this.store.dispatch(CompanyActions.updateCompanySetting({ settings: { requireMfa: true } }));
            }
          }),
          filter(disableMfa => !!disableMfa),
          switchMap(() => {
            this.cdr.markForCheck();
            return this.companyService.updateSettingsWithKeyValue('requireMfa', false);
          }),
          finalize(() => {
            this.cdr.markForCheck();
          })
        )
        .subscribe({
          next: () => {
            this.spinner = false;
          },
          error: (err: unknown) => {
            this.spinner = false;
            this.store.dispatch(CompanyActions.updateCompanySetting({ settings: { requireMfa: true } }));
            this.errorService.notify(err, 'Failed to update MFA Setting');
          },
        });
    }
  }

  updateColorBranding(colorBranding: ColorBranding): void {
    this.store.dispatch(CompanyActions.updateTheme(_cloneDeep({ colorBranding })));
    this.companyService.updateSettings({ colorBranding }).subscribe();
  }
}
