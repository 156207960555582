import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';

import { PricingTier } from '../../../_shared/models/company/company-pricing-tiers';
import { ButtonComponent } from '../../buttons/button/button.component';
import { CardModule } from '../../cards/card.module';

import { AllInDiscountComponent } from './all-in-discount/all-in-discount.component';
import { ComparePlansComponent } from './compare-plans/compare-plans.component';
import { PricingCardsComponent } from './pricing-cards/pricing-cards.component';

@Component({
  selector: 'ninety-pricing-tier-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    CardModule,
    TerraIconModule,
    PricingCardsComponent,
    AllInDiscountComponent,
    ComparePlansComponent,
  ],
  templateUrl: './pricing-tier-dialog.component.html',
  styleUrls: ['./pricing-tier-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingTierDialogComponent {
  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<PricingTierDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { currentPricingTier: PricingTier }
  ) {}

  cancel(): void {
    this.dialogRef.close();
  }
}
