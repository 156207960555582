import { Injectable, inject } from '@angular/core';
import { concatLatestFrom, createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs';

import { MeetingService } from '@ninety/meeting/_shared/services/meeting.service';
import { ChannelService } from '@ninety/ui/legacy/core/services/channel.service';
import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';
import { MeetingMessage } from '@ninety/ui/legacy/shared/models/meetings/meeting-message';
import { MeetingMessageAction } from '@ninety/ui/legacy/shared/models/meetings/meeting-message-action';
import {
  selectAllUsers,
  selectCurrentUserIsManageeOrAbove,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';
import { RealTimeActions } from '@ninety/ui/legacy/state/app-global/real-time/real-time.actions';
import { selectCurrentUserId, selectLanguage } from '@ninety/ui/legacy/state/index';

import { MeetingRealTimeActions, MeetingsPageActions } from '../meetings.actions';
import { MeetingsStateSelectors } from '../meetings.selectors';

/**
 * Broadcasts received from other users
 */

@Injectable()
export class MeetingsRealTimeSubscriptionsEffects {
  private readonly actions$ = inject(Actions);
  private readonly channelService = inject(ChannelService);
  private readonly store = inject(Store);
  private readonly meetingService = inject(MeetingService);

  /** Watches for changes to meetings on the currently selected team */
  receiveMeetingStateChangeMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RealTimeActions.messageReceived),
      filter(({ message }) => message.messageType === 'meeting-state-change'),
      concatLatestFrom(() => [
        this.store.select(MeetingsStateSelectors.selectTeamId),
        this.store.select(MeetingsStateSelectors.selectCurrentMeeting),
      ]),
      filter(([{ message }, teamId, currentMeeting]) =>
        this.filterToCurrentMeeting(message.document as MeetingMessage, teamId, currentMeeting)
      ),
      map(() => MeetingsPageActions.getActiveMeetingInfo())
    )
  );

  /** Only fetch current meeting info if there's a change to meeting state */
  filterToCurrentMeeting(message: MeetingMessage, teamId: string, meeting: Meeting): boolean {
    return (
      message?.teamId === teamId &&
      (message?.meeting?.inProgress !== meeting?.inProgress || message?.meeting?.paused !== meeting?.paused)
    );
  }

  /**
   * Gets channel presence in response to several actions for users on the meetings page who haven't joined the meeting yet -
   * used to display users who are present in the meeting on the active-meeting card.
   */
  getChannelPresence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingRealTimeActions.authorizeChannelSuccess, MeetingRealTimeActions.meetingStateChange),
      concatLatestFrom(() => [this.store.select(MeetingsStateSelectors.selectMeetingStatus)]),
      filter(([, meeting]) => !!meeting),
      switchMap(([, meeting]) => {
        return this.channelService
          .getPresenceByChannelId(meeting._id)
          .pipe(map(attendees => MeetingRealTimeActions.setMeetingPresence({ attendees })));
      })
    )
  );

  /**
   * Authorizes the channel for the current user to check presence by the getChannelPresence$ effect above.
   * This usually occurs when subscribing to a channel - but in this case the user isn't subscribed yet.
   */
  getMeetingChannelAuthToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.getActiveMeetingInfoSuccess),
      concatLatestFrom(() => [this.store.select(selectCurrentUserId)]),
      filter(([{ meeting }]) => !!meeting),
      switchMap(([{ meeting }, userId]) =>
        this.channelService
          .authorizeChannelId(userId, meeting._id)
          .pipe(map(() => MeetingRealTimeActions.authorizeChannelSuccess()))
      )
    )
  );

  /**
   * Checks if a presenter is present after joining a meeting and re-assigns to current user otherwise.
   */
  checkMeetingPresenter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingRealTimeActions.checkMeetingPresenterExists),
        switchMap(({ meeting }) => this.channelService.getPresenceByChannelId(meeting._id)),
        concatLatestFrom(() => [
          this.store.select(selectCurrentUserId),
          this.store.select(MeetingsStateSelectors.selectCurrentMeeting),
          this.store.select(selectCurrentUserIsManageeOrAbove),
        ]),
        filter(([presence, userId, meeting, isManageeOrAbove]) =>
          this.meetingNeedsPresenter(presence, userId, meeting, isManageeOrAbove)
        ),
        switchMap(([, userId]) => this.meetingService.updateCurrentMeeting({ presenterUserId: userId }))
      ),
    { dispatch: false }
  );

  private meetingNeedsPresenter(
    presence: string[],
    userId: string,
    meeting: Meeting,
    isManageeOrAbove: boolean
  ): boolean {
    return !presence.some(u => u === meeting.presenterUserId) && meeting.presenterUserId !== userId && isManageeOrAbove;
  }

  /**
   * Redirects to meeting home when a meeting presenter deletes or suspends a meeting
   */
  meetingDeletedOrSuspended$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RealTimeActions.messageReceived),
      filter(
        ({ message }) =>
          message.messageType === MeetingMessageAction.Suspended || message.messageType === MeetingMessageAction.Delete
      ),
      concatLatestFrom(() => [
        this.store.select(MeetingsStateSelectors.selectCurrentMeeting),
        this.store.select(selectAllUsers),
        this.store.select(selectLanguage),
      ]),
      filter(([{ message }, meeting]) => message.document === meeting?._id),
      tap(() => {
        this.meetingService.clearMeeting();
        this.meetingService.navigateToMeetingsHome();
      }),
      map(([{ message, emitterId }, , users, language]) => {
        const user = users.find(u => u._id === emitterId)?.fullName;
        const action = message.messageType === MeetingMessageAction.Suspended ? 'suspended' : 'deleted';
        const notifyMessage = `${language.meeting.item} was ${action} by ${user}`;
        return NotificationActions.notify({ message: notifyMessage, duration: 3000 });
      })
    )
  );
}
