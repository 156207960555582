import { GridWidget } from '@ninety/layouts/grid-layout/models/grid-widget.model';

/**
 * Maps widget id > widget.
 *
 * The most common data access pattern of widgets is by their id. Maintaining this organization makes updates of the
 * widgets trivial.
 * @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket.
 */
export type GridWidgetById = Record<string, GridWidget<unknown>>;

export namespace GridWidgetById {
  export function from(models: GridWidget<unknown>[]): GridWidgetById {
    return models.reduce((acc, model) => {
      acc[model._id] = model;
      return acc;
    }, {});
  }
}
