import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraIconModule } from '@ninety/terra';
import { NinetyCMModule } from '@ninety/ui/legacy/components/commercial-model/cm.module';
import { ButtonComponent } from '@ninety/ui/legacy/components/index';
import { selectLanguage } from '@ninety/ui/legacy/state/index';

@Component({
  selector: 'ninety-universal-create-btn',
  standalone: true,
  imports: [ButtonComponent, TerraIconModule, NinetyCMModule, NgIf, LetDirective],
  templateUrl: './universal-create-btn.component.html',
  styleUrls: ['./universal-create-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniversalCreateBtnComponent {
  private readonly store = inject(Store);
  @Input() locked = false;
  @Input() disabled = false;

  @Output() openUniversalCreateModal = new EventEmitter<void>();

  language$ = this.store.select(selectLanguage);
}
