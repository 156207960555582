import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { FilterBarActions } from '@ninety/layouts/_state/filterbar/filterbar-state.actions';
import { TerraIconModule } from '@ninety/terra';
import { NinetyCMModule } from '@ninety/ui/legacy/components/commercial-model/cm.module';

@Component({
  standalone: true,
  imports: [CommonModule, TerraIconModule, NinetyCMModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <!--    -->
    <ninety-cm-tiered-page>
      <div headline>Unlock Powerful Insights for Your Business</div>
      <div description>
        Upgrade your plan for access to Insights. Track and analyze all your activity and tools in Ninety to empower
        data-driven decisions that fuel growth and improve performance.
      </div>
      <button ninety-cm-button>
        <terra-icon icon="lightning" variant="fill" />
        Explore Scale Plan
      </button>
    </ninety-cm-tiered-page>`,
})
export class InsightsTieredPageComponent {
  constructor(private readonly store: Store) {
    this.store.dispatch(FilterBarActions.hide());
  }
}
