import { ComponentRef, EmbeddedViewRef } from '@angular/core';

import { GridItemComponent } from '../components/grid-item/grid-item.component';

import { GridWidget } from './grid-widget.model';

/** @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket. */
export interface RegisteredComponentsMapValue {
  /** Data model of the widgets position */
  model: GridWidget<unknown>;

  /** Reference to the component which wraps whatever template was passed to the grid. Parent of embeddedTemplateRef */
  gridItemComponentRef?: ComponentRef<GridItemComponent>;

  /**
   * Reference to the template this model matched. IE, this is the personal _todo widget model, so this is that
   * template.
   */
  embeddedTemplateRef?: EmbeddedViewRef<unknown>;

  /**
   * The index that the tempalte was inserted in the DOM when grid mode is active. Stored when one-column-mode is
   * enabled to allow easy re-insertion in the grid when one-column-mode is disabled
   */
  previousIndex?: number;
}

/**
 * Holds references to views along with their data for easy retrieval by id
 * @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket.
 */
export class RegisteredComponentsMap extends Map<string, RegisteredComponentsMapValue> {
  constructor(entries?: readonly (readonly [string, RegisteredComponentsMapValue])[] | null) {
    super(entries);
  }

  override clear() {
    super.forEach(value => {
      try {
        value.gridItemComponentRef?.destroy();
      } catch (e) {
        console.log(`Attempted to close component, but it was already closed.`, e, value);
      }

      try {
        value.embeddedTemplateRef?.destroy();
      } catch (e) {
        console.log(`Attempted to close embedded view, but it was already closed.`, e, value);
      }
    });
    super.clear();
  }

  registerModel(id: string, model: GridWidget<unknown>) {
    if (this.has(id)) {
      const current = this.get(id);
      this.set(id, { ...current, model });
    } else this.set(id, { model });
  }

  registerTemplateRefs(
    id: string,
    gridItemComponentRef: ComponentRef<GridItemComponent>,
    embeddedTemplateRef: EmbeddedViewRef<unknown>
  ) {
    if (this.has(id)) {
      const current = this.get(id);
      const merged: RegisteredComponentsMapValue = { ...current, gridItemComponentRef, embeddedTemplateRef };
      this.set(id, merged);
    } else {
      console.warn('Cannot register template refs without an existing model in the map');
    }
  }

  orderedByPreviousIndex(): RegisteredComponentsMapValue[] {
    return Array.from(this.values()).sort((a, b) => {
      if (a.previousIndex === undefined || b.previousIndex === undefined) {
        throw new Error(
          'Cannot sort by previous index when one or more items do not have a previous index. Attempting to compare ids ' +
            a.model._id +
            ' and ' +
            b.model._id
        );
      }
      return a.previousIndex - b.previousIndex;
    });
  }
}
