<div #formfield class="terra-form-field">
  <div class="terra-form-field__label">
    <div class="terra-form-field__label-group">
      <ng-content select="terra-form-label" />
      <div *ngIf="optional" class="terra-form-field__optional">(optional)</div>
    </div>
    <div *ngIf="charCount" class="terra-form-field__count">
      {{ _currCharCount() | number }}/{{ _getMaxLength() | number }}
    </div>
  </div>
  <div class="terra-form-field__content">
    <ng-content select="terra-text-input, terra-select, terra-switch" />
  </div>
  <div class="terra-form-field__context">
    <ng-content select="terra-description" />
    <ng-container *ngIf="_isFormControlInvalid()">
      <ng-content select="terra-error" />
    </ng-container>
  </div>
</div>
