import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';

import { ButtonComponent } from '../../../../../buttons/button/button.component';
import { CardModule } from '../../../../../cards/card.module';
import { Plan } from '../../../compare-plans/compare-plans.component';

@Component({
  selector: 'ninety-pricing-card-scale',
  standalone: true,
  imports: [CommonModule, CardModule, TerraIconModule, ButtonComponent],
  templateUrl: './pricing-card-scale.component.html',
  styleUrls: ['./pricing-card-scale.component.scss'],
})
export class PricingCardScaleComponent {
  plan: Plan = {
    name: 'Scale',
    price: 15,
    description:
      'Designed for teams ready to grow, the Scale plan offers tools to plan each quarter, think strategically, and scale your operations seamlessly.',
    features: [
      'Annual Meetings',
      'Google Integrations',
      'Microsoft Integrations',
      'Quarterly Discussion',
      'Reporting',
      'Scorecard Integrations',
    ],
    buttonLabel: 'Choose Scale',
  };

  selectPlanBtnClick() {
    console.log(`${this.plan.name} plan selected!`);
  }
}
