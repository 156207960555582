import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';

@Component({
  selector: 'ninety-rock-type-selector',
  templateUrl: './rock-type-selector.html',
  styleUrls: ['./rock-type-selector.scss'],
  standalone: true,
  imports: [CommonModule, TerraIconModule],
})
export class RockTypeSelectorComponent {
  @Input() rockType: string;
  @Input() rockLanguage: string;
  @Input() isSelected: boolean;
  @Input() disabled: boolean;
  @Input() selectedName: string;
  @Output() selectedRockType = new EventEmitter<boolean>();

  onSelectRockType(): void {
    if (this.disabled) {
      return;
    }
    this.selectedRockType.emit(!this.isSelected);
  }
}
