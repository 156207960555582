import { Sort } from '@angular/material/sort';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DirectoryState, DirectoryStateKey } from '@ninety/directory-v2/_state/directory-state.model';
import {
  ALL_ROLE_FILTER_OPTION,
  DirectoryRoleFilterOption,
} from '@ninety/directory-v2/models/directory-role-filter-option';
import { DirectoryUserRole, DirectoryUserRoleFromRoleCode } from '@ninety/directory-v2/models/directory-user-role';
import {
  DIRECTORY_USER_STATUS_FILTER_OPTIONS,
  DirectoryUserStatusOption,
} from '@ninety/directory-v2/models/directory-user-status-options';
import { GetDirectoryParams } from '@ninety/directory-v2/models/get-directory-params';
import { QuickFilterOption } from '@ninety/ui/legacy/components/inputs/quick-filter/models/quick-filter-item.model';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { Countries } from '@ninety/ui/legacy/shared/models/directory/countries.constant';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { selectRolesMetadata } from '@ninety/ui/legacy/state/app-entities/roles/roles-state.selectors';
import { teamAll } from '@ninety/ui/legacy/state/app-entities/team-list/api/team-list.model';
import { TeamSelectors } from '@ninety/ui/legacy/state/app-entities/team-list/team-list-state.selectors';
import {
  selectCurrentUser,
  selectCurrentUserIsAdminOrOwner,
  selectCurrentUserIsManagerOrAbove,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import {
  selectCompanyBillingCounts,
  selectIsTrialing,
} from '@ninety/ui/legacy/state/app-global/billing/billing-state.selectors';
import { selectCompany } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';

import { MenuOptions } from '../components/directory-table-action/directory-table-action.component';

export namespace DirectorySelectors {
  export const selectDirectoryState = createFeatureSelector<DirectoryState>(DirectoryStateKey);
  export const selectLoading = createSelector(selectDirectoryState, ({ loading }) => loading);
  export const selectUsers = createSelector(selectDirectoryState, ({ users }) => users);
  export const selectUserById = (userId: string) =>
    createSelector(selectUsers, users => users.find(u => u?._id === userId));

  export const selectSelectedUser = createSelector(selectDirectoryState, ({ selectedUser }) => selectedUser);

  export const selectCanEditSelectedUser = createSelector(
    DirectorySelectors.selectDirectoryState,
    selectCurrentUser,
    selectCurrentUserIsAdminOrOwner,
    ({ selectedUser }, currentUser, isAdminOrOwner) => {
      return isAdminOrOwner || currentUser._id === selectedUser._id;
      // return false;
    }
  );

  export const selectUsersToBeInvited = createSelector(selectUsers, users => users.filter(u => u.toBeInvited));
  export const selectAllUsersToBeInvited = createSelector(selectUsers, users =>
    users.every(users => users.toBeInvited)
  );
  export const selectTotalUsers = createSelector(selectDirectoryState, ({ totalUsers }) => totalUsers);

  export const selectPagination = createSelector(selectDirectoryState, ({ pageIndex, pageSize, totalUsers }) => ({
    pageIndex,
    pageSize,
    totalUsers,
  }));

  export const selectMatSort = createSelector(
    selectDirectoryState,
    ({ sortField: active, sortDirection: direction }): Sort => ({ active, direction })
  );

  export const selectDirectoryParams = createSelector(selectDirectoryState, GetDirectoryParams.fromState);
  export const selectShowInviteCheckboxes = createSelector(
    selectDirectoryState,
    ({ showCheckboxes }) => showCheckboxes
  );

  export const selectSearchText = createSelector(selectDirectoryState, ({ searchText }) => searchText);

  export const selectCurrentTeam = createSelector(
    selectDirectoryState,
    TeamSelectors.selectAll,
    ({ teamId }, teams) => teams.find(({ _id }) => _id === teamId) || teamAll
  );

  export const selectCurrentRoleFilterOption = createSelector(
    selectDirectoryState,
    ({ selectedRole }): DirectoryRoleFilterOption => selectedRole
  );

  export const selectDirectoryRoles = createSelector(
    selectRolesMetadata,
    (rolesMetadata): DirectoryRoleFilterOption[] =>
      rolesMetadata.map(({ title, roleCode, isImplementer }) => ({
        title,
        userRole: isImplementer ? DirectoryUserRole.coach : DirectoryUserRoleFromRoleCode[roleCode],
      }))
  );

  export const selectRoleFilterOptions = createSelector(
    selectDirectoryRoles,
    selectCurrentRoleFilterOption,
    (roles, selectedRole): QuickFilterOption<DirectoryRoleFilterOption>[] => {
      return [ALL_ROLE_FILTER_OPTION, ...roles].map(({ title, userRole }) => ({
        id: title,
        displayText: title,
        item: { title, userRole },
        selected: title === selectedRole.title,
      }));
    }
  );

  export const selectCurrentUserStatusFilterOption = createSelector(
    selectDirectoryState,
    ({ selectedUserStatus }): DirectoryUserStatusOption => selectedUserStatus
  );

  export const selectUserStatusFilterOptions = createSelector(
    selectCurrentUserStatusFilterOption,
    (selectedRole): QuickFilterOption<DirectoryUserStatusOption>[] => {
      return DIRECTORY_USER_STATUS_FILTER_OPTIONS.map(({ title, status }) => ({
        id: title,
        displayText: title,
        item: { title, status },
        selected: title === selectedRole.title,
      }));
    }
  );

  const AlWAYS_VISIBLE_COLUMNS = ['avatar', 'fullName', 'firstName', 'lastName', 'role'];

  export const selectDisplayedColumns = createSelector(selectCurrentUserIsManagerOrAbove, isManagerOrAbove => {
    return isManagerOrAbove
      ? ['avatar', 'fullName', 'firstName', 'lastName', 'teams', 'role', 'status', 'actions']
      : AlWAYS_VISIBLE_COLUMNS;
  });

  export const selectIsSameOrHigherRole = (otherUser: User) =>
    createSelector(selectCurrentUser, currentUser => {
      if (!currentUser || !otherUser) return false;
      if (currentUser._id === otherUser._id) return true;
      return currentUser.roleCode <= otherUser.roleCode;
    });

  export const selectCanRemoveUser = (otherUser: User) =>
    createSelector(
      selectCurrentUserIsAdminOrOwner,
      selectIsSameOrHigherRole(otherUser),
      (isAdminOrOwner, isSameOrHigherRole) => {
        return isAdminOrOwner && isSameOrHigherRole;
      }
    );

  export const selectShouldShowCopyInviteLink = (otherUser: User) =>
    createSelector(selectCurrentUserIsManagerOrAbove, selectCurrentUser, (isManagerOrAbove, currentLoggedInUser) => {
      const showInviteLink: boolean =
        !otherUser.personId &&
        isManagerOrAbove &&
        currentLoggedInUser.roleCode <= otherUser.roleCode &&
        otherUser.emailAddresses?.length > 0 &&
        otherUser.teams?.length > 0;

      return showInviteLink;
    });

  export const selectCanDeactivateUser = (otherUser: User) =>
    createSelector(selectCurrentUserIsManagerOrAbove, selectCurrentUser, (isManagerOrAbove, currentLoggedInUser) => {
      const canDeactivate = otherUser.roleCode
        ? parseInt(currentLoggedInUser.roleCode, 10) <= parseInt(otherUser.roleCode, 10)
        : true;

      const showDeactivateLink: boolean =
        isManagerOrAbove && currentLoggedInUser.roleCode <= otherUser.roleCode && canDeactivate;

      return showDeactivateLink;
    });

  export const selectMenuType = (otherUser: User) =>
    createSelector((): MenuOptions => {
      const hasUserBeenInvited = otherUser?.hasBeenInvited;
      const isUserActive = otherUser?.active;

      if (hasUserBeenInvited && isUserActive) {
        return MenuOptions.PendingUser;
      } else if (!hasUserBeenInvited && !isUserActive) {
        return MenuOptions.DirectoryOnlyPerson;
      } else if (isUserActive) {
        return MenuOptions.ActiveUser;
      }
    });

  export const selectIsActionButtonActive = (otherUser: User) =>
    createSelector(
      selectMenuType(otherUser),
      selectCanRemoveUser(otherUser),
      selectShouldShowCopyInviteLink(otherUser),
      selectCanDeactivateUser(otherUser),
      (
        menuType: MenuOptions,
        canRemoveUser: boolean,
        shouldShowCopyInviteLink: boolean,
        canDeactivateUser: boolean
      ) => {
        let shouldBeActive = false;

        if (menuType === MenuOptions.ActiveUser) {
          shouldBeActive = canDeactivateUser || canRemoveUser;
        } else if (menuType === MenuOptions.PendingUser) {
          shouldBeActive = canRemoveUser || shouldShowCopyInviteLink || canDeactivateUser;
        } else if (menuType === MenuOptions.DirectoryOnlyPerson) {
          shouldBeActive = canRemoveUser;
        }
        return shouldBeActive;
      }
    );

  export const selectCanShowInviteLink = ({ personId, emailAddresses, teams }: User) =>
    createSelector(
      selectCurrentUserIsManagerOrAbove,
      isManagerOrAbove => isManagerOrAbove && !personId && emailAddresses?.length > 0 && teams?.length > 0
    );

  export const selectShowBulkInviteButton = createSelector(
    selectCurrentUserIsManagerOrAbove,
    selectShowInviteCheckboxes,
    (isManagerOrAbove, isDirectoryOnlyView) => isManagerOrAbove && isDirectoryOnlyView
  );

  export const selectBulkInviteButtonDisabled = createSelector(
    selectUsersToBeInvited,
    usersToBeInvited => !usersToBeInvited.length
  );

  export const selectCanAddUserCounts = createSelector(
    selectIsTrialing,
    selectCompanyBillingCounts,
    selectCompany,
    (isTrialing, counts, { trialingUserLimit }) => ({
      canAdd: isTrialing ? counts.activeBillingUsers < trialingUserLimit - 1 : counts.assignableSeats,
      companyUserLimit: isTrialing ? trialingUserLimit : counts.seatsPaidFor,
    })
  );

  export const selectEnableDirectoryV2 = createSelector(
    selectFeatureFlag(FeatureFlagKeys.enableUserManagementEnhancements),
    isEnabled => isEnabled
  );

  export const selectEnableUserDetailV2 = createSelector(
    selectEnableDirectoryV2,
    selectFeatureFlag(FeatureFlagKeys.userDetailV2Development),
    (directoryV2Enabled, userDetailV2Enabled) => directoryV2Enabled && userDetailV2Enabled
  );

  export const selectCountries = createSelector(() =>
    Countries.map(({ name, code }) => ({
      name,
      code,
    }))
  );
}
