import { createSelector } from '@ngrx/store';

import { selectFeedbackState } from '@ninety/feedback/_state/feedback-state';
import { selectCompanyLanguage } from '@ninety/feedback/_state/page/feedback-page.selectors';
import { ConversationType } from '@ninety/ui/legacy/shared/models/feedback/conversation-type.enum';

import { ConversationListStateKey, initialConversationListState } from './conversation-list.model';

export const selectConversationListState = createSelector(selectFeedbackState, state =>
  state ? state[ConversationListStateKey] : initialConversationListState
);

export const selectConversationType = createSelector(selectConversationListState, state => state.conversationType);

export const selectSelectedConversationId = createSelector(
  selectConversationListState,
  state => state.selectedConversationId
);

export const selectFilteredSearchText = createSelector(selectConversationListState, state => state.filter.searchText);

export const selectFilteredUserId = createSelector(selectConversationListState, state => state.filter.selectedUserId);
export const selectInitFilterWithCurrentUser = createSelector(
  selectConversationListState,
  state => state.filter.initFilterWithCurrentUser
);

export const selectShowCompletedConversations = createSelector(
  selectConversationListState,
  state => state.filter.showCompletedConversations
);

export const selectConversationPageTitle = createSelector(
  selectConversationType,
  selectCompanyLanguage,
  (type, language) => {
    const titleMap: Record<ConversationType, string> = {
      [ConversationType.quarterly]: language?.feedback?.quarterlyItems,
      [ConversationType.annual]: language?.feedback?.annualItems,
    };

    return titleMap[type];
  }
);

export const selectConversationListTitle = createSelector(
  selectConversationPageTitle,
  selectShowCompletedConversations,
  (lang, showCompleted) => {
    const tense = showCompleted ? 'Past' : 'Active';
    return `${tense} ${lang}`;
  }
);

export const selectConversationListDescription = createSelector(
  selectConversationPageTitle,
  selectShowCompletedConversations,
  (lang, showCompleted) => {
    const activeDesc = `A list of all active ${lang} that need your input.`;
    const pastDesc = `A list of all completed ${lang}.`;
    return showCompleted ? pastDesc : activeDesc;
  }
);

export const selectConversationList = createSelector(
  selectConversationListState,
  selectShowCompletedConversations,
  (state, showCompletedConversations) => (showCompletedConversations ? state.pastList : state.activeList)
);

export const selectConversations = createSelector(
  selectConversationListState,
  selectShowCompletedConversations,
  selectConversationType,
  (state, showCompletedConversations, conversationType) =>
    showCompletedConversations ? state.pastList[conversationType].items : state.activeList[conversationType].items
);

export const selectTotalConversations = createSelector(
  selectConversationListState,
  selectShowCompletedConversations,
  selectConversationType,
  (state, showCompletedConversations, conversationType) =>
    showCompletedConversations
      ? state.pastList[conversationType].totalCount
      : state.activeList[conversationType].totalCount
);

export const selectConversationListPager = createSelector(selectConversationListState, state => state.pager);

export const selectConversationListSort = createSelector(selectConversationListState, state => state.sort);

export const selectConversationListApiParameters = createSelector(
  selectConversationType,
  selectFilteredSearchText,
  selectConversationListPager,
  selectConversationListSort,
  selectFilteredUserId,
  selectShowCompletedConversations,
  selectConversations,
  selectTotalConversations,
  (conversationType, searchText, page, sort, selectedUserId, isCompleted, items, totalCount) => ({
    conversationType,
    searchText,
    selectedUserId,
    isCompleted,
    page: page.pageIndex,
    take: page.pageSize,
    sort: sort,
    conversations: {
      items,
      totalCount,
    },
  })
);

export const selectRefetchOnSort = createSelector(
  selectTotalConversations,
  selectConversationListPager,
  (total, pager) => total > pager.pageSize
);

export const selectSelectedConversation = createSelector(selectConversations, selectSelectedConversationId, (c, id) => {
  if (id === null) return null;
  return c.find(conversation => conversation._id === id);
});

export const selectCreateConversationDialogData = createSelector(
  selectCompanyLanguage,
  selectConversationType,
  (language, conversationType) => {
    const titleMap: Record<ConversationType, string> = {
      [ConversationType.quarterly]: language.feedback.quarterlyItem,
      [ConversationType.annual]: language.feedback.annualItem,
    };
    return { language, title: titleMap[conversationType] };
  }
);

export const selectConversationById = function (conversationId: string) {
  return createSelector(selectConversations, conversations => conversations.find(c => c._id === conversationId));
};
