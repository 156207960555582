<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="my-90" />
      <span data-cy="my-ninety-language_card-title">{{ my90.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="my90">
      <terra-form-field>
        <terra-form-label>Page Name</terra-form-label>
        <terra-text-input
          formControlName="route"
          required
          [attr.aria-label]="my90.route + ' Page Name'"
          data-cy="my-ninety-language_page-name-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('route') !== resetLanguage?.route">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('route')"
              data-cy="my-ninety-language_page-name-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ my90.teamTodos }}</terra-form-label>
        <terra-text-input
          #teamTodos
          formControlName="teamTodos"
          required
          [attr.aria-label]="my90.teamTodos"
          data-cy="my-ninety-language_team-to-dos-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('teamTodos') !== resetLanguage?.teamTodos">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('teamTodos')"
              data-cy="my-ninety-language_team-to-dos-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ my90.personalTodos }}</terra-form-label>
        <terra-text-input
          #personalTodos
          formControlName="personalTodos"
          required
          [attr.aria-label]="my90.personalTodos"
          data-cy="my-ninety-language_private-to-dos-input-field">
          <terra-input-suffix
            *ngIf="!readonly && getFormControlValue('personalTodos') !== resetLanguage?.personalTodos">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('personalTodos')"
              data-cy="my-ninety-language_private-to-dos-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ my90.rocksAndMilestones }}</terra-form-label>
        <terra-text-input
          #rocksAndMilestones
          formControlName="rocksAndMilestones"
          required
          [attr.aria-label]="my90.rocksAndMilestones"
          data-cy="my-ninety-language_rocks-and-milestones-input-field">
          <terra-input-suffix
            *ngIf="!readonly && getFormControlValue('rocksAndMilestones') !== resetLanguage?.rocksAndMilestones">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('rocksAndMilestones')"
              data-cy="my-ninety-language_rocks-and-milestones-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ my90.scorecard }}</terra-form-label>
        <terra-text-input
          #scorecard
          formControlName="scorecard"
          required
          [attr.aria-label]="my90.scorecard"
          data-cy="my-ninety-language_scorecard-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('scorecard') !== resetLanguage?.scorecard">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('scorecard')"
              data-cy="my-ninety-language_scorecard-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ my90.todoChart }}</terra-form-label>
        <terra-text-input
          #todoChart
          formControlName="todoChart"
          required
          [attr.aria-label]="my90.todoChart"
          data-cy="my-ninety-language_todos-last-90days-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('todoChart') !== resetLanguage?.todoChart">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('todoChart')"
              data-cy="my-ninety-language_todos-last-90days-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ my90.rockChart }}</terra-form-label>
        <terra-text-input
          #rockChart
          formControlName="rockChart"
          required
          [attr.aria-label]="my90.rockChart"
          data-cy="my-ninety-language_rocks-statuses-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('rockChart') !== resetLanguage?.rockChart">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('rockChart')"
              data-cy="my-ninety-language_rocks-statuses-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ my90.measurableChart }}</terra-form-label>
        <terra-text-input
          #measurableChart
          formControlName="measurableChart"
          required
          [attr.aria-label]="my90.measurableChart"
          data-cy="my-ninety-language_kpis-input-field">
          <terra-input-suffix
            *ngIf="!readonly && getFormControlValue('measurableChart') !== resetLanguage?.measurableChart">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('measurableChart')"
              data-cy="my-ninety-language_kpis-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
    </div>
  </mat-card-content>
</mat-card>
