import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { BehaviorSubject, map, Subject, take, takeUntil, tap } from 'rxjs';

import { DirectoryAddInviteDialogComponent } from '@ninety/directory/directory-add-invite/directory-add-invite.component';
import { GuideSelectors } from '@ninety/getting-started/guide/_state/guide.selectors';
import { GettingStartedTrackerComponent } from '@ninety/getting-started/guide/components/getting-started-tracker/getting-started-tracker.component';
import { selectShouldShowInsightsInNavigation } from '@ninety/insights/_shared/_state/insights.selectors';
import { selectIsMainSidebarHidden } from '@ninety/layouts/_state/main-sidebar/main-sidebar-state.selectors';
import { CompanyMenuComponent } from '@ninety/layouts/components/company-menu/company-menu.component';
import { UserProfileMenuComponent } from '@ninety/layouts/main-layout/user-profile-menu/user-profile-menu.component';
import TrialCountdownComponent from '@ninety/settings/company/trial-countdown-component/trial-countdown.component';
import { TerraIconModule, TerraIconName } from '@ninety/terra';
import { NavigationMenuItemComponent } from '@ninety/ui/legacy/components/menu-items/navigation-menu-item/navigation-menu-item.component';
import { NinetyChipComponent } from '@ninety/ui/legacy/components/ninety-chip/ninety-chip.component';
import { PopoverService } from '@ninety/ui/legacy/components/popover/popover.service';
import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { HelperService } from '@ninety/ui/legacy/core/services/helper.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { NinetyFeatures } from '@ninety/ui/legacy/shared/models/company/company-pricing-tiers';
import { Theme } from '@ninety/ui/legacy/shared/models/enums/theme';
import { PartnerType } from '@ninety/ui/legacy/shared/models/partner-hub/partner-type.enum';
import { IsFeatureAvailablePipe } from '@ninety/ui/legacy/shared/pipes/is-feature-available.pipe';
import { IsTieredPageActivePipe } from '@ninety/ui/legacy/shared/pipes/is-tiered-page-active.pipe';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import {
  selectCurrentUser,
  selectCurrentUserId,
  selectCurrentUserTheme,
  selectUserAvatarInfo,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectAuthShouldHaveAccess } from '@ninety/ui/legacy/state/app-global/auth/auth.selectors';
import {
  selectCompany,
  selectCompanyCreatedAfterPricingGoLive,
} from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import {
  selectIsBillingV2Company,
  selectIsTrialSubscription,
  selectShouldDisplayTrialCountdown,
} from '@ninety/ui/legacy/state/app-global/company/subscription/subscription-state.selectors';
import {
  selectCurrentPerson,
  selectHasPartnerHubAccess,
} from '@ninety/ui/legacy/state/app-global/current-person/current-person.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { selectDoesUserHaveAccessToOrgChart } from '@ninety/ui/legacy/state/composite-selectors/user-team.selectors';
import { selectUrl } from '@ninety/ui/legacy/state/route.selectors';

import { LearnSupportMenuComponent } from '../learn-support-menu/learn-support-menu.component';

import { sideNavAnimation, sideNavContainerAnimation } from './main-sidenav-animation';

@Component({
  selector: 'ninety-main-sidebar',
  templateUrl: './main-sidebar.component.html',
  styleUrls: ['./main-sidebar.component.scss'],
  animations: [sideNavAnimation, sideNavContainerAnimation],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PopoverService],
  imports: [
    CommonModule,
    RouterModule,
    NavigationMenuItemComponent,
    PushPipe,
    SharedModule,
    TrialCountdownComponent,
    NinetyTooltipDirective,
    LetDirective,
    CompanyMenuComponent,
    TerraIconModule,
    GettingStartedTrackerComponent,
    NinetyChipComponent,
    IsFeatureAvailablePipe,
    IsTieredPageActivePipe,
  ],
})
export class MainSidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly Theme = Theme;
  @ViewChild('learnAndSupportTrigger', { read: ElementRef }) learnAndSupportTrigger: ElementRef<unknown>;
  @ViewChild('userProfileTrigger', { read: ElementRef }) userProfileTrigger: ElementRef<unknown>;
  // Used for navigation tools scrolling
  @ViewChild('navTools') navTools!: ElementRef;
  @HostListener('window:resize') onResize() {
    this.checkForScrollbar();
  }

  @Input() isMobile: boolean;
  @Input() showGettingStartedGuide: boolean;

  private destroy$ = new Subject<void>();

  protected readonly url$ = this.store.select(selectUrl);

  isTablet = false;
  isHovering = false;
  isButtonHovering = false;

  public profileMenuIcon: 'more' | 'warning-circle' = 'more';

  protected toolsScrollState$: BehaviorSubject<'TOP' | 'MIDDLE' | 'BOTTOM'> = new BehaviorSubject('TOP');
  navToolsHasScrollbar = false;

  protected readonly enableSurveys$ = this.featureFlags.getFlag(FeatureFlagKeys.enableAssessments);
  protected readonly enableSurveysPill$ = this.featureFlags.getFlag(FeatureFlagKeys.enableAssessmentsSidenavPill);
  protected readonly enableLearningModule$ = this.featureFlags.getFlag(FeatureFlagKeys.enableLearningModule);
  protected readonly enableRocksV3$ = this.featureFlags.getFlag(FeatureFlagKeys.webRocksV3);
  protected readonly enableReferralProgram$ = this.featureFlags.getFlag(FeatureFlagKeys.referralProgram);
  protected readonly isTrialSubscription$ = this.store.select(selectIsTrialSubscription);

  protected readonly company$ = this.store.select(selectCompany);
  protected readonly currentTheme$ = this.store.select(selectCurrentUserTheme);
  protected readonly currentPerson$ = this.store.select(selectCurrentPerson);
  protected readonly currentUser$ = this.store.select(selectCurrentUser);
  protected readonly disableNavLinks$ = this.store
    .select(selectAuthShouldHaveAccess)
    .pipe(map(shouldHaveAccess => !shouldHaveAccess));
  protected readonly hasPartnerHubAccess$ = this.store.select(selectHasPartnerHubAccess);
  protected readonly isBillingV2Company$ = this.store.select(selectIsBillingV2Company);
  protected readonly isExpanded$ = this.currentUser$.pipe(map(user => !(user.settings.sidenavPinned === 'collapsed')));
  protected readonly isHidden$ = this.store.select(selectIsMainSidebarHidden);
  protected readonly language$ = this.store.select(selectLanguage);
  protected readonly profileMenuIcon$ = this.currentUser$.pipe(
    map((user): TerraIconName => {
      const alerts = user?.settings.settingsAlerts ?? {};
      const key = Object.keys(alerts).find(f => !!alerts[f]);
      return key ? 'warning-circle' : 'more';
    })
  );

  // Scorecard Beta
  protected readonly scorecardBeta$ = this.featureFlags.getFlag(FeatureFlagKeys.scorecardBeta);

  // Directory V2
  protected readonly directoryV2$ = this.featureFlags.getFlag(FeatureFlagKeys.enableUserManagementEnhancements);
  protected readonly showGettingStartedTracker$ = this.store.select(GuideSelectors.selectIsGuideEnabled);
  protected readonly shouldDisplayTrialCountdown$ = this.store.select(selectShouldDisplayTrialCountdown);
  protected readonly userAvatarInfo$ = this.store
    .select(selectCurrentUserId)
    .pipe(map(userId => this.store.select(selectUserAvatarInfo(userId))));

  // Owned By Reporting
  protected readonly canAccessInsights$ = this.store.select(selectShouldShowInsightsInNavigation);
  protected readonly canAccessOrgChart$ = this.store.select(selectDoesUserHaveAccessToOrgChart);

  //Selector for creation after pricing go live
  protected readonly companyCreatedAfterPricingGoLive$ = this.store.select(selectCompanyCreatedAfterPricingGoLive);
  constructor(
    public stateService: StateService,
    public filterService: FilterService,
    public store: Store,
    private userService: UserService,
    private companyService: CompanyService,
    private cdr: ChangeDetectorRef,
    private featureFlags: FeatureFlagFacade,
    public dialog: MatDialog,
    private popoverService: PopoverService,
    private helperService: HelperService
  ) {}

  ngAfterViewInit(): void {
    this.checkForScrollbar();
  }

  ngOnInit() {
    this.isTablet = this.helperService.isTablet();

    this.companyService.companyMasterySettingsUpdated$
      .pipe(
        tap(_ => this.cdr.markForCheck()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  toggleSideNav() {
    this.isExpanded$.pipe(take(1)).subscribe(isExpanded => {
      this.filterService.sideNavExpanded$.next(!isExpanded);
      const text = !isExpanded ? 'expanded' : 'collapsed';
      this.userService.updateUserSettings$.next({ sidenavPinned: text });
    });
  }

  public openAddInviteDialog() {
    this.dialog.open(DirectoryAddInviteDialogComponent, {
      id: 'add-invite-modal',
      panelClass: 'add-invite-modal-container',
      width: '900px',
      maxWidth: '100vw',
      data: {
        allOtherUserEmailAddresses: this.userService.getAllOtherUserEmails(),
      },
    });
  }

  showLearnAndSupportMenu() {
    this.popoverService.show(this.learnAndSupportTrigger, LearnSupportMenuComponent);
  }

  checkForScrollbar() {
    // The element will be available during ngAfterViewInit but may not be yet rendered.
    // We need to wait another cycle for the height to be set
    // Angular 16.2 might have a new lifeCycle method for this
    if (this.navTools.nativeElement.clientHeight === 0) {
      setTimeout(() => this.checkForScrollbar(), 0);
    } else {
      this.navToolsHasScrollbar =
        this.navTools.nativeElement.scrollHeight > this.navTools.nativeElement.clientHeight ? true : false;
    }
  }

  showUserProfileMenu() {
    this.disableNavLinks$
      .pipe(
        take(1),
        tap(disabled => {
          this.popoverService.show(this.userProfileTrigger, UserProfileMenuComponent, null, { disabled });
        })
      )
      .subscribe();
  }

  protected readonly PartnerType = PartnerType;
  protected readonly NinetyFeatures = NinetyFeatures;
}
