import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { TippyDirective } from '@ngneat/helipopper';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { EmptyListViewModule } from '@ninety/_layouts/components/empty-list-view/empty-list-view.module';
import { WidgetTemplateType } from '@ninety/my-ninety/_models/widget-template-type';
import { TerraIconModule, TerraAvatarModule, TerraDividerModule } from '@ninety/terra';
import { CardModule } from '@ninety/ui/legacy/components/cards/card.module';
import {
  ButtonComponent,
  PopoverComponent,
  PopoverOriginDirective,
  WidgetSkeletonComponent,
} from '@ninety/ui/legacy/components/index';
import {
  BusinessOperatingSystem,
  ContactCardHoverDirective,
  UpcomingDatePipe,
  UserInitialsPipe,
} from '@ninety/ui/legacy/shared/index';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import {
  extractValueFromStore,
  selectBos,
  selectCurrentUser,
  selectCurrentUserIsManageeOrAbove,
  selectCurrentUserIsManagerOrAbove,
  selectIsHandsetPortrait,
} from '@ninety/ui/legacy/state/index';

import { Cadence } from '../../_models/cadence.enum';
import { MeetingSchedule } from '../../_models/meeting-schedule.model';
import { MeetingsV2Service } from '../../_services/meetings-v2.service';
import { MeetingSchedulingActions, MeetingDialogActions } from '../../_state/meetings.actions';
import { MeetingsStateSelectors } from '../../_state/meetings.selectors';

import { MeetingSchedulesContextMenuComponent } from './meeting-schedules-context-menu/meeting-schedules-context-menu.component';
import { CustomAmPmFormatTimePipe } from './pipes/custom-time-date.pipe';
import { DisableLaunchSchedulePipe } from './pipes/disable-launch-schedule.pipe';

@Component({
  selector: 'ninety-meeting-schedules',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    CardModule,
    MatTableModule,
    UpcomingDatePipe,
    TerraIconModule,
    TippyDirective,
    TerraAvatarModule,
    UserInitialsPipe,
    ButtonComponent,
    ContactCardHoverDirective,
    DisableLaunchSchedulePipe,
    MeetingSchedulesContextMenuComponent,
    MatMenuModule,
    EmptyListViewModule,
    WidgetSkeletonComponent,
    CustomAmPmFormatTimePipe,
    PopoverOriginDirective,
    PopoverComponent,
    TerraDividerModule,
  ],
  templateUrl: './meeting-schedules.component.html',
  styleUrls: ['./meeting-schedules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingSchedulesComponent implements OnInit {
  store = inject(Store);
  meetingService = inject(MeetingsV2Service);
  isUpcomingMeetingsPopoverOpen = false;

  @ViewChild(MeetingSchedulesContextMenuComponent) contextMenu: MeetingSchedulesContextMenuComponent;

  vm$ = {
    language: this.store.select(selectLanguage),
    meetingInProgress: this.store.select(MeetingsStateSelectors.selectMeetingInProgress),
    loading: this.store.select(MeetingsStateSelectors.selectScheduledMeetingsLoading),
    isMobile: this.store.select(selectIsHandsetPortrait),
    bos: this.store.select(selectBos),
  };

  private readonly TABLE_COLUMNS = [
    'frontSpacer',
    'date',
    'time',
    'agenda',
    'scribe',
    'presenter',
    'launch',
    'contextMenu',
    'endSpacer',
  ];
  private readonly TABLE_COLUMNS_LAUNCH_ONLY = [
    'frontSpacer',
    'date',
    'time',
    'agenda',
    'scribe',
    'presenter',
    'launch',
    'endSpacer',
  ];
  private readonly TABLE_COLUMNS_READ_ONLY = [
    'frontSpacer',
    'date',
    'time',
    'agenda',
    'scribe',
    'presenter',
    'endSpacer',
  ];

  columns: typeof this.TABLE_COLUMNS;

  Cadence = Cadence;

  LOADING_SKELETON_ROWS = 2;
  readonly WidgetTemplateType = WidgetTemplateType;
  readonly eos = BusinessOperatingSystem.eos;

  ngOnInit() {
    if (extractValueFromStore(this.store, selectCurrentUserIsManagerOrAbove)) {
      this.columns = this.TABLE_COLUMNS;
    } else if (extractValueFromStore(this.store, selectCurrentUserIsManageeOrAbove)) {
      this.columns = this.TABLE_COLUMNS_LAUNCH_ONLY;
    } else {
      this.columns = this.TABLE_COLUMNS_READ_ONLY;
    }
    this.store.dispatch(MeetingSchedulingActions.getMeetingSchedules());
  }

  createMeeting({ meetingType, agendaId }: MeetingSchedule) {
    this.store.dispatch(MeetingDialogActions.createMeeting({ meetingType, agendaId }));
  }

  onClickOpenContextMenu(event: MouseEvent, schedule: MeetingSchedule) {
    this.contextMenu.openMenu(event, schedule, extractValueFromStore(this.store, selectCurrentUser));
  }

  onDelete({ schedule }: { schedule: MeetingSchedule }) {
    this.store.dispatch(MeetingSchedulingActions.openDeleteMeetingScheduleDialog({ schedule }));
  }

  onEdit({ schedule }: { schedule: MeetingSchedule }) {
    this.store.dispatch(MeetingSchedulingActions.openUpdateMeetingScheduleDialog({ schedule }));
  }
}
