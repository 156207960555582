import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';
import { DataImportSelectors } from '@ninety/data-import/_state/data-import.selectors';
import { DataImportType } from '@ninety/data-import/models/data-import-types';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { NinetyFeatures } from '@ninety/ui/legacy/shared/models/company/company-pricing-tiers';
import { DataImportItemType } from '@ninety/ui/legacy/shared/models/enums/data-import-item-type';
import { selectTeamFilterOptions } from '@ninety/ui/legacy/state/composite-selectors/user-team.selectors';
import { selectFeatureEnabled } from '@ninety/ui/legacy/state/index';

@Component({
  selector: 'ninety-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataImportComponent implements OnInit, OnDestroy {
  protected readonly shownConfirmDialog$ = this.store.select(DataImportSelectors.selectShowedConfirmDialog);
  protected readonly showOneSchemaIframe$ = this.store.select(DataImportSelectors.selectShowOneSchemaIframe);
  protected readonly showPreviewTable$ = this.store.select(DataImportSelectors.selectShowPreviewTable);

  protected readonly templateOptions$ = this.store.select(DataImportSelectors.selectTemplateOptions);
  protected readonly showTeamFilter$ = this.store.select(DataImportSelectors.selectShowTeamFilter);
  protected readonly teamOptions$ = this.store.select(selectTeamFilterOptions);
  protected readonly selectedImportType$ = this.store.select(DataImportSelectors.selectImportType);
  protected readonly DataImportItemType = DataImportItemType;

  protected readonly hasAccess$ = this.store.select(selectFeatureEnabled(NinetyFeatures.dataImport));

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(DataImportActions.init());
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  selectTemplate(option: DataImportType): void {
    this.store.dispatch(DataImportActions.selectImportType({ option }));
  }

  selectTeam({ _id: teamId }: Team): void {
    this.store.dispatch(DataImportActions.selectTeam({ teamId }));
  }

  downloadCSVTemplate(): void {
    this.store.dispatch(DataImportActions.downloadCSVTemplate());
  }

  destroy(): void {
    this.store.dispatch(DataImportActions.destroy());
  }

  openConsentDialog(): void {
    this.store.dispatch(DataImportActions.showUsersImportConfirmDialog());
  }

  asDataImportType = (option): DataImportType => option as DataImportType;
  asTeam = (option): Team => option as Team;
}
