/* eslint-disable @angular-eslint/no-output-rename,@angular-eslint/no-outputs-metadata-property */
import { BooleanInput } from '@angular/cdk/coercion';
import { CdkListbox, ListboxValueChangeEvent } from '@angular/cdk/listbox';
import { Directive, Input } from '@angular/core';
import { map, merge, Observable, Subject } from 'rxjs';

@Directive({
  selector: '[ninetyOptionList]',
  standalone: true,
  hostDirectives: [
    {
      directive: CdkListbox,
      inputs: ['cdkListboxValue: selected', 'cdkListboxDisabled: disabled', 'cdkListboxCompareWith: compareWith'],
      outputs: ['cdkListboxValueChange'],
    },
  ],
})
export class OptionListDirective<T = unknown> {
  private readonly onValueSet = new Subject<readonly T[]>();

  constructor(private listbox: CdkListbox<T>) {}

  @Input() set multiple(value: BooleanInput) {
    // We explicitly manage this input in order to emit on the change stream. This prevents rare change detection issues as documented at
    // https://traxion.atlassian.net/browse/DEV-11432
    this.listbox.multiple = value;
    this.onValueSet.next(this.value);
  }

  /**
   * A stream which emits any time the value of the listbox changes. Note, {@link CdkListbox#valueChange} only emits
   * when the value is changed by a user event. This stream emits when the value is changed programmatically as well.
   * This is particularly useful for the distributed nature of the select module. It enables components whose `OnPush`
   * change detection would not change otherwise to react to changes in the value of the select box.
   */
  public readonly changeStream: Observable<readonly T[]> = merge(
    this.valueChanged.pipe(map(event => event.value)),
    this.onValueSet.asObservable()
  );

  get valueChanged(): Observable<ListboxValueChangeEvent<T>> {
    return this.listbox.valueChange;
  }

  get value(): T[] {
    return [...this.listbox.value];
  }

  set value(value: T[]) {
    this.listbox.value = value;
    this.onValueSet.next(value);
  }

  get multiple(): boolean {
    return this.listbox.multiple;
  }

  get isEmpty(): boolean {
    return this.listbox.value.length === 0;
  }

  get canBeCleared(): boolean {
    return !this.disabled && !this.isEmpty;
  }

  get showToggleButton(): boolean {
    return !this.disabled;
  }

  get disabled(): boolean {
    return this.listbox.disabled;
  }

  clear(): void {
    // This is code smell, but an acceptable one imo. This is just a "recipe", not Terra.
    // See https://traxion.atlassian.net/browse/DEV-10306
    const options = this.listbox['options'];
    this.listbox['triggerRange'](null, 0, options.length, false);
  }

  deselectValue(value: T): void {
    if (!this.multiple) {
      // Fixes bug preventing calling this method to clear the value of a single select
      this.clear();
      return;
    }

    // This is code smell, but an acceptable one imo. This is just a "recipe", not Terra.
    // See https://traxion.atlassian.net/browse/DEV-10306
    const options = this.listbox['options'];
    const option = options.find(o => o.value === value);
    this.listbox['triggerOption'](option);
  }
}
