<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="one-on-one" />
      <span data-cy="one-on-one-language_card-title">{{ oneOnOne.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="feedback">
      <terra-form-field>
        <terra-form-label>Page Name</terra-form-label>
        <terra-text-input
          formControlName="route"
          required
          [attr.aria-label]="oneOnOne.route + ' Page Name'"
          data-cy="one-on-one-language_page-name-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('route') !== resetLanguage?.route">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('route')"
              data-cy="one-on-one-language_page-name-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.item }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="item"
          required
          [attr.aria-label]="oneOnOne.item"
          data-cy="one-on-one-language_discussion-singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('item') !== resetLanguage?.item">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('item')"
              data-cy="one-on-one-language_discussion-singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.items }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="items"
          required
          [attr.aria-label]="oneOnOne.items"
          data-cy="one-on-one-language_discussion-plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('items') !== resetLanguage?.items">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('items')"
              data-cy="one-on-one-language_discussion-plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.quarterlyItem }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="quarterlyItem"
          required
          [attr.aria-label]="oneOnOne.quarterlyItem"
          data-cy="one-on-one-language_quarterly-discussion-singular-input-field">
          <terra-input-suffix
            *ngIf="!readonly && getFormControlValue('quarterlyItem') !== resetLanguage?.quarterlyItem">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('quarterlyItem')"
              data-cy="one-on-one-language_quarterly-discussion-singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.quarterlyItems }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="quarterlyItems"
          required
          [attr.aria-label]="oneOnOne.quarterlyItems"
          data-cy="one-on-one-language_quarterly-discussion-plural-input-field">
          <terra-input-suffix
            *ngIf="!readonly && getFormControlValue('quarterlyItems') !== resetLanguage?.quarterlyItems">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('quarterlyItems')"
              data-cy="one-on-one-language_quarterly-discussion-plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.annualItem }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="annualItem"
          required
          [attr.aria-label]="oneOnOne.annualItem"
          data-cy="one-on-one-language_annual-discussion-singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('annualItem') !== resetLanguage?.annualItem">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('annualItem')"
              data-cy="one-on-one-language_annual-discussion-singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.annualItems }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="annualItems"
          required
          [attr.aria-label]="oneOnOne.annualItems"
          data-cy="one-on-one-language_annual-discussion-plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('annualItems') !== resetLanguage?.annualItems">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('annualItems')"
              data-cy="one-on-one-language_annual-discussion-plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.managee }}</terra-form-label>
        <terra-text-input
          formControlName="managee"
          required
          [attr.aria-label]="oneOnOne.managee"
          data-cy="one-on-one-language_team-member-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('managee') !== resetLanguage?.managee">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('managee')"
              data-cy="one-on-one-language_team-member-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.manager }}</terra-form-label>
        <terra-text-input
          formControlName="manager"
          required
          [attr.aria-label]="oneOnOne.manager"
          data-cy="one-on-one-language_manager-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('manager') !== resetLanguage?.manager">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('manager')"
              data-cy="one-on-one-language_manager-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.meeting }}</terra-form-label>
        <terra-text-input
          formControlName="meeting"
          required
          [attr.aria-label]="oneOnOne.meeting"
          data-cy="one-on-one-language_meeting-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('meeting') !== resetLanguage?.meeting">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('meeting')"
              data-cy="one-on-one-language_meeting-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.peopleAnalyzer }}</terra-form-label>
        <terra-text-input
          formControlName="peopleAnalyzer"
          required
          [attr.aria-label]="oneOnOne.peopleAnalyzer"
          data-cy="one-on-one-language_fit-check-input-field">
          <terra-input-suffix
            *ngIf="!readonly && getFormControlValue('peopleAnalyzer') !== resetLanguage?.peopleAnalyzer">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('peopleAnalyzer')"
              data-cy="one-on-one-language_fit-check-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.CCC }} (Max length 4)</terra-form-label>
        <terra-text-input
          formControlName="CCC"
          required
          [attr.aria-label]="oneOnOne.CCC"
          maxlength="4"
          data-cy="one-on-one-language_ccc-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('CCC') !== resetLanguage?.CCC">
            <terra-icon icon="reset" (click)="resetOne.emit('CCC')" data-cy="one-on-one-language_ccc-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.competency }}</terra-form-label>
        <terra-text-input
          formControlName="competency"
          required
          [attr.aria-label]="oneOnOne.competency"
          data-cy="one-on-one-language_competency-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('competency') !== resetLanguage?.competency">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('competency')"
              data-cy="one-on-one-language_competency-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.commitment }}</terra-form-label>
        <terra-text-input
          formControlName="commitment"
          required
          [attr.aria-label]="oneOnOne.commitment"
          data-cy="one-on-one-language_commitment-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('commitment') !== resetLanguage?.commitment">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('commitment')"
              data-cy="one-on-one-language_commitment-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.capacity }}</terra-form-label>
        <terra-text-input
          formControlName="capacity"
          required
          [attr.aria-label]="oneOnOne.capacity"
          data-cy="one-on-one-language_capacity-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('capacity') !== resetLanguage?.capacity">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('capacity')"
              data-cy="one-on-one-language_capacity-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.LAC }} (Max length 4)</terra-form-label>
        <terra-text-input
          formControlName="LAC"
          required
          [attr.aria-label]="oneOnOne.LAC"
          maxlength="4"
          data-cy="one-on-one-language_lac-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('LAC') !== resetLanguage?.LAC">
            <terra-icon icon="reset" (click)="resetOne.emit('LAC')" data-cy="one-on-one-language_lac-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.leadershipCommitments }}</terra-form-label>
        <terra-text-input
          formControlName="leadershipCommitments"
          required
          [attr.aria-label]="oneOnOne.leadershipCommitments"
          data-cy="one-on-one-language_leadership-commitments-input-field">
          <terra-input-suffix
            *ngIf="!readonly && getFormControlValue('leadershipCommitments') !== resetLanguage?.leadershipCommitments">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('leadershipCommitments')"
              data-cy="one-on-one-language_leadership-commitments-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ oneOnOne.coachingCommitments }}</terra-form-label>
        <terra-text-input
          formControlName="coachingCommitments"
          required
          [attr.aria-label]="oneOnOne.coachingCommitments"
          data-cy="one-on-one-language_coaching-commitments-input-field">
          <terra-input-suffix
            *ngIf="!readonly && getFormControlValue('coachingCommitments') !== resetLanguage?.coachingCommitments">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('coachingCommitments')"
              data-cy="one-on-one-language_coaching-commitments-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
    </div>
  </mat-card-content>
</mat-card>
