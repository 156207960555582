import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep as _cloneDeep } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Observer,
  Subject,
  Subscription,
  catchError,
  concatMap,
  exhaustMap,
  filter,
  forkJoin,
  from,
  interval,
  map,
  merge,
  mergeMap,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { selectStoredWeeklyConversationId } from '@ninety/feedback/_state/weekly/weekly.selectors';
import { GuideActions } from '@ninety/getting-started/guide/_state/guide.actions';
import { HeadlineService } from '@ninety/headlines/_shared/services/headline.service';
import { IssueService } from '@ninety/issues/_shared/issue.service';
import { MeetingChangeStageDialogComponent } from '@ninety/meeting/meeting-change-stage-dialog/meeting-change-stage-dialog.component';
import { MeetingConcludeActions, MeetingStateActions } from '@ninety/pages/meetings/_state/meetings.actions';
import { RockService } from '@ninety/rocks/_shared/rock.service';
import { TodoService } from '@ninety/todos/_shared/todo.service';
import { TeamsApiService } from '@ninety/ui/legacy/core/index';
import { AuxiliaryRouterOutletService } from '@ninety/ui/legacy/core/services/auxiliary-router-outlet.service';
import { ChannelService } from '@ninety/ui/legacy/core/services/channel.service';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { NotifyService } from '@ninety/ui/legacy/core/services/notify.service';
import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import { SessionService } from '@ninety/ui/legacy/core/services/session.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { ConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { WarningConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/warning-confirm-dialog.component';
import { PagedResponse } from '@ninety/ui/legacy/shared/models/_shared/paged-response';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { SnackbarTemplateType } from '@ninety/ui/legacy/shared/models/enums/snackbar-template-type';
import { CONVERSATION_CHANNEL_PREFIX } from '@ninety/ui/legacy/shared/models/feedback/conversation-real-time.models';
import { IntervalCode } from '@ninety/ui/legacy/shared/models/issues/interval-code';
import { IssuesListSettingsMessage } from '@ninety/ui/legacy/shared/models/issues/issues-list-settings-message';
import { Headline } from '@ninety/ui/legacy/shared/models/meetings/headline';
import { HeadlinesResponse } from '@ninety/ui/legacy/shared/models/meetings/headlines-response';
import { ListDropMessage } from '@ninety/ui/legacy/shared/models/meetings/list-drop-message';
import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';
import { MeetingAgendaType } from '@ninety/ui/legacy/shared/models/meetings/meeting-agenda-type.enum';
import { MeetingMessage } from '@ninety/ui/legacy/shared/models/meetings/meeting-message';
import { MeetingMessageAction } from '@ninety/ui/legacy/shared/models/meetings/meeting-message-action';
import { MeetingSection } from '@ninety/ui/legacy/shared/models/meetings/meeting-section';
import { MeetingType } from '@ninety/ui/legacy/shared/models/meetings/meeting-type.enum';
import { MeetingsResponse } from '@ninety/ui/legacy/shared/models/meetings/meetings-response';
import { RatingMessage } from '@ninety/ui/legacy/shared/models/meetings/rating-message';
import type {
  RealtimeMessage,
  ReceivedRealtimeMessage,
} from '@ninety/ui/legacy/shared/models/meetings/realtime-message';
import { Stage } from '@ninety/ui/legacy/shared/models/meetings/stage';
import { StartMeetingData } from '@ninety/ui/legacy/shared/models/meetings/start-meeting-data';
import { UserRating } from '@ninety/ui/legacy/shared/models/meetings/user-rating';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import { TodoMessageType } from '@ninety/ui/legacy/shared/models/todos/todo-message-types';
import { selectCompanyUserByCompanyId } from '@ninety/ui/legacy/state/app-entities/company-users/company-users-state.selectors';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectCurrentUserId } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectCompanyId } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { RealTimeActions } from '@ninety/ui/legacy/state/app-global/real-time/real-time.actions';
import {
  CompanyUserListModel,
  CurrentUserSelectors,
  TeamListStateActions,
  TeamSelectors,
} from '@ninety/ui/legacy/state/index';
import { extractValueFromStore } from '@ninety/ui/legacy/state/state-util';

import { MeetingRealTimeActions } from '../../../pages/meetings/_state/meetings.actions';

@Injectable({
  providedIn: 'root',
})
export class MeetingService {
  private meetingsApi = '/api/v4/Meetings';
  private rocksUrl = '/api/v4/Rocks';
  private vtoApi = '/api/v4/Vto';
  private warnedConclude = false;
  meetingTimer$ = new BehaviorSubject<number>(0);
  sectionTimer$ = new BehaviorSubject<number>(0);
  progressBarValue$ = new BehaviorSubject<number>(0);
  stopSectionTimer$ = new Subject();
  validUsersRatingsForCurrentMeeting: boolean;
  navigationEnded$ = new BehaviorSubject<boolean>(false);
  team$ = new BehaviorSubject<Team>(null);
  ratedMessage$ = new Subject<RatingMessage>();
  dropListIssue$ = new Subject<ListDropMessage>();
  dropListHeadline$ = new Subject<ListDropMessage>();
  presenceChanged$ = new Subject();
  showNotes$ = new BehaviorSubject<boolean>(false);
  currentSectionDuration = 300;
  currentSectionLastTick: number;
  // Local copy of the current section for multiuser meetings
  // The meeting object keeps track of time. However, in weekly meetings, this is only done by the presenter
  // The rest of the users need an auxiliary way of keeping the time they are in a section
  // to show a timer and prevent discrete jumps when they receive updates from the presenter.
  currentSection: MeetingSection;
  // As presenter sends a message to update timers every 5 seconds, this variable was introduced to
  // use in calculations and not display a 5 secs gap
  timerOffset = 0;
  startingMeeting = false;
  subscriptions = new Subscription();
  notifiedStartedMeetingIds: string[] = [];

  activeMeetingsPaged$ = new BehaviorSubject<PagedResponse<Meeting>>(null);
  teamHeadlinesPaged$ = new BehaviorSubject<HeadlinesResponse>(null);
  teamCascadedMessagesPaged$ = new BehaviorSubject<HeadlinesResponse>(null);

  finishedMeeting$ = new Subject<Meeting>();
  doneHeadlineDocs$ = new BehaviorSubject<Headline[]>([]);
  cascadingMessageDocs$ = new BehaviorSubject<Headline[]>([]);

  votingEnabled$ = new BehaviorSubject<boolean>(false);

  IntervalCode = IntervalCode;
  currentMeeting: Meeting;

  private _currentMeeting: BehaviorSubject<Meeting> = new BehaviorSubject(null);
  /**
   * @deprecated Use meetingStateFeature.selectCurrentMeeting or meetingStateFeature.selectCurrentMeetingInProgress instead
   */
  public currentMeeting$: Observable<Meeting> = this._currentMeeting.asObservable();

  get currentTimestamp(): number {
    return Math.floor(new Date().getTime() / 1000);
  }

  private readonly rocksV3$ = this.featureFlags.getFlag(FeatureFlagKeys.webRocksV3);
  rocksV3 = false;
  //weekly conversations
  private readonly weeklyConversations$ = this.featureFlags.getFlag(FeatureFlagKeys.webWeeklyConversations);
  isWeeklyConversations = false;
  weeklyConversationsEnabled = false;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private filterService: FilterService,
    private errorService: ErrorService,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private router: Router,
    private matDialog: MatDialog,
    private dialog: MatDialog,
    public stateService: StateService,
    private notifyService: NotifyService,
    private teamApi: TeamsApiService,
    private channelService: ChannelService,
    private snackBar: MatSnackBar,
    private issueService: IssueService,
    private activatedRoute: ActivatedRoute,
    private auxiliaryRouterOutletService: AuxiliaryRouterOutletService,
    private todoService: TodoService,
    private rockService: RockService,
    private headlineService: HeadlineService,
    private store: Store,
    private featureFlags: FeatureFlagFacade
  ) {
    this.subscribeToMessages();

    this.subscribeToIssueEvents();
    this.subscribeToTodos();
    this.subscribeToRocks();
    this.subscribeToHeadlines();

    this.subscriptions.add(
      this.currentMeeting$.subscribe(currentMeeting => {
        this.currentMeeting = currentMeeting;
        if (currentMeeting === null) {
          this.currentSection = null;
          this.team$.next(null);
        }
      })
    );

    this.subscriptions.add(
      this.rocksV3$.subscribe(rocksV3 => {
        this.rocksV3 = rocksV3;
      })
    );

    const weeklyConversationEnabledSubscription = this.weeklyConversations$.subscribe(weeklyConversations => {
      this.weeklyConversationsEnabled = weeklyConversations;
    });

    this.subscriptions.add(weeklyConversationEnabledSubscription);
  }

  subscribeToTodos() {
    this.subscriptions.add(
      merge(this.todoService.newToDos$, this.todoService.todoAddedInline$)
        .pipe(
          filter(_ => this.currentMeeting?.inProgress),
          tap(({ todos }) => {
            this.currentMeeting.newTodoDocs = [...(this.currentMeeting.newTodoDocs || []), ...todos];
            todos.forEach((todo: Todo) => {
              this.currentMeeting.newTodos.push(todo._id);
            });
            this.store.dispatch(GuideActions.checkCompletionStatus());
          }),
          filter(({ fromBroadcast }) => !fromBroadcast),
          switchMap(_ => this.updateCurrentMeeting({ newTodos: this.currentMeeting?.newTodos })),
          switchMap(_ =>
            this.broadcastMessage({
              messageType: TodoMessageType.newTodos,
              document: {
                newTodos: this.currentMeeting?.newTodos,
              },
            })
          )
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.todoService.deletedTodo$
        .pipe(
          filter(_ => this.currentMeeting?.inProgress),
          tap(({ id }) => {
            this.currentMeeting.newTodos = this.currentMeeting?.newTodos?.filter(t => t !== id) ?? [];
            this.currentMeeting.doneTodos = this.currentMeeting?.doneTodos?.filter(t => t !== id) ?? [];
            this.currentMeeting.newTodoDocs = this.currentMeeting?.newTodoDocs?.filter(t => t._id !== id) ?? [];
          }),
          filter(({ fromBroadcast }) => !fromBroadcast),
          switchMap(_ =>
            this.updateCurrentMeeting({
              newTodos: this.currentMeeting?.newTodos,
              doneTodos: this.currentMeeting?.doneTodos,
            })
          )
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.todoService.deletedSeries$
        .pipe(
          filter(_ => this.currentMeeting?.inProgress),
          tap(({ id }) => {
            this.currentMeeting.newTodos = this.currentMeeting?.newTodos?.filter(t => t !== id) ?? [];
            this.currentMeeting.doneTodos = this.currentMeeting?.doneTodos?.filter(t => t !== id) ?? [];
            this.currentMeeting.newTodoDocs = this.currentMeeting?.newTodoDocs?.filter(t => t._id !== id) ?? [];
          }),
          filter(({ fromBroadcast }) => !fromBroadcast),
          switchMap(_ =>
            this.updateCurrentMeeting({
              newTodos: this.currentMeeting?.newTodos,
              doneTodos: this.currentMeeting?.doneTodos,
            })
          )
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.todoService.completionChange$
        .pipe(
          filter(({ fromBroadcast }) => !!this.currentMeeting?.inProgress && !fromBroadcast),
          mergeMap(({ id, completed }) => {
            if (completed) return this.addDoneTodo(id);
            else return this.removeDoneTodo(id);
          })
        )
        .subscribe()
    );
  }

  subscribeToWeeklyConversations() {
    this.subscriptions.add(
      this.store.select(selectStoredWeeklyConversationId).subscribe(weeklyConversationId => {
        this.isWeeklyConversations = !!weeklyConversationId;
      })
    );
  }

  subscribeToRocks() {
    this.subscriptions.add(
      this.rockService.newRock$
        .pipe(
          filter(_ => this.currentMeeting?.inProgress),
          tap(({ rock }) => {
            this.currentMeeting.newRocks = [...(this.currentMeeting.newRocks || []), rock._id];
          }),
          filter(({ fromBroadcast }) => !fromBroadcast),
          switchMap(_ => this.updateCurrentMeeting({ newRocks: this.currentMeeting?.newRocks }))
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.rockService.deletedRock$
        .pipe(
          filter(_ => this.currentMeeting?.inProgress),
          tap(({ rock }) => {
            this.currentMeeting.newRocks = this.currentMeeting?.newRocks?.filter(t => t !== rock._id) ?? [];
          }),
          filter(({ fromBroadcast }) => !fromBroadcast),
          switchMap(_ => this.updateCurrentMeeting({ newRocks: this.currentMeeting?.newRocks }))
        )
        .subscribe()
    );
  }

  private subscribeToHeadlines() {
    this.subscriptions.add(
      this.headlineService.newCascadingMessageFromUniversalCreate$
        .pipe(
          filter(() => !!this.currentMeeting?.inProgress),
          concatMap(({ headlines }) => forkJoin(headlines.map(headline => this.addCascadingMessage(headline._id))))
        )
        .subscribe()
    );
  }

  subscribeToMessages() {
    this.subscriptions.add(
      this.channelService.presenceChanged$.subscribe({
        next: (presence: any) => {
          if (this.currentMeeting) {
            if (presence?.channel?.startsWith(CONVERSATION_CHANNEL_PREFIX)) return;
            switch (presence.action) {
              case 'join':
                {
                  // try to add to current list
                  const userExists = this.currentMeeting.presentUsers?.some(u => u === presence.uuid);
                  if (!userExists) {
                    const newList = _cloneDeep(this.currentMeeting.presentUsers) || [];
                    newList.push(presence.uuid);
                    this.currentMeeting.presentUsers = newList;
                  }
                }
                break;
              case 'leave':
              case 'timeout':
                // try to remove from current list
                this.currentMeeting.presentUsers = this.currentMeeting?.presentUsers?.filter(u => u !== presence.uuid);
                break;
            }
            this.setCurrentMeeting(this.currentMeeting);
            this.votingEnabled$.next(this.currentMeeting.votingEnabled ?? false);
            this.presenceChanged$.next(null);
          }
        },
      })
    );

    this.subscriptions.add(
      this.channelService.currentPresenceInfo$.subscribe({
        next: (presence: any) => {
          if (this.currentMeeting && this.currentMeeting._id === presence.channel) {
            this.currentMeeting.presentUsers = presence.occupants;
            this.setCurrentMeeting(this.currentMeeting);
            this.presenceChanged$.next(null);

            if (this.currentMeeting.presenterUserId === this.stateService.currentCompanyUser._id) {
              this.broadcastMessage({
                messageType: 'change-stage',
                document: {
                  meetingElapsedTime: this.currentMeeting.elapsedTime,
                  stageElapsedTime: this.currentMeeting.sections.find(
                    section => section.name === this.currentMeeting.currentSection.name
                  ).elapsedTime,
                  stage: this.currentMeeting.currentSection.name,
                },
              }).subscribe();
            }
          }
        },
      })
    );

    this.subscriptions.add(
      this.channelService.messageReceived$.subscribe({
        next: message => {
          switch (message.messageType) {
            case 'fetch-object':
              this.handleMessageWithApiGet(message);
              break;
            case 'change-stage':
              if (this.currentMeeting) {
                this.executeStageChangeMessage(message);
                this.setCurrentMeeting(this.currentMeeting);
              }
              break;
            case 'meeting':
              this.executeMeetingMessageAction(message.document as MeetingMessage);
              if (message.document?.action !== MeetingMessageAction.Start) {
                this.setCurrentMeeting(this.currentMeeting);
              }
              break;
            case 'list-drop':
              this.executeListDrop(message.document as ListDropMessage);
              break;
            case 'rating-message':
              this.executeRatingMessage(message);
              break;
            case 'shuffle-users':
              this.executeShuffleUsersMessage(message.document);
              break;
            case 'worked-issues':
              this.executeWorkedIssuesMessage(message.document);
              break;
            case 'done-todos':
              this.executeDoneTodosMessage(message.document);
              break;
            case 'new-todos':
              this.executeNewTodosMessage(message.document);
              break;
            case 'done-headlines':
              this.executeDoneHeadlinesMessage(message.document);
              break;
            case 'cascading-headlines':
              this.executeCascadingHeadlinesMessage(message.document);
              break;
            case 'new-presenter':
              this.setCurrentMeeting(
                Object.assign(this.currentMeeting, { presenterUserId: message.document as string })
              );
              break;
            case 'meeting-issues-settings':
              this.executeIssuesSettingsMessage(message.document);
              break;
            case 'toggle-issue-voting':
              this.votingEnabled$.next(message.document);
              this.setCurrentMeeting({ ...this.currentMeeting, votingEnabled: message.document });
              break;
          }
        },
      })
    );
  }

  handleMessageWithApiGet(message: RealtimeMessage): void {
    // Get the required object from API and treat as a pubnub message
    switch (message.originalMessageType) {
      case 'meeting':
        this.getMeetingNotes(this.currentMeeting._id).subscribe({
          next: (meeting: Meeting) => {
            this.channelService.messageReceived$.next({
              messageType: message.originalMessageType,
              document: {
                action: MeetingMessageAction.Update,
                meeting: { notes: meeting.notes },
              },
            } as ReceivedRealtimeMessage);
          },
        });
        break;
      case 'worked-issues':
        this.getMeetingWorkedIssues(this.currentMeeting._id).subscribe({
          next: ({ workedIssues, workedIssueDocs }) => {
            this.channelService.messageReceived$.next({
              messageType: message.originalMessageType,
              document: {
                workedIssues,
                workedIssueDocs,
              },
            } as ReceivedRealtimeMessage);
          },
        });
        break;
      case 'done-headlines':
        this.getMeetingDoneHeadlines(this.currentMeeting._id).subscribe({
          next: ({ doneHeadlines, doneHeadlineDocs }) => {
            this.channelService.messageReceived$.next({
              messageType: message.originalMessageType,
              document: {
                doneHeadlines,
                doneHeadlineDocs,
              },
            } as ReceivedRealtimeMessage);
          },
        });
        break;
      case 'cascading-headlines':
        this.getMeetingCascadingMessages(this.currentMeeting._id).subscribe({
          next: ({ cascadingMessages, cascadingMessageDocs }) => {
            this.channelService.messageReceived$.next({
              messageType: message.originalMessageType,
              document: {
                cascadingMessages,
                cascadingMessageDocs,
              },
            } as ReceivedRealtimeMessage);
          },
        });
        break;
    }
  }

  executeStageChangeMessage(message: RealtimeMessage) {
    const stageMessage = message.document as Stage;
    this.currentMeeting.sections.forEach(section => (section.showGrayIndicator = section.name === stageMessage.stage));
    const sectionIndex = this.currentMeeting.sections.findIndex(section => section.name === stageMessage.stage);
    this.currentMeeting.sections[sectionIndex].elapsedTime = stageMessage.stageElapsedTime;
    if (this.currentSection?.name === stageMessage.stage)
      this.currentSection.elapsedTime = stageMessage.stageElapsedTime;

    this.timerOffset = 0; // set offset to 0 as we are in sync with the presenter again
    const concludeSection = this.currentMeeting.sections.find(s => s.path === 'conclude');
    if (stageMessage.stage === concludeSection.name && !this.warnedConclude) {
      this.warnedConclude = true;
      if (this.currentMeeting.currentSection.name !== stageMessage.stage) {
        this.matDialog
          .open(MeetingChangeStageDialogComponent, {
            data: {
              header: `The presenter has moved to ${concludeSection.name}`,
              // eslint-disable-next-line max-len
              body: `Follow the presenter now to enter your ${this.stateService.language.meeting.item} score.
                   <br />If you do not follow now, you may be able to enter your own score but will not see others.`,
              concludePageName: concludeSection.name,
            },
            maxWidth: '88vw',
          })
          .afterClosed()
          .pipe(
            filter((accepted: boolean) => accepted),
            mergeMap(() => {
              const route = [
                '/meeting',
                this.currentMeeting._id,
                this.getMeetingTypePath(this.stateService.meetingType),
                this.getAnnualDayPath(this.currentMeeting),
                'conclude',
              ];

              return from(this.navigate(route.filter(s => !!s)));
            })
          )
          .subscribe();
      }
    }
  }

  executeMeetingMessageAction(message: MeetingMessage) {
    switch (message.action) {
      case MeetingMessageAction.Continue:
        if (message.emitterId !== this.stateService.currentUser._id && this.currentMeeting.paused) {
          const sectionIndex = this.currentMeeting.sections.findIndex(section => section.name === message.stage);
          this.currentMeeting.sections[sectionIndex].elapsedTime = message.stageElapsedTime;
          this.toggleTimers(sectionIndex);
          this.snackBar.open(`The presenter has continued with the ${this.stateService.language.meeting.item}`, null, {
            duration: 5000,
          });
        }
        break;
      case MeetingMessageAction.Pause:
        if (message.emitterId !== this.stateService.currentUser._id && !this.currentMeeting.paused) {
          this.currentMeeting.elapsedTime = message.meetingElapsedTime;
          this.meetingTimer$.next(this.currentMeeting.elapsedTime);
          this.toggleTimers();
          this.snackBar.open(`The ${this.stateService.language.meeting.item} was paused by the presenter`, null, {
            duration: 5000,
          });
        }
        break;
      case MeetingMessageAction.Finished:
        this.snackBar.open(`${this.stateService.language.meeting.item} has finished`, undefined, { duration: 3000 });
        setTimeout(() => {
          this.clearMeeting();
          this.navigateToMeetingsHome();
        }, 3000);
        break;
      case MeetingMessageAction.Update:
        if (message.emitterId !== this.stateService.currentUser._id) {
          if (
            message.meeting.presenterUserId &&
            this.currentMeeting.presenterUserId !== this.stateService.currentUser._id &&
            message.meeting.presenterUserId === this.stateService.currentUser._id
          )
            this.notifyService.notify(`You are now the presenter of the ${this.stateService.language.meeting.item}`);
          this.setCurrentMeeting(Object.assign(this.currentMeeting, message.meeting));
        }
        break;
      case MeetingMessageAction.Start:
        if (message.emitterId !== this.stateService.currentUser._id) {
          const teamId = message.teamId;
          const onTeam = extractValueFromStore(this.store, CurrentUserSelectors.selectTeams).some(
            ut => ut._id === teamId
          );
          const alreadyNotified = this.notifiedStartedMeetingIds.some(n => n === message.meeting._id);
          if (!alreadyNotified) this.notifiedStartedMeetingIds.push(message.meeting._id);

          if (onTeam && !alreadyNotified && !this.currentMeeting) {
            const path = ['/meeting/', message.meeting._id, this.getMeetingTypePath(message.meeting.type)];
            const teams = extractValueFromStore(this.store, TeamSelectors.selectAll);
            this.notifyService.notifyWithTemplate(SnackbarTemplateType.newMeeting, {
              path,
              team: teams.find(t => t._id === message.teamId)?.name ?? '',
              meetingType: this.getMeetingTitle({ type: message.meeting.type, name: message.meeting.name }),
            });
          }
        }
    }
  }

  executeListDrop(message: ListDropMessage) {
    switch (message.listType) {
      case 'headlines':
      case 'cascadedMessages':
        this.dropListHeadline$.next(message);
        break;
    }
  }

  updateCurrentMeetingRatingValue(userId: string, rating?: number, absent?: boolean) {
    const auxIdx = this.currentMeeting.userRatings.findIndex(a => a.userId === userId);
    this.currentMeeting.absences[auxIdx] = absent || false;
    this.currentMeeting.userRatings[auxIdx].rating = rating;
  }

  updateCurrentMeetingRating(userId: string, rating?: number, absent?: boolean): Observable<any> {
    this.updateCurrentMeetingRatingValue(userId, rating, absent);
    return this.updateMeetingHttp(this.currentMeeting._id, {
      userRatings: this.currentMeeting.userRatings,
      absences: this.currentMeeting.absences,
    }).pipe(
      switchMap(_ =>
        this.broadcastMessage({
          messageType: 'rating-message',
          document: {
            userId: userId,
            rating: rating,
            absent: absent || false,
          },
        })
      ),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not update the ${this.stateService.language.meeting.item}. Please try again.`
        )
      )
    );
  }

  executeRatingMessage(message: RealtimeMessage) {
    const rating = message.document as RatingMessage;
    this.updateCurrentMeetingRatingValue(rating.userId, rating.rating, rating.absent);
    this.ratedMessage$.next(rating);
  }

  executeShuffleUsersMessage(messageDocument: Pick<Meeting, 'userRatings' | 'absences'>) {
    const { userRatings, absences } = messageDocument;
    this.setCurrentMeeting({
      ...this.currentMeeting,
      userRatings: _cloneDeep(userRatings),
      absences: _cloneDeep(absences),
    });
  }

  executeWorkedIssuesMessage(messageDocument: Pick<Meeting, 'workedIssues' | 'workedIssueDocs'>) {
    const { workedIssues = [], workedIssueDocs = [] } = messageDocument;
    this.setCurrentMeeting({ ...this.currentMeeting, workedIssues, workedIssueDocs });
  }

  executeNewTodosMessage(messageDocument: Pick<Meeting, 'newTodos'>) {
    const { newTodos } = messageDocument;
    this.setCurrentMeeting({ ...this.currentMeeting, newTodos });
  }

  executeDoneTodosMessage(messageDocument: Pick<Meeting, 'doneTodos'>) {
    const { doneTodos } = messageDocument;
    this.setCurrentMeeting({ ...this.currentMeeting, doneTodos });
  }

  executeDoneHeadlinesMessage(messageDocument: Pick<Meeting, 'doneHeadlines' | 'doneHeadlineDocs'>) {
    const { doneHeadlines = [], doneHeadlineDocs = [] } = messageDocument;
    this.setCurrentMeeting({ ...this.currentMeeting, doneHeadlines, doneHeadlineDocs });
    this.doneHeadlineDocs$.next(doneHeadlineDocs);
  }

  executeCascadingHeadlinesMessage(messageDocument: Pick<Meeting, 'cascadingMessages' | 'cascadingMessageDocs'>) {
    const { cascadingMessages = [], cascadingMessageDocs = [] } = messageDocument;
    this.setCurrentMeeting({ ...this.currentMeeting, cascadingMessages, cascadingMessageDocs });
    this.cascadingMessageDocs$.next(cascadingMessageDocs);
  }

  executeIssuesSettingsMessage(messageDocument: IssuesListSettingsMessage) {
    if (messageDocument.hasOwnProperty('issueVoting')) {
      const { teamId, value } = messageDocument.issueVoting;
      this.store.dispatch(
        TeamListStateActions.updateSelectedTeamSettingInMemory({
          teamId,
          changes: {
            issueVoting: value,
          },
        })
      );
    }
    if (messageDocument.hasOwnProperty('votesPerUser')) {
      this.setCurrentMeeting({ ...this.currentMeeting, votesPerUser: messageDocument.votesPerUser });
    }
    if (messageDocument.hasOwnProperty('issueRating')) {
      this.stateService.company.settings.issueRating = messageDocument.issueRating;
    }
    if (messageDocument.hasOwnProperty('issuesSolveRate')) {
      const { teamId, ...issuesSolveRate } = messageDocument.issuesSolveRate;

      this.store.dispatch(
        TeamListStateActions.updateSelectedTeamSettingInMemory({
          teamId,
          changes: {
            issuesSolveRate,
          },
        })
      );
    }
  }

  toggleShowNotes(): void {
    const value = this.showNotes$.value;
    this.showNotes$.next(!value);
  }

  startTimerBySectionPath(path?: string) {
    if (!path) {
      const splitUrl = this.router.url.split('/');
      path = splitUrl[splitUrl.length - 1];
    }
    const index = this.currentMeeting.sections.findIndex((s: MeetingSection) => s.path === path);
    if (index !== -1) this.startMeetingSectionTimer(index);
    else {
      this.navigate(['/meetings', this.getMeetingTypePath(this.stateService.meetingType)]);
    }
  }

  startMeetingSectionTimer(sectionIndex: number): void {
    this.stopSectionTimer$.next(null);
    this.currentSection =
      this.currentMeeting?.type !== MeetingType.weekly ||
      this.currentMeeting.presenterUserId === this.stateService.currentCompanyUser._id
        ? this.currentMeeting.sections[sectionIndex]
        : Object.assign({}, this.currentMeeting.sections[sectionIndex]); // Use a copy, don't update meeting object

    this.currentSectionDuration = this.currentSection.duration;
    this.currentSectionLastTick = this.currentTimestamp;

    if (this.currentMeeting.paused) {
      this.sectionTimer$.next(this.currentSection.elapsedTime);
      this.progressBarValue$.next((this.currentSection.elapsedTime / this.currentSection.duration) * 100);
      this.setTotalTimer();
      return;
    }
    this.subscriptions.add(
      interval(1000)
        .pipe(takeUntil(this.stopSectionTimer$))
        .subscribe({
          next: (sec: number) => {
            this.timerOffset += 1;
            const currentTick = this.currentTimestamp;
            if (this.currentSection) {
              this.currentSection.elapsedTime += currentTick - this.currentSectionLastTick;
              this.currentSectionLastTick = currentTick;
              this.sectionTimer$.next(this.currentSection.elapsedTime);
              this.progressBarValue$.next((this.currentSection.elapsedTime / this.currentSection.duration) * 100);
              if (
                this.currentMeeting?.type !== MeetingType.weekly ||
                this.currentMeeting.presenterUserId === this.stateService.currentCompanyUser._id
              ) {
                this.setTotalTimer();
              } else {
                this.setTotalTimer(this.timerOffset);
              }
            }
          },
        })
    );
    if (
      this.currentMeeting.presenterUserId === this.stateService.currentCompanyUser._id &&
      this.currentMeeting?.type === MeetingType.weekly
    ) {
      //as presenter, send updates of elapsed time to ensure time sync
      this.subscriptions.add(
        interval(5000)
          .pipe(takeUntil(this.stopSectionTimer$))
          .subscribe({
            next: (sec: number) => {
              this.broadcastMessage({
                messageType: 'change-stage',
                document: {
                  meetingElapsedTime: this.currentMeeting.elapsedTime,
                  stageElapsedTime: this.currentMeeting.sections.find(
                    (sectionItem: MeetingSection) => sectionItem.name === this.currentMeeting.currentSection.name
                  ).elapsedTime,
                  stage: this.currentMeeting.currentSection.name,
                },
              }).subscribe();
            },
          })
      );
      // update meeting every 5 min in the background.  this way if someone REALLY wants to go to another page, everything won't be lost
      this.subscriptions.add(
        interval(300000)
          .pipe(takeUntil(this.stopSectionTimer$))
          .subscribe({
            next: () => this.updateCurrentMeeting(this.currentMeeting).subscribe(),
          })
      );
    }
  }

  setTotalTimer(timerOffset = 0) {
    if (!this.currentMeeting) return;

    // doing it this way so that the times will add up correctly...there was a lag when using multiple timers and changing sections
    // Only update elapsedTime if presenter on L10
    const elapsedTime = this.currentMeeting.sections?.reduce((a, b) => a + b.elapsedTime, 0) + timerOffset;
    if (
      this.currentMeeting?.type !== MeetingType.weekly ||
      this.currentMeeting.presenterUserId === this.stateService.currentCompanyUser._id
    ) {
      this.currentMeeting.elapsedTime = elapsedTime;
    }
    this.meetingTimer$.next(elapsedTime);
  }

  toggleTimers(sectionIndex?: number): void {
    this.currentMeeting.paused = !this.currentMeeting.paused;
    if (!this.currentMeeting.paused) {
      this.startMeetingSectionTimer(sectionIndex || this.currentMeeting.currentSection.ordinal);
    } else {
      this.stopSectionTimer$.next(null);
    }
  }

  createNewMeeting(data: StartMeetingData): Observable<Meeting> {
    this.spinnerService.start();
    if (data.type === MeetingType.quarterly || data.type === MeetingType.annualDayOne)
      this.archiveCopyOfTeamVto(data.teamId);
    return this.http.post<Meeting>(`${this.meetingsApi}/StartMeeting`, data).pipe(
      tap((meeting: Meeting) => {
        this.startMeeting(meeting);
      }),
      catchError((e: unknown) => {
        this.startingMeeting = false;
        return this.errorService.notify(
          e,
          `Could not start ${this.stateService.language.meeting.item}.  Please try again.`
        );
      })
    );
  }

  private subscribeToIssueEvents() {
    this.subscriptions.add(
      this.issueService.newIssueForMetrics$
        .pipe(
          filter(issue =>
            this.canUpdateShortTermIssuesMetrics({
              isShortTerm: issue.intervalCode === IntervalCode.shortTerm,
              teamId: issue.teamId,
            })
          ),
          map(_ => this.getShortTermIssuesMetricsTotalTracked('add')),
          switchMap(shortTermIssuesMetricsTotalTracked =>
            this.updateShortTermIssuesMetrics(shortTermIssuesMetricsTotalTracked)
          )
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.issueService.deleteIssue$
        .pipe(
          filter(event => !!this.currentMeeting?.inProgress && !event?.localOnly),
          mergeMap(event => this.removeWorkedIssue(event.id).pipe(map(_ => event))),
          filter(event =>
            this.canUpdateShortTermIssuesMetrics({
              isShortTerm: this.issueService.isShortTerm,
              teamId: event.teamId,
            })
          ),
          map(_ => this.getShortTermIssuesMetricsTotalTracked('remove')),
          switchMap(shortTermIssuesMetricsTotalTracked =>
            this.updateShortTermIssuesMetrics(shortTermIssuesMetricsTotalTracked)
          )
        )
        .subscribe()
    );

    //NOTE: team update is disabled for worked issues
    this.subscriptions.add(
      this.issueService.teamChange$
        .pipe(
          filter(_ => this.canUpdateShortTermIssuesMetrics({ isShortTerm: this.issueService.isShortTerm })),
          map(event =>
            event.teamId === this.currentMeeting.teamId
              ? this.getShortTermIssuesMetricsTotalTracked('add')
              : this.getShortTermIssuesMetricsTotalTracked('remove')
          ),
          switchMap(shortTermIssuesMetricsTotalTracked =>
            this.updateShortTermIssuesMetrics(shortTermIssuesMetricsTotalTracked)
          )
        )
        .subscribe()
    );

    //NOTE: interval update is disabled for worked issues
    this.subscriptions.add(
      this.issueService.intervalChange$
        .pipe(
          filter(_ => this.canUpdateShortTermIssuesMetrics()),
          tap(event => {
            if (this.currentMeeting?.workedIssueDocs?.length) {
              const wi = this.currentMeeting.workedIssueDocs.find(wi => wi._id === event.id);
              if (wi) wi.intervalCode = event.intervalCode;
            }
          }),
          map(event =>
            event.intervalCode === IntervalCode.shortTerm
              ? this.getShortTermIssuesMetricsTotalTracked('add')
              : this.getShortTermIssuesMetricsTotalTracked('remove')
          ),
          switchMap(shortTermIssuesMetricsTotalTracked =>
            this.updateShortTermIssuesMetrics(shortTermIssuesMetricsTotalTracked)
          )
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.issueService.completionChange$
        .pipe(
          filter(_ => !!this.currentMeeting?.inProgress),
          mergeMap(({ id, isCompleted }) => {
            if (isCompleted) return this.addWorkedIssue(id);
            else return this.removeWorkedIssue(id);
          })
        )
        .subscribe()
    );
  }

  startMeeting(meeting: Meeting) {
    this.updateRocksV3Sections(meeting);
    // Need to fetch the current team to get the full object
    this.teamApi
      .getTeamById(meeting.teamId)
      .pipe(
        catchError((e: unknown) => {
          this.errorService.notify(
            e,
            `Could not find the team associated with this ${this.stateService.language.meeting.item}`,
            'Missing Team'
          );
          return ErrorService.handle(e);
        })
      )
      .subscribe(team => {
        this.team$.next(team);
        this.initializeMeeting(meeting);

        /** Remove once meetings v2 is GA */
        this.store.dispatch(MeetingStateActions.trackMeetingCreation({ meeting: _cloneDeep(meeting) }));

        this.startWebSocket(meeting);
      });
  }

  startWebSocket(meeting: Meeting) {
    // PubNub subscriptions
    this.channelService
      .subscribeToMeetingChannels(meeting._id, meeting.teamId)
      .pipe(
        tap(_ => this.navigateToMeeting(meeting)),
        concatLatestFrom(() => [this.store.select(selectCurrentUserId), this.store.select(selectCompanyId)]),
        tap(([_, currentUserId, companyId]) =>
          this.store.dispatch(
            RealTimeActions.sendMessage({
              channelId: companyId,
              message: {
                messageType: 'meeting',
                document: {
                  meeting: {
                    _id: meeting._id,
                    type: meeting.type,
                    name: meeting.name,
                  },
                  action: MeetingMessageAction.Start,
                  emitterId: currentUserId,
                  teamId: meeting.teamId,
                },
              },
            })
          )
        )
      )
      .subscribe({
        next: _ => {
          const alreadyNotified = this.notifiedStartedMeetingIds.some(n => n === meeting._id);
          if (!alreadyNotified) this.notifiedStartedMeetingIds.push(meeting._id);
        },
        error: (err: unknown) => {
          this.errorService.notify(
            err,
            `Something went wrong. Please reload to start/join the ${this.stateService.language.meeting.item}.`,
            undefined,
            {
              timeOut: 10000,
            }
          );
        },
      });
  }

  initializeMeeting(meeting: Meeting) {
    this.meetingTimer$.next(0);
    this.sectionTimer$.next(0);
    this.progressBarValue$.next(0);
    this.warnedConclude = false;
    this.setCurrentMeeting(meeting);

    if (meeting.doneHeadlineDocs) {
      this.doneHeadlineDocs$.next(meeting.doneHeadlineDocs);
    } else {
      this.doneHeadlineDocs$.next([]);
    }
    if (meeting.cascadingMessageDocs) {
      this.cascadingMessageDocs$.next(meeting.cascadingMessageDocs);
    } else {
      this.cascadingMessageDocs$.next([]);
    }

    this.stateService.meetingType = meeting.type;
    this.stateService.meetingIsAnnual =
      meeting.type === MeetingType.annualDayOne || meeting.type === MeetingType.annualDayTwo;
  }

  navigate(pathParts: readonly string[]) {
    const localPathParts = [...pathParts];

    let navigationExtras: NavigationExtras = {};
    if (localPathParts.length > 0 && localPathParts[pathParts.length - 1].includes('#')) {
      const [path, fragment] = localPathParts[localPathParts.length - 1].split('#');
      localPathParts[localPathParts.length - 1] = path;
      navigationExtras = {
        ...navigationExtras,
        fragment,
      };
    }

    return this.auxiliaryRouterOutletService.closeDetailBeforeNavigate(
      this.activatedRoute,
      localPathParts,
      navigationExtras
    );
  }

  navigateToMeeting(meeting: Meeting): void {
    this.navigate(this.getMeetingRoute(meeting.currentSection)).then(() => {
      this.setMeetingTitle();

      const currentCompanyId = extractValueFromStore(this.store, selectCompanyId) as string;
      if (currentCompanyId !== meeting.companyId) {
        const companyUser = extractValueFromStore(
          this.store,
          selectCompanyUserByCompanyId(meeting.companyId)
        ) as CompanyUserListModel;
        if (companyUser) this.sessionService.switchCompany(companyUser);
      }

      if (extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId) !== meeting.teamId) {
        window.sessionStorage.setItem('lastAccessedTeamId', meeting.teamId);
        this.stateService.currentCompanyUser$.value.lastAccessedTeamId = meeting.teamId;
        this.userService.update({ lastAccessedTeamId: meeting.teamId }).subscribe();
        this.filterService.setTeamId(meeting.teamId);
      }

      this.navigationEnded$.next(true);
      this.startingMeeting = false;
      this.startTimerBySectionPath(meeting.currentSection.path);
    });
  }

  getMeetingTitle(meeting: Partial<Meeting> = this.currentMeeting, skipDay = false): string {
    if (!meeting) return '';

    switch (meeting.type) {
      case MeetingType.quarterly:
        return this.stateService.language.meeting.quarterlySession;
      case MeetingType.annualDayOne:
        return `${skipDay ? '' : 'Day 1 - '} ${this.stateService.language.meeting.annualSession}`;
      case MeetingType.annualDayTwo:
        return `${skipDay ? '' : 'Day 2 - '}  - ${this.stateService.language.meeting.annualSession}`;
      case MeetingType.focusDay:
        return this.stateService.language.meeting.focusDay;
      case MeetingType.visionBuildingDayOne:
        return this.stateService.language.meeting.visionBuildingDayOne;
      case MeetingType.visionBuildingDayTwo:
        return this.stateService.language.meeting.visionBuildingDayTwo;
      case MeetingType.weekly:
        return this.stateService.language.meeting.levelTen;
      default:
        return meeting.name || 'Custom';
    }
  }

  isActive(meeting = this.currentMeeting): boolean {
    if (meeting.paused !== true && meeting.inProgress !== true) {
      return false;
    }
    return true;
  }

  setMeetingTitle(title?: string): void {
    if (title || this.currentMeeting) {
      const sectionTitle = title || this.currentMeeting.currentSection.name;
      this.stateService.setTitle(`${sectionTitle} | ${this.getMeetingTitle()}`);
    }
  }

  getMeetingRoute(section: MeetingSection = this.currentMeeting.currentSection): string[] {
    const route = [
      '/meeting/',
      this.currentMeeting._id,
      this.getMeetingTypePath(this.currentMeeting.type),
      this.getAnnualDayPath(this.currentMeeting),
      section.path,
    ];
    return route.filter(s => !!s);
  }

  getEndedMeetingRoute(meetingId: string): string[] {
    const route = ['/meeting/', meetingId, 'ended'];
    return route.filter(s => !!s);
  }

  getAnnualDayPath(meeting: Meeting): null | 'day1' | 'day2' {
    switch (meeting.type) {
      case MeetingType.annualDayOne:
      case MeetingType.visionBuildingDayOne:
        return 'day1';
      case MeetingType.annualDayTwo:
      case MeetingType.visionBuildingDayTwo:
        return 'day2';
      default:
        return null;
    }
  }

  getMeetingTypePath(type: MeetingType, dayOneOnly = false): MeetingType | 'annual' | 'focus-day' | 'vision-building' {
    switch (type) {
      case MeetingType.annualDayOne:
      case MeetingType.annualDayTwo:
        return dayOneOnly ? MeetingType.annualDayOne : 'annual';
      case MeetingType.focusDay:
        return 'focus-day';
      case MeetingType.visionBuildingDayOne:
      case MeetingType.visionBuildingDayTwo:
        return 'vision-building';
      default:
        return type;
    }
  }

  suspendCurrentMeeting() {
    const meetingId = this.currentMeeting._id;
    this.spinnerService.start();
    this.stopSectionTimer$.next(null);

    this.updateCurrentMeeting({
      ...this.currentMeeting,
      inProgress: false,
      paused: true,
      endDate: null,
    })
      .pipe(
        tap(_ => {
          this.team$.next(null);
          this.broadcastMeetingStateChange();
        })
      )
      .subscribe({
        next: () => {
          this.clearMeeting();
          this.store.dispatch(MeetingStateActions.suspendMeeting({ meetingId }));
          return this.navigateToMeetingsHome();
        },
      });
  }

  clearMeeting() {
    if (this.currentMeeting) {
      this.channelService.unsubscribeFromMeetingChannels(this.currentMeeting._id, this.currentMeeting.teamId);
      this.setCurrentMeeting(null);
      this.stopSectionTimer$.next(true);
      this.showNotes$.next(false);
    }
  }

  navigateToMeetingsHome() {
    this.navigate(['/meetings']);
  }

  changePresenter(newPresenterId: string): void {
    if (this.currentMeeting) {
      const partial = { presenterUserId: newPresenterId };
      this.updateCurrentMeeting(partial)
        .pipe(
          switchMap(() =>
            this.broadcastMessage({
              messageType: 'meeting',
              document: {
                action: MeetingMessageAction.Update,
                meeting: partial,
                emitterId: this.stateService.currentUser._id,
              },
            })
          )
        )
        .subscribe();
    }
  }

  archiveCopyOfTeamVto(teamId: string): void {
    this.http
      .post<string>(`${this.vtoApi}/ArchiveVtoCopyByTeamId?teamId=${teamId}`, {})
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not save copy of team ${this.stateService.language.vto.item}.  Please try again.`
          )
        )
      )
      .subscribe();
  }

  getAndStartMeeting(meetingId: string): void {
    this.spinnerService.start();
    this.http
      .get<Meeting>(`${this.meetingsApi}/${meetingId}/Start`)
      .pipe(
        catchError((err: unknown) => {
          this.spinnerService.stop();
          const e = err as HttpErrorResponse;
          if (e.status === 410) {
            this.navigate(this.getEndedMeetingRoute(meetingId));
            return of(null);
          }
          if (e.status === 403) {
            const errorMessage = e?.error?.errorMessage;
            if (errorMessage) return this.errorService.notify(e, errorMessage);
            else
              return this.errorService.notify(
                e,
                `Could not get ${this.stateService.language.meeting.item}.  Please try again.`
              );
          }

          return this.errorService.notify(
            e,
            `Could not get ${this.stateService.language.meeting.item}.  Please try again.`
          );
        }),
        filter(meeting => !!meeting)
      )
      .subscribe({
        next: (meeting: Meeting) => {
          this.startMeeting(meeting);
        },
      });
  }

  getMeeting(meetingId: string): Observable<Meeting> {
    this.spinnerService.startAuxiliary();
    return this.http.get<Meeting>(`${this.meetingsApi}/${meetingId}`).pipe(
      tap(() => {
        this.spinnerService.stopAuxiliary();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not get ${this.stateService.language.meeting.item}.  Please try again.`)
      )
    );
  }

  getMeetingNotes(meetingId: string): Observable<Partial<Meeting>> {
    this.spinnerService.startAuxiliary();
    return this.http.get<Partial<Meeting>>(`${this.meetingsApi}/${meetingId}/notes`).pipe(
      tap(() => {
        this.spinnerService.stopAuxiliary();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not get ${this.stateService.language.meeting.item}.  Please try again.`)
      )
    );
  }
  getMeetingWorkedIssues(meetingId: string): Observable<Partial<Meeting>> {
    this.spinnerService.startAuxiliary();
    return this.http.get<Partial<Meeting>>(`${this.meetingsApi}/${meetingId}/workedIssues`).pipe(
      tap(() => {
        this.spinnerService.stopAuxiliary();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not get ${this.stateService.language.meeting.item}.  Please try again.`)
      )
    );
  }

  getMeetingDoneHeadlines(meetingId: string): Observable<Partial<Meeting>> {
    this.spinnerService.startAuxiliary();
    return this.http.get<Partial<Meeting>>(`${this.meetingsApi}/${meetingId}/doneHeadlines`).pipe(
      tap(() => {
        this.spinnerService.stopAuxiliary();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not get ${this.stateService.language.meeting.item}.  Please try again.`)
      )
    );
  }

  getMeetingCascadingMessages(meetingId: string): Observable<Partial<Meeting>> {
    this.spinnerService.startAuxiliary();
    return this.http.get<Partial<Meeting>>(`${this.meetingsApi}/${meetingId}/cascadingMessages`).pipe(
      tap(() => {
        this.spinnerService.stopAuxiliary();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not get ${this.stateService.language.meeting.item}.  Please try again.`)
      )
    );
  }

  getMeetingDetail(meetingId: string, usePrimarySpinner = false): Observable<Meeting> {
    if (usePrimarySpinner) this.spinnerService.start();
    else this.spinnerService.startAuxiliary();
    return this.http.get<Meeting>(`${this.meetingsApi}/Detail/${meetingId}`).pipe(
      tap(() => {
        if (usePrimarySpinner) this.spinnerService.stop();
        else this.spinnerService.stopAuxiliary();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not get ${this.stateService.language.meeting.item}.  Please try again.`)
      )
    );
  }

  getPastMeetings(page: number, pageSize: number, includeDetails = false): Observable<MeetingsResponse> {
    if (!this.spinnerService.primary) this.spinnerService.start();
    const type = this.getMeetingTypePath(this.stateService.meetingType, true);
    const params = QueryParamsService.build({
      teamId: extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId),
      pageIndex: page,
      pageSize,
      includeDetails,
      type,
    });
    return this.http
      .get<MeetingsResponse>(`${this.meetingsApi}/Past`, { params })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not get ${this.stateService.language.meeting.items}.  Please try again.`)
        )
      );
  }

  getActiveMeetings(page: number, pageSize: number): Observable<MeetingsResponse> {
    if (!this.spinnerService.primary) this.spinnerService.start();
    const type = this.getMeetingTypePath(this.stateService.meetingType, true) as MeetingType;
    const params = QueryParamsService.build({
      teamId: extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId),
      pageIndex: page,
      pageSize,
      type,
    });

    return this.http.get<MeetingsResponse>(`${this.meetingsApi}/ActiveForTeams`, { params }).pipe(
      tap(response => this.activeMeetingsPaged$.next(response)),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not get active ${this.stateService.language.meeting.items}.
    Please try again.`
        )
      )
    );
  }

  setCurrentMeeting(meeting: Meeting | null): void {
    this._currentMeeting.next(meeting);

    this.store.dispatch(MeetingStateActions.updateCurrentMeeting({ update: _cloneDeep(meeting) }));
  }

  updateCurrentMeeting(update: Partial<Meeting> = {}, fromBroadcast = false): Observable<Meeting> {
    if (!this.currentMeeting) {
      if (fromBroadcast) return of(null);
      else return this.errorService.oops(new Error(`Could not update ${this.stateService.language.meeting.item}.`));
    }
    this.updateRocksV3Sections(this.currentMeeting);
    this.setCurrentMeeting(Object.assign(this.currentMeeting, update));

    if (!fromBroadcast) {
      //observers should not update a meeting
      if (this.stateService.isObserver) return of(this.currentMeeting);

      const updateToSend = { ...update };
      delete updateToSend.doneHeadlineDocs;
      delete updateToSend.cascadingMessageDocs;

      if (updateToSend.workedIssues) {
        delete updateToSend.workedIssues;
      }
      if (updateToSend.doneTodos) {
        delete updateToSend.doneTodos;
      }
      return this.updateMeetingHttp(this.currentMeeting._id, updateToSend);
    } else {
      if (update.doneHeadlineDocs) this.doneHeadlineDocs$.next(update.doneHeadlineDocs);
      if (update.cascadingMessageDocs) this.cascadingMessageDocs$.next(update.cascadingMessageDocs);
      return of(this.currentMeeting);
    }
  }

  updateMeetingHttp(meetingId: string, update: Partial<Meeting> = {}): Observable<Meeting> {
    if (!meetingId)
      this.errorService.notify(
        new Error(`${this.stateService.language.meeting.item} id is missing.  Please contact customer support.`)
      );

    delete update.newTodoDocs;
    delete update.workedIssueDocs;
    delete update.cascadingMessageDocs;
    delete update.todos; //is this needed?

    return this.http.patch<Meeting>(`${this.meetingsApi}/${meetingId}`, update).pipe(
      tap(() => this.spinnerService.stop()),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not update ${this.stateService.language.meeting.item}.  Please try again.`)
      )
    );
  }

  changeMeetingSection(section: MeetingSection, index: number): void {
    this.setMeetingTitle(section.name);
    this.startMeetingSectionTimer(index);
    this.setCurrentMeeting(this.currentMeeting);
    if (this.currentMeeting.presenterUserId === this.stateService.currentUser._id) {
      this.updateCurrentMeeting({ ...this.currentMeeting, currentSection: section }).subscribe();
      this.setMeetingTitle();
    } else {
      this.updateCurrentMeeting({ currentSection: section }).subscribe();
    }
  }

  deleteMeeting(meeting: Meeting | Partial<Meeting>): Observable<boolean> {
    return this.confirmDeleteMeetingDialog().pipe(
      mergeMap((confirmed: boolean) => {
        if (confirmed) {
          if (!this.spinnerService.primary) this.spinnerService.start();
          const nextOrPreviousMeeting = meeting.nextMeetingId || meeting.previousMeetingId;
          return forkJoin({
            meeting: this.http.patch<Meeting>(`${this.meetingsApi}/${meeting._id}`, { isDeleted: true }),
            nextOrPreviousMeeting: nextOrPreviousMeeting
              ? this.http.patch<Meeting>(`${this.meetingsApi}/${nextOrPreviousMeeting}`, { isDeleted: true })
              : of(null),
          }).pipe(
            map(() => {
              this.spinnerService.stop();
              return true;
            }),
            catchError((e: unknown) =>
              this.errorService.notify(
                e,
                `Could not delete ${this.stateService.language.meeting.item}.
              Please try again.`
              )
            )
          );
        }
        return of(false);
      })
    );
  }

  executeFinish(): void {
    this.spinnerService.start();
    const meeting = _cloneDeep(this.currentMeeting);
    meeting.inProgress = false;
    meeting.endDate = new Date();
    meeting.paused = false;

    this.broadcastMessage({
      messageType: 'meeting',
      document: {
        action: MeetingMessageAction.Finished,
      },
    }).subscribe();

    this.updateCurrentMeeting({
      ...this.currentMeeting,
      inProgress: false,
      endDate: new Date(),
      paused: false,
      ...(this.team$.value.settings?.issuesSolveRate && this.currentMeeting.shortTermIssuesMetricsTotalTracked != null
        ? { shortTermIssuesSolveRate: this.team$.value.settings.issuesSolveRate }
        : null),
    })
      .pipe(
        tap(_ => {
          this.team$.next(null);
          this.broadcastMeetingStateChange();
        })
      )
      .subscribe({
        next: () => {
          this.channelService.unsubscribe(meeting._id);
          if (this.isWeeklyConversations && this.weeklyConversationsEnabled) {
            this.router.navigate(['/1-on-1/discussions/weekly']);
          } else {
            this.navigate(['/meetings']);
          }
          this.stopSectionTimer$.next(true);
          if (this.currentMeeting?.type !== MeetingType.annualDayOne) this.sendMeetingRecapEmail(this.currentMeeting);
          this.setCurrentMeeting(null);
          this.store.dispatch(MeetingConcludeActions.meetingConcluded({ meetingType: this.currentMeeting?.type }));
          this.showNotes$.next(false);
        },
        error: (e: unknown) =>
          this.errorService.notify(
            e,
            `Could not save & exit ${this.stateService.language.meeting.item}.  Please try again.`
          ),
      });
  }

  finishMeeting(): void {
    const confirmFinishDialogRef = this.dialog.open<WarningConfirmDialogComponent, ConfirmDialogData>(
      WarningConfirmDialogComponent,
      {
        data: {
          title:
            `Once confirmed, the ${this.stateService.language.meeting.item} will end for all participants. ` +
            `Are you sure you want to finish the ${this.stateService.language.meeting.item}?`,
          confirmButtonText: 'Finish',
        },
      }
    );

    confirmFinishDialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          this.executeFinish();
        }
      },
    });
  }

  sendMeetingRecapEmail(meeting: Meeting, sendAnyway = false): void {
    if (sendAnyway) this.spinnerService.start();
    if (sendAnyway || meeting.shouldSendRecap) {
      const meetingId = meeting.previousMeetingId ? meeting.previousMeetingId : meeting._id;
      this.http
        .post<any>(`${this.meetingsApi}/${meetingId}/SendRecap?type=${meeting.type}`, {})
        .pipe(
          tap(() => {
            if (sendAnyway) {
              this.spinnerService.stop();
              this.notifyService.notify('Recap Email Sent.');
            }
          }),
          catchError((e: unknown) => {
            const err = e as HttpErrorResponse;
            if (err.status === 400) {
              return this.errorService.notify(
                e,
                `Could not send Meeting recap email because there are no active users.`
              );
            }
            return this.errorService.notify(
              e,
              `Could not send ${this.stateService.language.meeting.item} recap email.`
            );
          })
        )
        .subscribe();
    }
  }

  checkRatingsAndAbsences(): Observable<boolean> {
    const confirmMarkingAbsentRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(
      ConfirmDialogComponent,
      {
        data: {
          title: 'All those without a rating will be marked as absent.',
        },
      }
    );
    return confirmMarkingAbsentRef.afterClosed();
  }

  getMeetingAgendaType(type: MeetingType = this.stateService.meetingType): MeetingAgendaType {
    switch (type) {
      case MeetingType.quarterly:
        return MeetingAgendaType.quarterly;
      case MeetingType.annualDayOne:
        return MeetingAgendaType.annualDayOne;
      case MeetingType.annualDayTwo:
        return MeetingAgendaType.annualDayTwo;
      case MeetingType.focusDay:
        return MeetingAgendaType.focusDay;
      case MeetingType.visionBuildingDayOne:
        return MeetingAgendaType.visionBuildingDayOne;
      case MeetingType.visionBuildingDayTwo:
        return MeetingAgendaType.visionBuildingDayTwo;
      case MeetingType.weekly:
      default:
        return MeetingAgendaType.weekly;
    }
  }

  ///////////////////////////// Issues /////////////////////////////////////
  addWorkedIssue(id: string): Observable<Meeting> {
    return this.http
      .patch<Partial<Meeting>>(`${this.meetingsApi}/${this.currentMeeting._id}/addWorkedIssue`, { _id: id })
      .pipe(
        tap(({ workedIssues, workedIssueDocs }) =>
          this.broadcastMessage({
            messageType: 'worked-issues',
            document: {
              workedIssues,
              workedIssueDocs,
            },
          }).subscribe()
        ),
        exhaustMap(({ workedIssues, workedIssueDocs }) =>
          this.updateCurrentMeeting({ workedIssues, workedIssueDocs }, true)
        ),
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not update ${this.stateService.language.meeting.item}.  Please try again.`)
        )
      );
  }

  removeWorkedIssue(id: string): Observable<Meeting> {
    return this.http
      .patch<Partial<Meeting>>(`${this.meetingsApi}/${this.currentMeeting._id}/removeWorkedIssue`, { _id: id })
      .pipe(
        tap(({ workedIssues, workedIssueDocs }) =>
          this.broadcastMessage({
            messageType: 'worked-issues',
            document: {
              workedIssues,
              workedIssueDocs,
            },
          }).subscribe()
        ),
        exhaustMap(({ workedIssues, workedIssueDocs }) =>
          this.updateCurrentMeeting({ workedIssues, workedIssueDocs }, true)
        ),
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not update ${this.stateService.language.meeting.item}.  Please try again.`)
        )
      );
  }

  ///////////////////////////// Rocks /////////////////////////////////////
  getRockById(id: string): Observable<Rock> {
    return this.http
      .get<Rock>(`${this.rocksUrl}/${id}`)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not get ${this.stateService.language.rock.items}. Please try again.`)
        )
      );
  }

  confirmDeleteMeetingDialog(): Observable<boolean> {
    const confirmDeleteDialogRef = this.dialog.open<WarningConfirmDialogComponent, ConfirmDialogData>(
      WarningConfirmDialogComponent,
      {
        data: {
          title: `Are you sure you want to delete this ${this.stateService.language.meeting.item}?`,
        },
      }
    );
    return confirmDeleteDialogRef.afterClosed();
  }

  broadcastMessage(message: RealtimeMessage): Observable<any> {
    if (this.currentMeeting?.inProgress) {
      return this.channelService.sendMessage(this.currentMeeting._id, message);
    }

    return new Observable((observer: Observer<void>) => {
      observer.next(null);
      observer.complete();
    });
  }

  ///////////////////////////// ToDos /////////////////////////////////////
  addDoneTodo(id: string): Observable<Meeting> {
    const updatedDoneTodos = [...(this.currentMeeting.doneTodos ?? []), id];

    this.broadcastMessage({
      messageType: TodoMessageType.done,
      document: {
        doneTodos: updatedDoneTodos,
      },
    }).subscribe();

    return this.http.patch<string[]>(`${this.meetingsApi}/${this.currentMeeting._id}/addDoneTodo`, { _id: id }).pipe(
      exhaustMap(doneTodos => this.updateCurrentMeeting({ doneTodos }, true)),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not update ${this.stateService.language.meeting.item}.  Please try again.`)
      )
    );
  }

  removeDoneTodo(id: string): Observable<Meeting> {
    const updatedDoneTodos = [...(this.currentMeeting.doneTodos ?? []).filter(wId => wId !== id)];

    this.broadcastMessage({
      messageType: TodoMessageType.done,
      document: {
        doneTodos: updatedDoneTodos,
      },
    }).subscribe();

    return this.http.patch<string[]>(`${this.meetingsApi}/${this.currentMeeting._id}/removeDoneTodo`, { _id: id }).pipe(
      exhaustMap(doneTodos => this.updateCurrentMeeting({ doneTodos }, true)),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not update ${this.stateService.language.meeting.item}.  Please try again.`)
      )
    );
  }

  ///////////////////////////// Headlines /////////////////////////////////////
  addDoneHeadline(id: string): Observable<Meeting> {
    return this.http
      .patch<Partial<Meeting>>(`${this.meetingsApi}/${this.currentMeeting._id}/addDoneHeadline`, { _id: id })
      .pipe(
        tap(({ doneHeadlines, doneHeadlineDocs }) =>
          this.broadcastMessage({
            messageType: 'done-headlines',
            document: {
              doneHeadlines,
              doneHeadlineDocs,
            },
          }).subscribe()
        ),
        exhaustMap(({ doneHeadlines, doneHeadlineDocs }) =>
          this.updateCurrentMeeting({ doneHeadlines, doneHeadlineDocs }, true)
        ),
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not update ${this.stateService.language.meeting.item}.  Please try again.`)
        )
      );
  }

  removeDoneHeadline(id: string): Observable<Meeting> {
    return this.http
      .patch<Partial<Meeting>>(`${this.meetingsApi}/${this.currentMeeting._id}/removeDoneHeadline`, { _id: id })
      .pipe(
        tap(({ doneHeadlines, doneHeadlineDocs }) =>
          this.broadcastMessage({
            messageType: 'done-headlines',
            document: {
              doneHeadlines,
              doneHeadlineDocs,
            },
          }).subscribe()
        ),
        exhaustMap(({ doneHeadlines, doneHeadlineDocs }) =>
          this.updateCurrentMeeting({ doneHeadlines, doneHeadlineDocs }, true)
        ),
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not update ${this.stateService.language.meeting.item}.  Please try again.`)
        )
      );
  }

  addCascadingMessage(id: string): Observable<Meeting> {
    return this.http
      .patch<Partial<Meeting>>(`${this.meetingsApi}/${this.currentMeeting._id}/addCascadingMessage`, { _id: id })
      .pipe(
        tap(({ cascadingMessages, cascadingMessageDocs }) =>
          this.broadcastMessage({
            messageType: 'cascading-headlines',
            document: {
              cascadingMessages,
              cascadingMessageDocs,
            },
          }).subscribe()
        ),
        exhaustMap(({ cascadingMessages, cascadingMessageDocs }) =>
          this.updateCurrentMeeting({ cascadingMessages, cascadingMessageDocs }, true)
        ),
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not update ${this.stateService.language.meeting.item}.  Please try again.`)
        )
      );
  }

  removeCascadingMessage(id: string): Observable<Meeting> {
    return this.http
      .patch<Partial<Meeting>>(`${this.meetingsApi}/${this.currentMeeting._id}/removeCascadingMessage`, { _id: id })
      .pipe(
        tap(({ cascadingMessages, cascadingMessageDocs }) =>
          this.broadcastMessage({
            messageType: 'cascading-headlines',
            document: {
              cascadingMessages,
              cascadingMessageDocs,
            },
          }).subscribe()
        ),
        exhaustMap(({ cascadingMessages, cascadingMessageDocs }) =>
          this.updateCurrentMeeting({ cascadingMessages, cascadingMessageDocs }, true)
        ),
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not update ${this.stateService.language.meeting.item}.  Please try again.`)
        )
      );
  }

  validRatings(): boolean {
    this.validUsersRatingsForCurrentMeeting = this.currentMeeting?.userRatings?.every(
      (r: UserRating) => r.rating == null || (r.rating >= 1 && r.rating <= 10)
    );

    return this.validUsersRatingsForCurrentMeeting;
  }

  toggleVotingEnabled(value: boolean): void {
    this.votingEnabled$.next(value);
    this.updateCurrentMeeting({ votingEnabled: value })
      .pipe(
        tap(_ =>
          this.broadcastMessage({
            messageType: 'toggle-issue-voting',
            document: value,
          }).subscribe()
        ),
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not update the voting setting. Please try again.`)
        )
      )
      .subscribe();
  }

  updateRocksV3Sections(meeting: Meeting) {
    if (this.rocksV3) {
      const rockSectionNames: readonly string[] = ['ROCK REVIEW', 'ROCKS', 'SET ROCKS'];
      const rockSection = meeting.sections.find(s => rockSectionNames.includes(s.name.toUpperCase()));
      if (rockSection) {
        rockSection.path = 'rocks-v3';
      }

      if (meeting.currentSection.path === 'rocks') {
        meeting.currentSection.path = 'rocks-v3';
      }
    }
  }

  private updateShortTermIssuesMetrics(shortTermIssuesMetricsTotalTracked: number): Observable<Meeting> {
    return this.updateCurrentMeeting({ shortTermIssuesMetricsTotalTracked }).pipe(
      tap(_ =>
        this.broadcastMessage({
          messageType: 'meeting',
          document: {
            action: MeetingMessageAction.Update,
            meeting: { shortTermIssuesMetricsTotalTracked },
          },
        }).subscribe()
      ),
      catchError((e: unknown) => this.errorService.notify(e, `Could not update total metrics. Please try again.`))
    );
  }

  private getShortTermIssuesMetricsTotalTracked(action: 'add' | 'remove'): number {
    return action === 'add'
      ? this.currentMeeting.shortTermIssuesMetricsTotalTracked + 1
      : Math.max(0, this.currentMeeting.shortTermIssuesMetricsTotalTracked - 1);
  }

  private canUpdateShortTermIssuesMetrics(extraOptionsToCheck: { isShortTerm?: boolean; teamId?: string } = {}) {
    const { isShortTerm, teamId } = extraOptionsToCheck;
    return (
      this.currentMeeting?.inProgress &&
      this.currentMeeting.shortTermIssuesMetricsTotalTracked != null &&
      (isShortTerm == null || isShortTerm) &&
      (!teamId || teamId === this.currentMeeting.teamId)
    );
  }

  /** Notifies users who are on the meetings page but not attending a meeting to refresh their meeting view. */
  private broadcastMeetingStateChange() {
    this.store.dispatch(MeetingRealTimeActions.meetingStateChange());
  }
}
