import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { FilterBarActions } from '@ninety/layouts/_state/filterbar/filterbar-state.actions';
import { TerraIconModule } from '@ninety/terra';
import { NinetyCMModule } from '@ninety/ui/legacy/components/commercial-model/cm.module';
import { selectLanguage } from '@ninety/ui/legacy/state/index';

@Component({
  standalone: true,
  imports: [CommonModule, TerraIconModule, NinetyCMModule, LetDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ninety-meetings-tiered-page',
  template: `<ninety-cm-tiered-page *ngrxLet="language$ as language">
    <div headline>Run {{ language.meeting.items }} That Fit Your Business</div>
    <div description>
      Unlock the {{ language.meeting.items }} tool to run effective {{ language.meeting.levelTens }},
      {{ language.meeting.quarterly }}, and {{ language.meeting.annual }} Team {{ language.meeting.items }} and stay
      aligned on your goals.
    </div>
    <button ninety-cm-button>
      <terra-icon icon="lightning" variant="fill" />
      Explore Essential Plan
    </button>
    <a href="https://www.ninety.io" rel="external" target="_blank">Learn more about {{ language.meeting.items }}</a>
  </ninety-cm-tiered-page>`,
})
export class MeetingsTieredPageComponent implements OnInit {
  private readonly store = inject(Store);
  language$ = this.store.select(selectLanguage);

  ngOnInit(): void {
    this.store.dispatch(FilterBarActions.hide());
  }
}
