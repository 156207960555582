import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { getRouterSelectors, SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import ObjectId from 'bson-objectid';

import { DetailType } from '../_shared/models/_shared/detail-type.enum';
import { routeNames } from '../_shared/models/constants/route-names';

// `router` is used as the default feature name. You can use the feature name
// of your choice by creating a feature selector and pass it to the `getSelectors` function
// export const selectRouter = createFeatureSelector<RouterReducerState>('yourFeatureName');
export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
  selectTitle, // Select the title if available
} = getRouterSelectors();

const v2DetailTypes: ReadonlyArray<DetailType> = [
  DetailType.cascadingMessage,
  DetailType.chart,
  DetailType.headline,
  DetailType.kpiGroup,
  DetailType.kpi,
  DetailType.kpiV2,
  DetailType.reorderKpiGroups,
  DetailType.scorecard,
  DetailType.seat,
  DetailType.todo,
  DetailType.rockStoreV2,
  DetailType.rockV3,
];

/* 
  Utility function for locating parameter in the route tree

  Example usage:
  ofType(ROUTER_NAVIGATED),
  map((action: RouterNavigationAction) => recurseRouteToFindParameter('id', action.payload.routerState)),

*/
export function recurseRouteToFindParameter(paramName: string, route: SerializedRouterStateSnapshot): string {
  const value = findParameter(paramName, route.root.children);
  return value;
}

function findParameter(paramName, children) {
  for (let idx = 0; idx < children.length; idx++) {
    if (children && children[idx].params[paramName]) {
      return children[idx].params[paramName];
    } else if (children[idx].children) {
      const val = findParameter(paramName, children[idx].children);
      if (val) {
        return val;
      }
    }
  }
  return '';
}

/** Utility function which checks if the current route is of a specific detail type */
export function routeIsOfDetailType(parsedUrl: UrlTree, type: DetailType) {
  return parsedUrl.root.children.detail?.segments[1]?.path === type;
}

export const selectUrlTree = createSelector(selectUrl, url => new DefaultUrlSerializer().parse(url));

export const selectIsV2DetailRoute = createSelector(selectUrlTree, parsedUrl =>
  v2DetailTypes.some(type => routeIsOfDetailType(parsedUrl, type))
);

export const selectRouteIsOfDetailType = (type: DetailType) =>
  createSelector(selectUrlTree, parsedUrl => routeIsOfDetailType(parsedUrl, type));

export const selectIsMyNinetyUrl = createSelector(
  selectUrlTree,
  parsedUrl => parsedUrl.root.children.primary?.segments[0]?.path === routeNames.myfocus
);

export const selectIsLoginUrl = createSelector(selectUrlTree, parsedUrl => {
  const segments = parsedUrl.root.children.primary?.segments;
  return segments && segments.length === 1 && segments[0].path === 'login';
});

export const selectIsInMeeting = createSelector(selectUrlTree, parsedUrl => {
  const [meetingSegment, meetingIdSegment] = parsedUrl.root.children.primary?.segments;
  return meetingSegment.path === routeNames.meeting && ObjectId.isValid(meetingIdSegment.path);
});
