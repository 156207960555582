<div class="maz-message" [class.user-message]="message.sender === MazMessageSender.user">
  <div class="main-content">
    <div class="message-body">
      <div class="maz-icon">
        <terra-icon icon="sparkle" size="12" variant="fill" />
      </div>
      <div [innerHTML]="message | mazMessageHtml" class="message"></div>
      <terra-avatar [initials]="avatarInfo.userInitials" [src]="avatarInfo.pictureURL" size="small"></terra-avatar>
    </div>
    <div class="message-actions">
      <ninety-button (click)="closeMazAndOpenIntercom.emit()" compact ninetyTooltip="Ask a 90 rep instead" secondary>
        <div class="open-intercom-btn">
          <img alt="Intercom-Logo" class="intercom-logo" src="/assets/images/intercom-logo.png" />
        </div>
      </ninety-button>
      <ninety-button
        (click)="toggleFlag()"
        [ninetyTooltip]="
          message.feedback?.flag !== MazFeedbackFlag.incorrect ? 'This answer is incorrect' : 'Remove this flag'
        "
        class="incorrect-btn"
        compact
        secondary>
        <terra-icon
          *ngIf="message.feedback?.flag !== MazFeedbackFlag.incorrect; else incorrectIcon"
          icon="warning-circle"
          size="20" />
        <ng-template #incorrectIcon>
          <terra-icon class="incorrect" icon="warning-circle" size="20" variant="fill" />
        </ng-template>
      </ninety-button>
    </div>
  </div>
  <div *ngIf="showFeedback" class="message-feedback">
    <div class="feedback-header">
      <div class="ninety-logo-small">
        <img alt="90-logo" src="/assets/icons/ninety/90_Logo_Square_Margins_Black.svg" />
      </div>
      <div class="feedback-header-text">Tell us more</div>
      <ninety-button
        (click)="closeFeedback()"
        compact
        data-cy="maz-message-feedback_close"
        flat
        icon
        ninetyTooltip="Close">
        <terra-icon icon="x" />
      </ninety-button>
    </div>
    <div class="feedback-body">
      <mat-form-field appearance="outline" class="flex1 _terra-migration-approved-override--mat-text-area">
        <textarea
          #autosize="cdkTextareaAutosize"
          [(ngModel)]="feedbackText"
          cdkAutosizeMaxRows="5"
          cdkAutosizeMinRows="3"
          cdkTextareaAutosize
          class="full-width"
          matInput
          placeholder="We're always looking for ways to improve. Provide any feedback here!"></textarea>
      </mat-form-field>
    </div>
    <div class="feedback-actions">
      <ninety-button
        (click)="onSubmitFeedback()"
        [disabled]="!feedbackText?.length"
        compact
        data-cy="maz-message-feedback_submit"
        flat
        primary>
        Submit
      </ninety-button>
    </div>
  </div>
</div>
