import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { BILLING_V2_ROOT_PATH } from '@ninety/ui/legacy/core/guards/active-subscription.guard';
import { AuthGuard } from '@ninety/ui/legacy/core/guards/auth.guard';
import { BillingGuard } from '@ninety/ui/legacy/core/guards/billing.guard';
import { Company } from '@ninety/ui/legacy/shared/models/company/company';
import { NinetyFeatures } from '@ninety/ui/legacy/shared/models/company/company-pricing-tiers';
import { UserModelState } from '@ninety/ui/legacy/state/app-entities/users/users-state.model';
import { selectCurrentUser } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import {
  selectCompany,
  selectFeatureEnabled,
} from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';

import { CompanyMeetingAgendaComponent } from './company/company-meeting-agenda/company-meeting-agenda.component';
import { ConfigurationComponent } from './company/configuration/configuration.component';
import { OurLanguageComponent } from './company/our-language/our-language.component';
import { TeamsComponent } from './company/teams/teams.component';
import { ToolsHubComponent } from './company/tools-hub/tools-hub.component';
import { SettingsMenuItem } from './settings-menu-item.enum';
import { AccountComponent } from './user/account/account.component';
import { PreferencesComponent } from './user/preferences/preferences.component';
import { ProfileComponent } from './user/profile/profile.component';

// company settings
const TEAMS_PATH = 'company/teams';
const CONFIGURATION_PATH = 'company/configuration';
const OUR_LANGUAGE_PATH = 'company/our-language';
const COMPANY_ADDONS_PATH = 'company/add-ons';
const MEETINGS_PATH = 'company/meetings';

// user settings
const PROFILE_PATH = 'user/profile';
const ACCOUNT_PATH = 'user/account';
const PREFERENCES_PATH = 'user/preferences';
const NOTIFICATIONS_PATH = 'user/notifications';
const USER_INTEGRATIONS_PATH = 'user/integrations';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private allowedUserSettingsRoutesBs = new BehaviorSubject(this.getAllowedUserSettingsRoutes());
  allowedUserSettingsRoutes$ = this.allowedUserSettingsRoutesBs.asObservable();
  private currentUser: UserModelState = null;
  private company: Company = null;
  meetingsEnabled;

  constructor(private store: Store) {
    this.store
      .select(selectFeatureEnabled(NinetyFeatures.weeklyMeetings))
      .pipe(
        take(1),
        tap(enabled => {
          this.meetingsEnabled = enabled;
        })
      )
      .subscribe();
  }

  getInfoFromState(): void {
    // for some reason the get route functions are being invoked before the constructor. This is a workaround
    if (!this.currentUser) {
      this.store
        .select(selectCurrentUser)
        .pipe(
          take(1),
          tap(user => (this.currentUser = user))
        )
        .subscribe();
    }

    if (!this.company) {
      this.store
        .select(selectCompany)
        .pipe(
          take(1),
          tap(company => (this.company = company))
        )
        .subscribe();
    }
  }

  getAllowedCompanySettingsRoutes() {
    this.getInfoFromState();

    return getCompanySettingsRoutes().reduce((routes: Routes, r) => {
      switch (r.path) {
        case OUR_LANGUAGE_PATH:
          if (this.currentUser.isAdminOrOwner) routes.push(r);
          break;
        case MEETINGS_PATH:
          if (this.currentUser.isAdminOrOwner && this.meetingsEnabled) routes.push(r);
          break;
        case BILLING_V2_ROOT_PATH:
          if (this.canSeeBilling()) routes.push(r);
          break;
        case COMPANY_ADDONS_PATH:
          if (this.currentUser.isAdminOrOwner && this.company.settings.toolsHub) routes.push(r);
          break;
        case TEAMS_PATH:
          if (this.currentUser.isManagerOrAbove) routes.push(r);
          break;
        default:
          if (this.currentUser.isAdminOrOwner) routes.push(r);
      }
      return routes;
    }, []);
  }

  canSeeBilling(): boolean {
    return this.currentUser.isManagerOrAbove;
  }

  getAllowedUserSettingsRoutes() {
    this.getInfoFromState();

    return getUserSettingsRoutes().reduce((routes: Routes, r) => {
      switch (r.path) {
        case USER_INTEGRATIONS_PATH:
          if (this.currentUser.isManageeOrAbove) routes.push(r);
          break;
        case PREFERENCES_PATH:
          // Only one thing on the preferences component right now and lite users can't use it
          if (!this.currentUser.isLite) routes.push(r);
          break;
        case NOTIFICATIONS_PATH:
          //User can only see notifications if they are a managee or above
          if (this.currentUser.isManageeOrAbove) routes.push(r);
          break;
        default:
          routes.push(r);
          break;
      }
      return routes;
    }, []);
  }
}

export const getCompanySettingsRoutes = (): Routes => {
  const PARENT_TITLE = '| Company Settings';

  return [
    {
      path: CONFIGURATION_PATH,
      component: ConfigurationComponent,
      title: SettingsMenuItem.Configuration,
      data: {
        title: `Configuration ${PARENT_TITLE}`,
        name: SettingsMenuItem.Configuration,
        checkMinRole: 'admin',
        icon: 'tool',
        activeVariant: 'duotone',
      },
      canActivate: [AuthGuard],
    },
    {
      path: TEAMS_PATH,
      component: TeamsComponent,
      title: SettingsMenuItem.Teams,
      data: {
        title: `Teams ${PARENT_TITLE}`,
        name: SettingsMenuItem.Teams,
        checkMinRole: 'manager',
        icon: 'users-three',
        activeVariant: 'duotone',
      },
      canActivate: [AuthGuard],
    },
    {
      path: OUR_LANGUAGE_PATH,
      component: OurLanguageComponent,
      title: SettingsMenuItem.OurLanguage,
      data: {
        title: `Our Language ${PARENT_TITLE}`,
        name: SettingsMenuItem.OurLanguage,
        checkMinRole: 'admin',
        icon: 'text-aa',
        activeVariant: 'regular',
      },
      canActivate: [AuthGuard],
    },
    {
      path: MEETINGS_PATH,
      component: CompanyMeetingAgendaComponent,
      title: SettingsMenuItem.Meetings,
      data: {
        title: `Meetings ${PARENT_TITLE}`,
        name: SettingsMenuItem.Meetings,
        checkMinRole: 'admin',
        icon: 'meetings',
        activeVariant: 'duotone',
      },
      canActivate: [AuthGuard],
    },
    {
      path: BILLING_V2_ROOT_PATH,
      title: SettingsMenuItem.Billing,
      loadChildren: () => import('../billing/billingv2.module').then(m => m.BillingV2Module),
      data: {
        title: `Billing ${PARENT_TITLE}`,
        name: SettingsMenuItem.Billing,
        checkMinRole: 'manager',
        icon: 'credit-card',
        activeVariant: 'duotone',
      },
      canActivate: [AuthGuard, BillingGuard],
    },
    {
      path: COMPANY_ADDONS_PATH,
      component: ToolsHubComponent,
      title: SettingsMenuItem.AddOns,
      data: {
        title: `Add-Ons ${PARENT_TITLE}`,
        name: SettingsMenuItem.AddOns,
        checkMinRole: 'admin',
        icon: 'puzzle-piece',
        activeVariant: 'duotone',
      },
      canActivate: [AuthGuard],
    },
  ];
};

export const getUserSettingsRoutes = (): Routes => {
  const PARENT_TITLE = '| User Settings';

  return [
    {
      path: PROFILE_PATH,
      component: ProfileComponent,
      title: SettingsMenuItem.Profile,
      data: {
        title: `Profile ${PARENT_TITLE}`,
        name: SettingsMenuItem.Profile,
        icon: 'user-circle',
        activeVariant: 'duotone',
      },
    },
    {
      path: ACCOUNT_PATH,
      component: AccountComponent,
      title: SettingsMenuItem.Account,
      data: {
        title: `Account ${PARENT_TITLE}`,
        name: SettingsMenuItem.Account,
        icon: 'key',
        activeVariant: 'duotone',
      },
    },
    {
      path: PREFERENCES_PATH,
      component: PreferencesComponent,
      title: SettingsMenuItem.Preferences,
      data: {
        title: `Preferences ${PARENT_TITLE}`,
        name: SettingsMenuItem.Preferences,
        icon: 'settings',
        activeVariant: 'duotone',
      },
    },
    {
      path: NOTIFICATIONS_PATH,
      loadChildren: () => import('./user/notifications/notifications.module').then(m => m.NotificationsModule),
      title: SettingsMenuItem.Notifications,
      canActivate: [AuthGuard],
      data: {
        title: `Notifications ${PARENT_TITLE}`,
        name: SettingsMenuItem.Notifications,
        checkMinRole: 'managee', //Only managee and above can access this route, see role-code.ts for ref.
        icon: 'bell',
        activeVariant: 'duotone',
      },
    },
    {
      path: USER_INTEGRATIONS_PATH,
      loadChildren: () => import('./user/integration/integration.module').then(m => m.IntegrationModule),
      title: SettingsMenuItem.Integrations,
      canActivate: [AuthGuard],
      data: {
        title: `Integrations ${PARENT_TITLE}`,
        name: SettingsMenuItem.Integrations,
        checkMinRole: 'managee',
        icon: 'plug',
        activeVariant: 'duotone',
        featureName: NinetyFeatures.integrations,
      },
    },
  ];
};
