import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';

import { ButtonComponent } from '../../../../../buttons/button/button.component';
import { CardModule } from '../../../../../cards/card.module';
import { Plan } from '../../../compare-plans/compare-plans.component';

@Component({
  selector: 'ninety-pricing-card-essential',
  standalone: true,
  imports: [CommonModule, CardModule, TerraIconModule, ButtonComponent],
  templateUrl: './pricing-card-essential.component.html',
  styleUrls: ['./pricing-card-essential.component.scss'],
})
export class PricingCardEssentialComponent {
  plan: Plan = {
    name: 'Essential',
    price: 13,
    description:
      'Ideal for teams focused on tactical execution, the Essential plan equips you to run effective Weekly Team Meetings and stay aligned on your goals.',
    features: ['Issues', 'My 90', 'Rocks', 'Scorecard', 'To-Dos', 'Weekly Meetings'],
    buttonLabel: 'Current plan',
  };

  selectPlanBtnClick() {
    console.log('clicked');
  }
}
