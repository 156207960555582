<ng-container *ngIf="useTerra; else legacySelect">
  <ng-container *ngIf="itemType >= 0">
    <terra-select
      data-cy="item-type-select_type-selector"
      #itemTypeSelect
      class="item-type-select"
      [ngModel]="itemType"
      [disabled]="disabled"
      (selectionChange)="onSelection(itemTypeSelect.value)">
      <terra-select-trigger
        class="item-type-select__custom-trigger"
        [class.item-type-select__custom-trigger--disabled]="disabled">
        <span class="item-type-select__custom-trigger-label">
          {{ itemType | itemLabelByType }}
        </span>

        <terra-icon *ngIf="!disabled" icon="caret-down" variant="bold" />
      </terra-select-trigger>
      <terra-option data-cy="item-type-select_rock-option" [value]="itemTypeEnum.rock">{{
        stateService.language.rock.item
      }}</terra-option>
      <terra-option data-cy="item-type-select_todo-option" [value]="itemTypeEnum.todo">{{
        stateService.language.todo.item
      }}</terra-option>
      <terra-option
        data-cy="item-type-select_issue-option"
        [value]="itemTypeEnum.issue"
        *ngIf="!stateService.isLiteUser"
        >{{ stateService.language.issue.item }}</terra-option
      >
      <terra-option
        data-cy="item-type-select_headline-option"
        [value]="itemTypeEnum.headline"
        *ngIf="!stateService.isLiteUser"
        >{{ stateService.language.headline.item }}</terra-option
      >
      <ng-container *ngIf="!stateService.isLiteUser || meetingService.currentMeeting?.inProgress || showCascadedOption">
        <terra-option
          data-cy="item-type-select_cascaded-message-option"
          [value]="itemTypeEnum.cascadedMessage"
          *ngIf="cascadingMessagesFeature$ | ngrxPush; else NoCascadingMessageFeatureTerra">
          {{ stateService.language.cascadingMessage.item }}
        </terra-option>

        <ng-template #NoCascadingMessageFeatureTerra>
          <terra-option
            data-cy="item-type-select_cascaded-message-option"
            [value]="itemTypeEnum.cascadedMessage"
            disabled
            [ninetyCmPopoverTrigger]="toTrigger"
            [ninetyCmPopoverEnabled]="true">
            <terra-icon *terraOptionPrefix icon="lock" size="20" />
            {{ stateService.language.cascadingMessage.item }}

            <ninety-cm-popover #toTrigger>
              <div headline>Get more done with Ninety.</div>
              <div description>
                Upgrade your plan to access enhanced functionality to help you focus, align and thrive in your business.
              </div>
              <button ninety-cm-button>
                <terra-icon icon="lightning" variant="fill" size="40" />
                Explore plans
              </button>
            </ninety-cm-popover>
          </terra-option>
        </ng-template>
      </ng-container>
    </terra-select>
  </ng-container>
</ng-container>
<ng-template #legacySelect>
  <ng-container *ngIf="itemType >= 0">
    <!-- appearance="none" is not a valid value for appearance but it removes underline
		without any CSS hacks, need to cast as any for typescript to not complain -->
    <mat-form-field *ngIf="!disabled; else readonly" [appearance]="$any('none')">
      <mat-select
        data-cy="item-type-select_type-selector"
        #itemTypeSelect
        class="item-type-select"
        [value]="itemType"
        [disabled]="disabled"
        (selectionChange)="onSelection(itemTypeSelect.value)">
        <mat-option
          data-cy="item-type-select_issue-option"
          [value]="itemTypeEnum.issue"
          *ngIf="!stateService.isLiteUser"
          >{{ stateService.language.issue.item }}</mat-option
        >
        <mat-option data-cy="item-type-select_todo-option" [value]="itemTypeEnum.todo">{{
          stateService.language.todo.item
        }}</mat-option>
        <mat-option data-cy="item-type-select_rock-option" [value]="itemTypeEnum.rock">{{
          stateService.language.rock.item
        }}</mat-option>
        <mat-option
          data-cy="item-type-select_headline-option"
          [value]="itemTypeEnum.headline"
          *ngIf="!stateService.isLiteUser"
          >{{ stateService.language.headline.item }}</mat-option
        >

        <mat-option
          data-cy="item-type-select_cascaded-message-option"
          [value]="itemTypeEnum.cascadedMessage"
          [disabled]="!(cascadingMessagesFeature$ | ngrxPush)"
          *ngIf="!stateService.isLiteUser || meetingService.currentMeeting?.inProgress || showCascadedOption">
          <ng-container *ngIf="cascadingMessagesFeature$ | ngrxPush; else NoCascadingMessageFeature">
            <div class="cascading-message-option">
              {{ stateService.language.cascadingMessage.item }}
            </div>
          </ng-container>
          <ng-template #NoCascadingMessageFeature>
            <div class="cascading-message-option" [ninetyCmPopoverTrigger]="toTrigger" [ninetyCmPopoverEnabled]="true">
              <terra-icon icon="lock" size="20" />
              <span>{{ stateService.language.cascadingMessage.item }}</span>
            </div>
            <ninety-cm-popover #toTrigger>
              <div headline>Get more done with Ninety.</div>
              <div description>
                Upgrade your plan to access enhanced functionality to help you focus, align and thrive in your business.
              </div>
              <button ninety-cm-button>
                <terra-icon icon="lightning" variant="fill" size="40" />
                Explore plans
              </button>
            </ninety-cm-popover>
          </ng-template>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-template #readonly>
      <div class="item-type readonly">{{ itemType | itemLabelByType }}</div>
    </ng-template>
  </ng-container>
</ng-template>
