<ng-container *ngIf="hasAccess$ | async; else noAccess">
  <ng-container
    *ngrxLet="{
    shownConfirmDialog: shownConfirmDialog$,
    templateOptions: templateOptions$,
    showTeamFilter: showTeamFilter$,
    teamOptions: teamOptions$,
    selected: selectedImportType$,
    showOneSchemaIframe: showOneSchemaIframe$,
    showPreviewTable: showPreviewTable$,
  } as data">
    <ninety-alert-message [hasIcon]="true" class="screen-too-small-alert" type="neutral">
      To use the data import feature, please visit us on a bigger screen.
    </ninety-alert-message>

    <ninety-alert-message
      *ngIf="!data.shownConfirmDialog && data.selected.importType === DataImportItemType.user"
      [hasIcon]="true"
      class="not-confirmed-alert"
      type="neutral">
      To use the data import feature please
      <a (click)="openConsentDialog()" class="open-confirm-dialog">open confirm dialog</a> or refresh the page.
    </ninety-alert-message>

    <ng-container>
      <ninety-action-bar *ngIf="data.shownConfirmDialog && data?.templateOptions">
        <div class="import-selects" left>
          <ninety-quick-filter
            (selectionChange)="selectTemplate(asDataImportType($event))"
            [disabled]="data.templateOptions?.length <= 1"
            [options]="data.templateOptions"
            [pillIcon]="data.selected.icon"
            pillTitle="Import"></ninety-quick-filter>

          <ninety-quick-filter
            (selectionChange)="selectTeam(asTeam($event))"
            *ngIf="data.showTeamFilter"
            [options]="data.teamOptions"
            pillTitle="Team"></ninety-quick-filter>
        </div>

        <div class="action-buttons">
          <ninety-button
            (click)="destroy()"
            [routerLink]="data.selected.routerLink"
            primary
            stroked
            data-product="data-import__go-to-corresponding-page">
            <terra-icon [icon]="data.selected.icon" size="20" />
            Go to {{ data.selected.correspondingPage }}
          </ninety-button>

          <ninety-button (click)="downloadCSVTemplate()" filled primary data-product="data-import__download-template">
            <terra-icon icon="download" size="20" />
            Download template
          </ninety-button>
        </div>
      </ninety-action-bar>

      <div class="import-content">
        <ng-container *ngIf="data.showOneSchemaIframe">
          <div *ngIf="data.selected.importType === DataImportItemType.user" class="info-cards">
            <ninety-data-import-user-step-one class="flex1" />
            <ninety-data-import-user-step-two class="flex1" />
          </div>

          <ninety-one-schema-import />
        </ng-container>

        <ng-container *ngIf="data.showPreviewTable">
          <ninety-data-import-user-step-three *ngIf="data.selected.importType === DataImportItemType.user" />
          <ninety-user-import-card />
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #noAccess>
  <ninety-cm-tiered-page>
    <div headline>You don’t have access to Data Import on your current plan.</div>
    <div description>Upgrade your plan to start using this tool.</div>
    <button ninety-cm-button>
      <terra-icon icon="lightning" variant="fill" />
      Explore Plans
    </button>
  </ninety-cm-tiered-page>
</ng-template>
