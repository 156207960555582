import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MazFeedbackFlag } from '../../models/maz-feedback-flag.enum';

@Component({
  selector: 'ninety-maz-chat-toaster',
  template: `
    <terra-icon icon="sparkle" variant="fill" size="20" />

    <span class="maz-alert-message-text">{{ message }}</span>

    <ninety-button filled positive icon class="maz-alert-message-close" (click)="hideMazAlert()">
      <terra-icon icon="x" size="20" />
    </ninety-button>
  `,
  styles: [
    `
      @use 'terra';

      :host {
        @include terra.radius(small);
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        background-color: terra.color('green');
        color: terra.color('white');
        padding: 6px 16px;
      }

      .maz-alert-message-text {
        flex: 1;
      }

      .maz-alert-message-close {
        color: terra.color('text-invert', 'light');
      }
    `,
  ],
})
export class MazChatToasterComponent implements OnInit {
  @Output() hide = new EventEmitter<void>();
  @Input() flag: MazFeedbackFlag;

  timeToHide = 8_000;
  message: string;

  ngOnInit(): void {
    this.message =
      this.flag === MazFeedbackFlag.correct ? 'We’ve removed the incorrect flag.' : 'Thanks for helping Maz evolve!';
    setTimeout(() => this.hideMazAlert(), this.timeToHide);
  }

  hideMazAlert(): void {
    this.hide.emit();
  }
}
