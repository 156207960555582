<ng-container
  *ngrxLet="{
    actionMenuActive: IsActionButtonActive$,
    canRemoveUser: canRemoveUser$,
    canDeactivateUser: canDeactivateUser$,
    showCopyInviteLink: shouldShowCopyInviteLink$,
    menuWeShow: menuType$,
  } as vm">
  <ninety-button
    [disabled]="!vm.actionMenuActive"
    [matMenuTriggerFor]="moreActions"
    [ninetyTooltip]="vm.actionMenuActive ? '' : 'Managers cannot take actions on this role'"
    compact
    icon>
    <terra-icon icon="more" />
  </ninety-button>

  <mat-menu #moreActions="matMenu" class="template-options-menu-directory-action" xPosition="before">
    <ng-container *ngIf="vm.menuWeShow === 'ActiveUser'" class="active-user-wrapper">
      <div class="active-user-menu">
        <ninety-button (click)="openDeactivateUserDialog()" *ngIf="vm.canDeactivateUser" mat-menu-item>
          <terra-icon class="text-light" icon="minus-circle" size="20" />
          <span class="terra-body">Deactivate <span class="text-light">(remove Ninety access)</span></span>
        </ninety-button>
        <ninety-button *ngIf="vm.canRemoveUser" (click)="openRemovePersonDialog()" class="color-red" mat-menu-item>
          <terra-icon icon="delete" size="20" />
          <span class="terra-body">Delete user</span>
        </ninety-button>
      </div>
    </ng-container>

    <ng-container *ngIf="vm.menuWeShow === 'PendingUser'">
      <div class="pending-user-menu">
        <ninety-button
          #copyInviteLinkBtnOnView
          (click)="copyInviteLink(copyInviteLinkBtnOnView)"
          *ngIf="vm.showCopyInviteLink"
          mat-menu-item>
          <terra-icon class="text-light" icon="link" size="20" />
          <span class="terra-body">Copy unique invite link</span>
        </ninety-button>
        <ninety-button (click)="openDeactivateUserDialog()" *ngIf="vm.canDeactivateUser" mat-menu-item>
          <terra-icon class="text-light" icon="minus-circle" size="20" />
          <span class="terra-body">Deactivate </span><span class="text-light">(remove Ninety access)</span>
        </ninety-button>
        <ninety-button *ngIf="vm.canRemoveUser" (click)="openRemovePersonDialog()" class="color-red" mat-menu-item>
          <terra-icon icon="delete" size="20" />
          <span class="terra-body">Delete user</span>
        </ninety-button>
      </div>
    </ng-container>

    <ng-container *ngIf="vm.menuWeShow === 'DirectoryOnlyPerson'">
      <div class="directory-only-person-menu">
        <ninety-button *ngIf="vm.canRemoveUser" (click)="openRemovePersonDialog()" class="color-red" mat-menu-item>
          <terra-icon icon="delete" size="20" />
          <span class="terra-body">Delete person</span>
        </ninety-button>
      </div>
    </ng-container>
  </mat-menu>
</ng-container>
