import { Directive, inject, Injector, OnDestroy, OnInit } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
  UntypedFormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';

/**
 * A reusable directive that allows a parent component to forward its Form Control to a child component.
 * Form Control Forwarding can be useful for custom inputs that act as a wrapper for another input
 * that would need to be able to access and modify the Form Control directly.
 */
@Directive({
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: HostFormControlPassthroughDirective,
    },
  ],
})
export class HostFormControlPassthroughDirective implements OnInit, OnDestroy, ControlValueAccessor {
  control!: FormControl;

  private injector = inject(Injector);

  private subscription?: Subscription;

  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl, null, {
      self: true,
      optional: true,
    });

    if (ngControl instanceof FormControlName) {
      const group = this.injector.get(ControlContainer).control as UntypedFormGroup;
      this.control = group.controls[ngControl.name!] as FormControl;
      return;
    }

    if (ngControl instanceof FormControlDirective) {
      this.control = ngControl.control;
      return;
    }

    if (ngControl instanceof NgModel) {
      const control: NgModel = ngControl;
      this.subscription = ngControl.control.valueChanges.subscribe(newValue => {
        // The viewToModelUpdate updates the directive and triggers the ngModelChange.
        // So we want to called it when the value changes except when it comes from the parent (ngModel input).
        // The `if` checks if the newValue is different from the value on the ngModel input or from the current value.
        if (control.model !== newValue || control.viewModel !== newValue) {
          ngControl.viewToModelUpdate(newValue);
        }
      });
      this.control = ngControl.control;
      return;
    }

    throw new Error('Terra component requires a form control (reactive/template) to be used');
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  writeValue(): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnChange(): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(): void {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
