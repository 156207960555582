<ninety-card>
  <div class="header">
    <div class="header-actions">
      <ninety-button flat icon (click)="cancel()">
        <terra-icon icon="x" size="20" />
      </ninety-button>
    </div>
    <div class="headline">Headline Here!</div>
    <div class="headline-description">
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
      aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
    </div>
  </div>

  <ninety-pricing-cards class="ninety-pricing-cards" [currentPricingTier]="data.currentPricingTier" />

  <ninety-all-in-discount />

  <ninety-compare-plans />
</ninety-card>
