<ng-container *ngIf="locked; else NotLocked">
  <ninety-button
    filled
    primary
    (click)="openUniversalCreateModal.emit()"
    data-cy="top-toolbar_create-button"
    [disabled]="true"
    [ninetyCmPopoverTrigger]="toTrigger"
    [ninetyCmPopoverEnabled]="true">
    <terra-icon icon="lock" size="20" />
    Create
  </ninety-button>

  <ninety-cm-popover #toTrigger>
    <div headline>Turn Ideas into Action</div>
    <div description *ngrxLet="language$ as language">
      Turn plans into progress by unlocking the ability to create {{ language.issue.items }},
      {{ language.todo.items }} and {{ language.rock.items }}. Drive accountability, solve challenges, and keep everyone
      on track.
    </div>
    <button ninety-cm-button>
      <terra-icon icon="lightning" variant="fill" size="40" />
      Explore Paid Plans
    </button>
  </ninety-cm-popover>
</ng-container>

<ng-template #NotLocked>
  <ninety-button
    filled
    primary
    (click)="openUniversalCreateModal.emit()"
    data-cy="top-toolbar_create-button"
    [disabled]="disabled">
    Create
  </ninety-button>
</ng-template>
