import { Directive, ElementRef, Input } from '@angular/core';

import { NinetyCmPopoverComponent } from './cm-popover.component';

@Directive({
  selector: '[ninetyCmPopoverTrigger]',
  exportAs: 'ninetyCmPopoverTrigger',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(mouseenter)': '_mouseEnter()',
    '(mouseleave)': '_mouseLeave()',
  },
})
export class NinetyCmPopoverTriggerDirective {
  /**
   * The popover component that this trigger is connected to
   */
  @Input({ required: true }) ninetyCmPopoverTrigger: NinetyCmPopoverComponent;

  /**
   * Whether the popover should be enabled when trigger is hovered
   */
  @Input() ninetyCmPopoverEnabled = false;

  constructor(private _elementRef: ElementRef) {}

  private _mouseEnter(): void {
    if (this.ninetyCmPopoverEnabled) {
      this.ninetyCmPopoverTrigger.showPopover(this._elementRef);
    }
  }

  private _mouseLeave(): void {
    // Need setTimeout to prevent the popover from closing when the user moves the mouse from the trigger to the popover
    setTimeout(() => {
      this.ninetyCmPopoverTrigger.hidePopover();
    });
  }
}
