import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';

import { ButtonComponent } from '../../../../../buttons/button/button.component';
import { CardModule } from '../../../../../cards/card.module';
import { Plan } from '../../../compare-plans/compare-plans.component';

@Component({
  selector: 'ninety-pricing-card-thrive',
  standalone: true,
  imports: [CommonModule, CardModule, TerraIconModule, ButtonComponent],
  templateUrl: './pricing-card-thrive.component.html',
  styleUrls: ['./pricing-card-thrive.component.scss'],
})
export class PricingCardThriveComponent {
  plan: Plan = {
    name: 'Thrive',
    description:
      'For ambitious organizations, the Thrive plan leverages advanced tools and AI-powered capabilities to align teams, accelerate growth, and drive unparalleled performance.',
    price: 17,
    buttonLabel: 'Choose Thrive',
    features: [
      'Benchmarking',
      'Board meetings',
      'Custom assessments',
      'General purpose APIs',
      'KST: Authoring',
      'Project views',
    ],
  };

  selectPlanBtnClick() {}
}
