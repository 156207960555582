import { coerceNumberProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TerraIconModule } from '../../terra-icon';
import { TerraInputBaseClass } from '../terra-input-base/terra-input-base';
import { TerraTextInputModule } from '../terra-text-input';

@Component({
  selector: 'terra-search-input',
  standalone: true,
  exportAs: 'terraSearchInput',
  imports: [CommonModule, TerraTextInputModule, TerraIconModule, FormsModule],
  templateUrl: './terra-search-input.component.html',
  styleUrls: ['../terra-input-base/terra-input-base.scss', './terra-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerraSearchInputComponent extends TerraInputBaseClass {
  /**
   * Emits when the clear button is clicked
   */
  @Output() cleared: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Set the maxlength of characters allowed in the input
   */
  @Input() get maxlength(): string | number | undefined {
    return this._maxlength;
  }
  set maxlength(value: string | number | undefined) {
    this._maxlength = coerceNumberProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _maxlength?: number | undefined;

  protected _clearInput(): void {
    this._onModelChange('');
    this.cleared.emit();
  }
}
