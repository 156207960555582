import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AgendaV2Component } from '@ninety/meeting/agenda-v2/agenda-v2.component';
import { AgendasV2Component } from '@ninety/meeting/agendas-v2/agendas-v2.component';
import { MeetingRoutes } from '@ninety/ui/legacy/shared/models/meetings/meeting-routes';

import { MeetingsHeaderComponent } from './meetings-header/meetings-header.component';
import { UpcomingMeetingsPageComponent } from './meetings-page/upcoming-meetings-page.component';
import { PastMeetingsPageComponent } from './past-meetings/past-meetings-page/past-meetings-page.component';

const routes: Routes = [
  {
    path: '',
    component: MeetingsHeaderComponent,
    children: [
      {
        path: '',
        component: UpcomingMeetingsPageComponent,
        pathMatch: 'full',
      },
      {
        path: MeetingRoutes.upcoming,
        component: UpcomingMeetingsPageComponent,
      },
      {
        path: `${MeetingRoutes.agendas}/:id`,
        component: AgendaV2Component,
      },
      {
        path: MeetingRoutes.agendas,
        component: AgendasV2Component,
      },

      {
        path: MeetingRoutes.past,
        component: PastMeetingsPageComponent,
      },
    ],
  },
  { path: '', redirectTo: MeetingRoutes.home, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MeetingV2RoutingModule {}
