<ng-container *ngIf="disabled; else rockTypeSelector">
  <div
    *ngIf="isSelected"
    [class]="rockType.toLowerCase()"
    class="selector selector-disabled"
    [ngClass]="{
      'company-selected': rockType === 'Company' && isSelected,
      'departmental-selected': rockType !== 'Company' && isSelected
    }">
    <terra-icon [icon]="rockType === 'Company' ? 'users-three' : 'users'" variant="fill" size="24" />
    <div class="selector-disabled__descriptors">
      {{ rockType }} {{ rockLanguage }}
      <span *ngIf="selectedName">| {{ selectedName }}</span>
    </div>
  </div>
</ng-container>

<ng-template #rockTypeSelector>
  <div
    class="selector selector-card"
    [class]="rockType.toLowerCase()"
    [ngClass]="{
      'company-selected': rockType === 'Company' && isSelected,
      'departmental-selected': rockType !== 'Company' && isSelected,
      zero: !isSelected
    }"
    (click)="onSelectRockType()">
    <terra-icon
      [icon]="isSelected ? 'check-circle' : 'circle'"
      [variant]="isSelected ? 'fill' : 'regular'"
      [ngClass]="{ zero: !isSelected }"
      size="32" />
    <div class="selector-card__descriptors">
      <div class="selector-card__title">{{ rockType }} {{ rockLanguage }}</div>
      <span class="selector-card__description">
        A {{ rockType }} {{ rockLanguage }} is a {{ rockLanguage }} shared with the entire team.
      </span>
    </div>
  </div>
</ng-template>
