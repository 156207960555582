import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PricingTier } from '../../../../_shared/models/company/company-pricing-tiers';

import { PricingCardEssentialComponent } from './components/pricing-card-essential/pricing-card-essential.component';
import { PricingCardFreeComponent } from './components/pricing-card-free/pricing-card-free.component';
import { PricingCardScaleComponent } from './components/pricing-card-scale/pricing-card-scale.component';
import { PricingCardThriveComponent } from './components/pricing-card-thrive/pricing-card-thrive.component';

@Component({
  selector: 'ninety-pricing-cards',
  standalone: true,
  imports: [
    CommonModule,
    PricingCardFreeComponent,
    PricingCardEssentialComponent,
    PricingCardScaleComponent,
    PricingCardThriveComponent,
  ],
  templateUrl: './pricing-cards.component.html',
  styleUrls: ['./pricing-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingCardsComponent {
  @Input() currentPricingTier: PricingTier;
}
