<div
  *ngrxLet="{
    language: language$,
    selectedTeam: selectedTeam$,
    teams: teams$,
    agendas: agendas$,
    weeklyMeetingsOnlyFeature: weeklyMeetingsOnlyFeature$
  } as vm">
  <div class="card-wrp" *ngIf="vm.selectedTeam">
    <ninety-card class="agendas-v2-card">
      <ninety-card-header header>
        <ninety-card-title-with-count title="Agendas" [count]="vm.agendas.length"></ninety-card-title-with-count>
        <span class="description">Manage meeting agendas for your team.</span>
      </ninety-card-header>

      <table mat-table [dataSource]="vm.agendas" class="agendas-v2">
        <ng-container matColumnDef="frontSpacer">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef class="front-spacer"></td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Title</th>
          <td mat-cell *matCellDef="let agenda">
            <div class="title-wrp">
              <terra-icon [icon]="agenda.type === 'Custom' ? 'lightning' : 'calendar'" size="32" />
              <ng-container *ngIf="vm.weeklyMeetingsOnlyFeature; else defaultAgenda">
                <span>{{ agenda.title }}</span>
                <terra-icon
                  *ngIf="agenda.id !== MeetingType.weekly"
                  class="cm-lock-icon"
                  icon="lock"
                  [ninetyCmPopoverTrigger]="upgradePopover"
                  [ninetyCmPopoverEnabled]="true" />

                <ninety-cm-popover #upgradePopover>
                  <div headline>Run {{ vm.language.meeting.items }} To Help Scale Your Business</div>
                  <div description>
                    Unlock {{ vm.language.meeting.quarterly }}, {{ vm.language.meeting.annual }}, and Implementation
                    {{ vm.language.meeting.item }} agendas to scale your business and turn your vision into reality. Set
                    the right focus, drive productivity, and ensure you’re strategically planning to achieve your goals.
                  </div>
                  <button ninety-cm-button>
                    <terra-icon icon="lightning" variant="fill" size="40" />
                    Explore Scale Plan
                  </button>
                </ninety-cm-popover>
              </ng-container>
              <ng-template #defaultAgenda>
                <span>{{ agenda.title }}</span>
              </ng-template>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let agenda">
            {{ agenda.type }}
          </td>
        </ng-container>

        <ng-container matColumnDef="endSpacer">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef class="end-spacer"></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          id="agenda-row-{{ row.id }}"
          [ngClass]="{ 'disabled-row': vm.weeklyMeetingsOnlyFeature && row.id !== MeetingType.weekly }"
          (click)="!vm.weeklyMeetingsOnlyFeature || row.id === MeetingType.weekly ? onSelected(row) : null"></tr>
      </table>
      <ninety-card-footer
        footer
        class="full-width flex-justify-space-between flex-wrap"
        *ngrxLet="language$ as language">
        <ninety-button
          *ngIf="vm.weeklyMeetingsOnlyFeature; else createAgendaButton"
          filled
          primary
          data-cy="top-toolbar_create-button"
          [disabled]="true"
          [ninetyCmPopoverTrigger]="toTrigger"
          [ninetyCmPopoverEnabled]="true">
          <terra-icon icon="lock" size="20" />
          Create
        </ninety-button>

        <ninety-cm-popover #toTrigger>
          <div headline>Run {{ language.meeting.items }} That Fit Your Business</div>
          <div description>
            Unlock custom agendas to shape {{ language.meeting.items | lowercase }} around your unique needs. Set the
            right focus, drive productivity, and make every {{ language.meeting.item | lowercase }} count.
          </div>
          <button ninety-cm-button>
            <terra-icon icon="lightning" variant="fill" size="40" />
            Explore Scale Plan
          </button>
        </ninety-cm-popover>

        <ng-template #createAgendaButton>
          <ninety-add-item-button
            class="create-agenda-btn"
            data-cy="create-agenda-btn"
            label="Create Agenda"
            (clicked)="createAgenda()"></ninety-add-item-button>
        </ng-template>
      </ninety-card-footer>
    </ninety-card>
  </div>
</div>
