<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="envelope" />
      <span data-cy="cascading-message-language_card-title">{{ cascadingMessage.items }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="cascadingMessage">
      <terra-form-field>
        <terra-form-label>{{ cascadingMessage.item }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="item"
          required
          [attr.aria-label]="cascadingMessage.item"
          data-cy="cascading-message-language_singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('item') !== resetLanguage?.item">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('item')"
              data-cy="cascading-message-language_singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>

      <terra-form-field>
        <terra-form-label>{{ cascadingMessage.items }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="items"
          required
          [attr.aria-label]="cascadingMessage.items"
          data-cy="cascading-message-language_plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('items') !== resetLanguage?.items">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('items')"
              data-cy="cascading-message-language_plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>

      <terra-form-field>
        <terra-form-label>{{ cascadingMessage.action }} (action)</terra-form-label>
        <terra-text-input
          formControlName="action"
          required
          [attr.aria-label]="cascadingMessage.action"
          data-cy="cascading-message-language_action-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('action') !== resetLanguage?.action">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('action')"
              data-cy="cascading-message-language_action-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>

      <terra-form-field>
        <terra-form-label>{{ cascadingMessage.actionPast }} (action - past tense)</terra-form-label>
        <terra-text-input
          formControlName="actionPast"
          required
          [attr.aria-label]="cascadingMessage.actionPast"
          data-cy="cascading-message-language_action-past-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('actionPast') !== resetLanguage?.actionPast">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('actionPast')"
              data-cy="cascading-message-language_action-past-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
    </div>
  </mat-card-content>
</mat-card>
