<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="directory" />
      <span data-cy="directory-language_card-title">{{ directory.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="directory">
      <terra-form-field>
        <terra-form-label>Page Name</terra-form-label>
        <terra-text-input
          formControlName="route"
          required
          [attr.aria-label]="directory.route + ' Page Name'"
          data-cy="directory-language_page-name-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('route') !== resetLanguage?.route">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('route')"
              data-cy="directory-language_page-name-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ directory.owner }}</terra-form-label>
        <terra-text-input
          formControlName="owner"
          required
          [attr.aria-label]="directory.owner"
          data-cy="directory-language_owner-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('owner') !== resetLanguage?.owner">
            <terra-icon icon="reset" (click)="resetOne.emit('owner')" data-cy="directory-language_owner-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ directory.admin }}</terra-form-label>
        <terra-text-input
          formControlName="admin"
          required
          [attr.aria-label]="directory.admin"
          data-cy="directory-language_admin-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('admin') !== resetLanguage?.admin">
            <terra-icon icon="reset" (click)="resetOne.emit('admin')" data-cy="directory-language_admin-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ directory.manager }}</terra-form-label>
        <terra-text-input
          formControlName="manager"
          required
          [attr.aria-label]="directory.manager"
          data-cy="directory-language_manager-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('manager') !== resetLanguage?.manager">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('manager')"
              data-cy="directory-language_manager-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ directory.managee }}</terra-form-label>
        <terra-text-input
          formControlName="managee"
          required
          [attr.aria-label]="directory.managee"
          data-cy="directory-language_team-member-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('managee') !== resetLanguage?.managee">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('managee')"
              data-cy="directory-language_team-member-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ directory.observer }}</terra-form-label>
        <terra-text-input
          formControlName="observer"
          required
          [attr.aria-label]="directory.observer"
          data-cy="directory-language_observer-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('observer') !== resetLanguage?.observer">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('observer')"
              data-cy="directory-language_observer-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ directory.implementer }}</terra-form-label>
        <terra-text-input
          formControlName="implementer"
          required
          [attr.aria-label]="directory.implementer"
          data-cy="directory-language_coach-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('implementer') !== resetLanguage?.implementer">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('implementer')"
              data-cy="directory-language_coach-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
    </div>
  </mat-card-content>
</mat-card>
