import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { TodoLanguage } from '../../../../models/language/custom-language';

@Component({
  selector: 'ninety-todo-language',
  templateUrl: './todo-language.component.html',
  styles: [],
})
export class TodoLanguageComponent {
  @Input() todo: TodoLanguage;
  @Input() resetLanguage: TodoLanguage;
  @Input() readonly: boolean;
  @Input() form: FormGroup;

  @Output() resetOne = new EventEmitter<keyof TodoLanguage>();

  getFormControlValue(formControlName: string): string {
    return this.form.get('todo').value[formControlName];
  }
}
