import { Highlightable } from '@angular/cdk/a11y';
import { EventEmitter, InjectionToken } from '@angular/core';

/**
 * Base interface that both TerraOption and TerraIconOption implement
 * This is so that the TerraSelect can generically handle both types of options
 */
export interface TerraOptionBase extends Highlightable {
  optionId: string;
  selected: boolean;
  _onClick: ($event: Event) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  select: EventEmitter<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content?: EventEmitter<any>;
  getLabel: () => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  checkbox?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _prefixTemplate?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _suffixTemplate?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _iconTemplate?: any;
  disabledOverride: boolean;
}

export const TERRA_OPTION_BASE = new InjectionToken<TerraOptionBase>('TerraOptionBase');
