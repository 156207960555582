<ninety-card>
  <div class="card-header">
    <div class="plan-name">{{ plan.name }}</div>
    <div class="plan-description">{{ plan.description }}</div>
  </div>
  <div class="card-price">
    <span class="plan-price">${{ plan.price }}</span>
    <span class="plan-user-per-month">per user per month</span>
  </div>

  <ninety-button class="card-button" filled primary (click)="selectPlanBtnClick()">
    <span class="button-text">
      {{ plan.buttonLabel }}
    </span>
  </ninety-button>

  <div class="card-features">
    <div class="everything-bold-text">Everything in Scale plus:</div>
    <ul>
      <li class="feature-list-item" *ngFor="let feature of plan.features">
        <terra-icon class="checkmark" icon="check" variant="bold" size="16" />
        <span class="feature-name">{{ feature }}</span>
      </li>
    </ul>
  </div>
</ninety-card>
