import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { FilterBarActions } from '@ninety/layouts/_state/filterbar/filterbar-state.actions';
import { TerraIconModule } from '@ninety/terra';
import { NinetyCMModule } from '@ninety/ui/legacy/components/commercial-model/cm.module';

/**
 * This is just a simple default tiered page.
 * Mainly to help demonstrate how the 'hasFeatureAccessGuard' route guard  works.
 * This is mainly used as a catch-all for tiered pages that have not been implemented yet.
 * */
@Component({
  selector: 'ninety-default-tiered-page',
  standalone: true,
  imports: [CommonModule, TerraIconModule, NinetyCMModule],
  template: `<ninety-cm-tiered-page>
    <div headline>You don’t have access to this tool on your current plan.</div>
    <div description>Upgrade your plan to start using this tool.</div>
    <button ninety-cm-button>
      <terra-icon icon="lightning" variant="fill" />
      Explore Plans
    </button>
  </ninety-cm-tiered-page>`,
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultTieredPageComponent {
  constructor(private readonly store: Store) {
    this.store.dispatch(FilterBarActions.hide());
  }
}
