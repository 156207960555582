import { createFeatureSelector, createSelector } from '@ngrx/store';

import { feedbackFeatureKey, FeedbackState } from '@ninety/feedback/_state/feedback-state';
import { FeedbackPageStateKey, initialFeedbackRootState } from '@ninety/feedback/_state/page/feedback-page.model';
import { MockCustomLanguage } from '@ninety/ui/legacy/shared/index';

export const selectFeedbackState = createFeatureSelector<FeedbackState>(feedbackFeatureKey);

export const selectFeedbackPageState = createSelector(selectFeedbackState, state =>
  state ? state[FeedbackPageStateKey] : initialFeedbackRootState
);

export const selectIsWaiting = createSelector(selectFeedbackPageState, state => state.isWaiting);

export const selectCompanyLanguage = createSelector(selectFeedbackPageState, state => state?.language);

export const selectFeedbackLanguage = createSelector(selectFeedbackPageState, state => state?.language?.feedback);
