<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="surveys" class="survey-icon" />
      <span data-cy="survey-language_card-title">{{ survey.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="survey">
      <terra-form-field>
        <terra-form-label>Page Name</terra-form-label>
        <terra-text-input
          formControlName="route"
          required
          [attr.aria-label]="survey.route + ' Page Name'"
          data-cy="survey-language_page-name-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('route') !== resetLanguage?.route">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('route')"
              data-cy="survey-language_page-name-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ survey.item }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="item"
          required
          [attr.aria-label]="survey.item"
          data-cy="survey-language_singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('item') !== resetLanguage?.item">
            <terra-icon icon="reset" (click)="resetOne.emit('item')" data-cy="survey-language_singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ survey.items }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="items"
          required
          [attr.aria-label]="survey.items"
          data-cy="survey-language_plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('items') !== resetLanguage?.items">
            <terra-icon icon="reset" (click)="resetOne.emit('items')" data-cy="survey-language_plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>

      <terra-form-field>
        <terra-form-label>{{ survey.admin }}</terra-form-label>
        <terra-text-input
          formControlName="admin"
          required
          [attr.aria-label]="survey.admin"
          data-cy="survey-language_admin-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('admin') !== resetLanguage?.admin">
            <terra-icon icon="reset" (click)="resetOne.emit('admin')" data-cy="survey-language_admin-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ survey.mySurveys }}</terra-form-label>
        <terra-text-input
          formControlName="mySurveys"
          required
          [attr.aria-label]="survey.mySurveys"
          data-cy="survey-language_my-surveys-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('mySurveys') !== resetLanguage?.mySurveys">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('mySurveys')"
              data-cy="survey-language_my-surveys-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>

      <terra-form-field>
        <terra-form-label>{{ survey.recipient }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="recipient"
          required
          [attr.aria-label]="survey.recipient"
          data-cy="survey-language_recipient-singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('recipient') !== resetLanguage?.recipient">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('recipient')"
              data-cy="survey-language_recipient-singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ survey.recipients }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="recipients"
          required
          [attr.aria-label]="survey.recipients"
          data-cy="survey-language_recipient-plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('recipients') !== resetLanguage?.recipients">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('recipients')"
              data-cy="survey-language_recipient-plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
    </div>
  </mat-card-content>
</mat-card>
