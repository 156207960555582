export enum RockSort {
  title = 'title',
  statusCode = 'statusCode',
  dueDate = 'dueDate',
  owner = 'owner',
  team = 'team',
}

export enum MilestoneSort {
  title = 'title',
  statusCode = 'statusCode',
  dueDate = 'dueDate',
  owner = 'owner',
}

export enum UserRockSort {
  title = 'title',
  statusCode = 'statusCode',
  dueDate = 'dueDate',
}

export enum OrdinalSort {
  userOrdinal = 'userOrdinal',
  ordinal = 'ordinal',
}

export type OrdinalType = `${OrdinalSort}`;
