import { Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs';

import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { CustomLanguage } from '@ninety/ui/legacy/shared/models/language/custom-language';
import { UserWithSeats } from '@ninety/ui/legacy/shared/models/user/user-with-seat.model';
import { CoreValue } from '@ninety/ui/legacy/shared/models/vto/core-value';
import { selectIsHandsetPortrait } from '@ninety/ui/legacy/state/index';

import { FitCheckFacade } from './fit-check.facade';

export const FitCheckStateKey = 'fit-check-list';

export interface FitCheckPager {
  pageSizeOptions: number[];
  pageSize: number;
  pageIndex: number;
  length: number;
}

export interface FitCheckState {
  currentUser: User | null;
  selectedUser: User | null;
  users: UserWithSeats[];
  coreValues: CoreValue[];
  isLiteUser: boolean | null;
  isObserver: boolean | null;
  pager: FitCheckPager;
  filter: {
    selectedUserId: string;
    selectedTeamId: string;
    searchText: string | null;
    isFetchingData: boolean;
  };
}

export const initialPagerState: FitCheckPager = {
  pageSizeOptions: [10, 25, 50, 100],
  pageSize: 10,
  pageIndex: 0,
  length: 0,
};

export const initialFitCheckState: FitCheckState = {
  currentUser: null,
  selectedUser: null,
  users: [],
  coreValues: [],
  isLiteUser: null,
  isObserver: null,
  pager: {
    ...initialPagerState,
  },
  filter: {
    selectedUserId: 'all',
    selectedTeamId: null,
    searchText: null,
    isFetchingData: false,
  },
};

export interface FitCheckVm extends FitCheckState {
  language: CustomLanguage;
  isMobile: boolean;
}

export const createFitCheckVm = (facade: FitCheckFacade, store: Store): Observable<FitCheckVm> =>
  combineLatest({
    state: facade.fitCheckState$,
    language: facade.language$,
    isMobile: store.select(selectIsHandsetPortrait),
  }).pipe(
    map(vm => ({
      ...vm.state,
      language: vm.language,
      isMobile: vm.isMobile,
    }))
  );
