import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MeasurableLanguage } from '../../../../models/language/custom-language';

@Component({
  selector: 'ninety-measurable-language',
  templateUrl: './measurable-language.component.html',
  styles: [],
})
export class MeasurableLanguageComponent {
  @Input() measurable: MeasurableLanguage;
  @Input() resetLanguage: MeasurableLanguage;
  @Input() readonly: boolean;
  @Input() form: FormGroup;

  @Output() resetOne = new EventEmitter<keyof MeasurableLanguage>();

  getFormControlValue(formControlName: string): string {
    return this.form.get('measurable').value[formControlName];
  }
}
