import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';

import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { selectHasAnyHelpfulPermission } from '@ninety/ui/legacy/state/app-global/helpful-permissions/helpful-permissions.selectors';

import { SettingsMenuItem } from '../settings-menu-item.enum';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'ninety-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss'],
})
export class SettingsMenuComponent implements OnInit, OnDestroy {
  @Output() settingLinkClicked = new EventEmitter<string>();
  SettingsMenuItem = SettingsMenuItem;
  companySettingsRoutes: Routes;
  userSettingsRoutes: Routes;
  activeLink: string;

  private destroy$ = new Subject<void>();

  private subscriptions = new Subscription();

  public readonly isHelpful$ = this.store.select(selectHasAnyHelpfulPermission);
  constructor(
    private router: Router,
    public stateService: StateService,
    private spinnerService: SpinnerService,
    private settingsService: SettingsService,
    private store: Store
  ) {
    this.spinnerService.stop();
    this.userSettingsRoutes = this.settingsService.getAllowedUserSettingsRoutes();
    this.companySettingsRoutes = this.settingsService.getAllowedCompanySettingsRoutes();
  }

  ngOnInit() {
    this.stateService.routeChange$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        const segments = this.router.parseUrl(this.router.url).root.children.primary.segments;
        this.activeLink = segments && (segments[1]?.path || segments[0]?.path);
      },
    });
  }

  onMenuClick(routePath: string): void {
    this.router.navigate([`/settings/${routePath}`]);
    this.settingLinkClicked.emit(`/settings/${routePath}`);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.subscriptions.unsubscribe();
  }
}
