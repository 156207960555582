import { format, addDays, add } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { NormalizedCalendarEvent, CalendarEvent } from './interfaces';

export const TimeFormats = {
  dateTimeLocal: `yyyy-MM-dd'T'HH:mm:ss`,
  dateTimeUTCMsTeams: `yyyy-MM-dd'T'HH:mm:ss'Z'`,
  dateTimeUTC: `yyyyMMdd'T'HHmmss'Z'`,
  allDay: 'yyyyMMdd',
  allDayMsTeams: `yyyy-MM-dd'T00:00:00Z'`,
};

export const stringify = (input: Record<string, any>): string => {
  const params = new URLSearchParams();

  Object.keys(input)
    .sort()
    .forEach(key => {
      const value = input[key];
      if (value != null) {
        params.append(key, String(value));
      }
    });

  return params.toString().replace(/\+/g, '%20');
};

export const formatTimes = (
  { startDate, endDate }: NormalizedCalendarEvent,
  dateTimeFormat: keyof typeof TimeFormats,
  toUtc = false
): { startDate: string; endDate: string } => {
  const timeFormat = TimeFormats[dateTimeFormat];

  const startFormatted = format(toUtc ? utcToZonedTime(startDate, 'UTC') : startDate, timeFormat);
  const endFormatted = format(toUtc ? utcToZonedTime(endDate, 'UTC') : endDate, timeFormat);

  return {
    startDate: startFormatted,
    endDate: endFormatted,
  };
};

export const calculateTimes = (event: CalendarEvent): { startDate: Date; endDate: Date } => {
  const { startDate, endDate, duration } = event;

  if (event.allDay) {
    const dayStart = new Date(startDate.setHours(0, 0, 0, 0));
    return { startDate: dayStart, endDate: addDays(dayStart, 1) };
  }

  if (endDate) {
    return { startDate, endDate };
  }

  if (duration) {
    return { startDate, endDate: add(startDate, ...duration) };
  }
  // Default to 1 hour if no duration
  return { startDate, endDate: add(startDate, { hours: 1 }) };
};
