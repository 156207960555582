import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

let optgroupId = 0;

@Component({
  selector: 'terra-optgroup',
  standalone: true,
  exportAs: 'terraOptgroup',
  imports: [CommonModule],
  templateUrl: './terra-optgroup.component.html',
  styleUrls: ['./terra-optgroup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerraOptgroupComponent {
  protected _optgroupId = optgroupId++;

  /**
   * Label for the optgroup
   */
  @Input({ required: true }) get label(): string {
    return this._label;
  }
  set label(label: string) {
    this._label = label;
    this._changeDetectorRef.markForCheck();
  }
  private _label!: string;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}
}
