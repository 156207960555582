<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="meetings" />
      <span data-cy="meeting-language_card-title">{{ meeting.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="meeting">
      <terra-form-field>
        <terra-form-label>Page Name</terra-form-label>
        <terra-text-input
          formControlName="route"
          required
          [attr.aria-label]="meeting.route + ' Page Name'"
          data-cy="meeting-language_page-name-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('route') !== resetLanguage?.route">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('route')"
              data-cy="meeting-language_page-name-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ meeting.item }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="item"
          required
          [attr.aria-label]="meeting.item"
          data-cy="meeting-language_singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('item') !== resetLanguage?.item">
            <terra-icon icon="reset" (click)="resetOne.emit('item')" data-cy="meeting-language_singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ meeting.items }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="items"
          required
          [attr.aria-label]="meeting.items"
          data-cy="meeting-language_plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('items') !== resetLanguage?.items">
            <terra-icon icon="reset" (click)="resetOne.emit('items')" data-cy="meeting-language_plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ meeting.levelTen }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="levelTen"
          required
          [attr.aria-label]="meeting.levelTen"
          data-cy="meeting-language_weekly-meeting-singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('levelTen') !== resetLanguage?.levelTen">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('levelTen')"
              data-cy="meeting-language_weekly-meeting-singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ meeting.levelTens }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="levelTens"
          required
          [attr.aria-label]="meeting.levelTens"
          data-cy="meeting-language_weekly-meeting-plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('levelTens') !== resetLanguage?.levelTens">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('levelTens')"
              data-cy="meeting-language_weekly-meeting-plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>

      <ng-container *ngIf="NinetyFeatures.allMeetings | isFeatureAvailable | ngrxPush">
        <terra-form-field>
          <terra-form-label>{{ meeting.quarterly }}</terra-form-label>
          <terra-text-input
            formControlName="quarterly"
            required
            [attr.aria-label]="meeting.quarterly"
            data-cy="meeting-language_quarterly-meeting-input-field">
            <terra-input-suffix *ngIf="!readonly && getFormControlValue('quarterly') !== resetLanguage?.quarterly">
              <terra-icon
                icon="reset"
                (click)="resetOne.emit('quarterly')"
                data-cy="meeting-language_quarterly-meeting-reset-button" />
            </terra-input-suffix>
          </terra-text-input>
        </terra-form-field>
        <terra-form-field>
          <terra-form-label>{{ meeting.quarterlySession }} (singular)</terra-form-label>
          <terra-text-input
            formControlName="quarterlySession"
            required
            [attr.aria-label]="meeting.quarterlySession"
            data-cy="meeting-language_quarterly-planning-meeting-singular-input-field">
            <terra-input-suffix
              *ngIf="!readonly && getFormControlValue('quarterlySession') !== resetLanguage?.quarterlySession">
              <terra-icon
                icon="reset"
                (click)="resetOne.emit('quarterlySession')"
                data-cy="meeting-language_quarterly-planning-meeting-singular-reset-button" />
            </terra-input-suffix>
          </terra-text-input>
        </terra-form-field>
        <terra-form-field>
          <terra-form-label>{{ meeting.quarterlySessions }} (plural)</terra-form-label>
          <terra-text-input
            formControlName="quarterlySessions"
            required
            [attr.aria-label]="meeting.quarterlySessions"
            data-cy="meeting-language_quarterly-planning-meeting-plural-input-field">
            <terra-input-suffix
              *ngIf="!readonly && getFormControlValue('quarterlySessions') !== resetLanguage?.quarterlySessions">
              <terra-icon
                icon="reset"
                (click)="resetOne.emit('quarterlySessions')"
                data-cy="meeting-language_quarterly-planning-meeting-plural-reset-button" />
            </terra-input-suffix>
          </terra-text-input>
        </terra-form-field>

        <terra-form-field>
          <terra-form-label>{{ meeting.annual }}</terra-form-label>
          <terra-text-input
            formControlName="annual"
            required
            [attr.aria-label]="meeting.annual"
            data-cy="meeting-language_annual-meeting-input-field">
            <terra-input-suffix *ngIf="!readonly && getFormControlValue('annual') !== resetLanguage?.annual">
              <terra-icon
                icon="reset"
                (click)="resetOne.emit('annual')"
                data-cy="meeting-language_annual-meeting-reset-button" />
            </terra-input-suffix>
          </terra-text-input>
        </terra-form-field>
        <terra-form-field>
          <terra-form-label>{{ meeting.annualSession }} (singular)</terra-form-label>
          <terra-text-input
            formControlName="annualSession"
            required
            [attr.aria-label]="meeting.annualSession"
            data-cy="meeting-language_annual-planning-meeting-singular-input-field">
            <terra-input-suffix
              *ngIf="!readonly && getFormControlValue('annualSession') !== resetLanguage?.annualSession">
              <terra-icon
                icon="reset"
                (click)="resetOne.emit('annualSession')"
                data-cy="meeting-language_annual-planning-meeting-singular-reset-button" />
            </terra-input-suffix>
          </terra-text-input>
        </terra-form-field>
        <terra-form-field>
          <terra-form-label>{{ meeting.annualSessions }} (plural)</terra-form-label>
          <terra-text-input
            formControlName="annualSessions"
            required
            [attr.aria-label]="meeting.annualSessions"
            data-cy="meeting-language_annual-planning-meeting-plural-input-field">
            <terra-input-suffix
              *ngIf="!readonly && getFormControlValue('annualSessions') !== resetLanguage?.annualSessions">
              <terra-icon
                icon="reset"
                (click)="resetOne.emit('annualSessions')"
                data-cy="meeting-language_annual-planning-meeting-plural-reset-button" />
            </terra-input-suffix>
          </terra-text-input>
        </terra-form-field>

        <terra-form-field>
          <terra-form-label>{{ meeting.focusDay }}</terra-form-label>
          <terra-text-input
            formControlName="focusDay"
            required
            [attr.aria-label]="meeting.focusDay"
            data-cy="meeting-language_foundation-day-input-field">
            <terra-input-suffix *ngIf="!readonly && getFormControlValue('focusDay') !== resetLanguage?.focusDay">
              <terra-icon
                icon="reset"
                (click)="resetOne.emit('focusDay')"
                data-cy="meeting-language_foundation-day-reset-button" />
            </terra-input-suffix>
          </terra-text-input>
        </terra-form-field>
        <terra-form-field>
          <terra-form-label>{{ meeting.visionBuildingDayOne }}</terra-form-label>
          <terra-text-input
            formControlName="visionBuildingDayOne"
            required
            [attr.aria-label]="meeting.visionBuildingDayOne"
            data-cy="meeting-language_vission-session-input-field">
            <terra-input-suffix
              *ngIf="!readonly && getFormControlValue('visionBuildingDayOne') !== resetLanguage?.visionBuildingDayOne">
              <terra-icon
                icon="reset"
                (click)="resetOne.emit('visionBuildingDayOne')"
                data-cy="meeting-language_vission-session-reset-button" />
            </terra-input-suffix>
          </terra-text-input>
        </terra-form-field>
        <terra-form-field>
          <terra-form-label>{{ meeting.visionBuildingDayTwo }}</terra-form-label>
          <terra-text-input
            formControlName="visionBuildingDayTwo"
            required
            [attr.aria-label]="meeting.visionBuildingDayTwo"
            data-cy="meeting-language_goals-setting-session-input-field">
            <terra-input-suffix
              *ngIf="!readonly && getFormControlValue('visionBuildingDayTwo') !== resetLanguage?.visionBuildingDayTwo">
              <terra-icon
                icon="reset"
                (click)="resetOne.emit('visionBuildingDayTwo')"
                data-cy="meeting-language_goals-setting-session-reset-button" />
            </terra-input-suffix>
          </terra-text-input>
        </terra-form-field>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
