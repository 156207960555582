import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuItem, MatMenuModule } from '@angular/material/menu';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DirectoryActions } from '@ninety/directory-v2/_state/directory.actions';
import { DirectorySelectors } from '@ninety/directory-v2/_state/directory.selectors';
import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { User } from '@ninety/ui/legacy/shared/index';
import {
  selectCurrentUser,
  selectCurrentUserIsManagerOrAbove,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';

@Component({
  selector: 'ninety-directory-table-action',
  templateUrl: './directory-table-action.component.html',
  styleUrls: ['./directory-table-action.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    TerraIconModule,
    NinetyTooltipDirective,
    ButtonComponent,
    ButtonComponent,
    LetDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectoryTableActionComponent implements OnInit {
  @Input() user: User;

  protected readonly isManagerOrAbove$ = this.store.select(selectCurrentUserIsManagerOrAbove);
  protected readonly currentUser$ = this.store.select(selectCurrentUser);

  protected canDeactivateUser$: Observable<boolean>;
  protected canRemoveUser$: Observable<boolean>;
  protected shouldShowCopyInviteLink$: Observable<boolean>;
  protected menuType$: Observable<MenuOptions>;
  protected IsActionButtonActive$: Observable<boolean>;

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    if (this.user) {
      this.IsActionButtonActive$ = this.store.select(DirectorySelectors.selectIsActionButtonActive(this.user));
      this.menuType$ = this.store.select(DirectorySelectors.selectMenuType(this.user));
      this.canRemoveUser$ = this.store.select(DirectorySelectors.selectCanRemoveUser(this.user));
      this.canDeactivateUser$ = this.store.select(DirectorySelectors.selectCanDeactivateUser(this.user));
      this.shouldShowCopyInviteLink$ = this.store.select(DirectorySelectors.selectShouldShowCopyInviteLink(this.user));
    }
  }

  openDeactivateUserDialog(): void {
    this.store.dispatch(DirectoryActions.openDeactivateUserDialog({ user: this.user }));
  }

  copyInviteLink(copyInviteLinkBtnOnView: MatMenuItem) {
    copyInviteLinkBtnOnView.focus();
    this.store.dispatch(DirectoryActions.copyInviteLink({ user: this.user }));
  }

  openRemovePersonDialog(): void {
    this.store.dispatch(DirectoryActions.openDeleteUserDialog({ user: this.user }));
  }
}

export enum MenuOptions {
  PendingUser = 'PendingUser',
  DirectoryOnlyPerson = 'DirectoryOnlyPerson',
  ActiveUser = 'ActiveUser',
}
