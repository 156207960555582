/* eslint-disable @ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { merge } from 'lodash';
import { of, switchMap } from 'rxjs';
import { catchError, exhaustMap, filter, map, take, tap } from 'rxjs/operators';

import { DetailViewActions } from '@ninety/detail-view/_state/detail-view.actions';
import { FilterBarActions, GridFilterBarActions } from '@ninety/layouts/_state/filterbar/filterbar-state.actions';
import { GridWidgetById } from '@ninety/layouts/grid-layout/models/grid-widget-by-id';
import { GridWidget } from '@ninety/layouts/grid-layout/models/grid-widget.model';
import { PersonalTodoActions } from '@ninety/todos/_state/personal/personal-todo.actions';
import { TeamTodoActions } from '@ninety/todos/_state/team/team-todo.actions';
import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';

import { NgGridStackService } from '../services/ng-grid-stack.service';

import { GridLayoutActions } from './grid-layout-state.actions';
import { GridState } from './grid-layout-state.model';
import { GridLayoutSelectors } from './grid-layout-state.selectors';

/** @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket. */
@Injectable()
export class GridLayoutStateEffects {
  constructor(private actions$: Actions, private store: Store, private ngGridStackService: NgGridStackService) {}

  /**
   * Create and initialize an instance of GridStack.
   */
  initialize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.initialize),
      concatLatestFrom(() => this.store.select(GridLayoutSelectors.selectCurrentGrid)),
      exhaustMap(([{ opts }, currentGrid]) => {
        let editLayoutEnabled = false;

        if (currentGrid) {
          // Persist the layout mode state if initialize dispatched without a dispatch of destroy. Note, layout mode is
          // enabled by default
          editLayoutEnabled = currentGrid.editLayoutEnabled;

          this.ngGridStackService.destroy();

          // Note state.currentGrid will be reset by the dispatch of initialized bellow, so no need to set it to null.
        }

        if (!editLayoutEnabled) opts = { ...opts, disableDrag: true, disableResize: true };
        this.ngGridStackService.init(opts);

        const gridState: GridState = {
          viewModel: {},
          singletonTemplate: null,
          editLayoutEnabled,
          opts,
          isInitialized: true,
        };

        return of(GridLayoutActions.initialized({ gridState }));
      }),
      catchError((error: unknown) => this.dispatchFailure(error, 'Failed to initialize grid'))
    )
  );

  /**
   * Destroy the current grid instance, if there is one.
   *
   * Note, this effect can run even if there is no grid available. Thus, clients can dispatch destroy without having to
   * worry if the grid has been initialized. While this should be avoided, it is sometimes easier. For example, the VTO
   * component can dispatch destroy during ngOnDestroy without having to know if custom VTO was actually enabled.
   */
  destroy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.destroy),
      exhaustMap(({ clearState }) => {
        this.ngGridStackService.destroy();

        return of(GridLayoutActions.destroyed({ clearState }));
      }),
      catchError((error: unknown) => this.dispatchFailure(error, 'Failed to destroy grid'))
    )
  );

  /**
   * Enable or disable the resize/drag-and-drop.
   */
  modifyEditState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.modifyEditState),
      exhaustMap(({ editLayoutEnabled }) => {
        if (editLayoutEnabled) this.ngGridStackService.enable();
        else this.ngGridStackService.disable();

        return of(GridLayoutActions.modifiedEditState({ editLayoutEnabled }));
      }),
      catchError((error: unknown) => this.dispatchFailure(error, 'Failed to modify grid layout edit state'))
    )
  );

  /**
   * Loads a collection of Grid widgets in the grid, if the grid is initialized. Filters out non-visible components.
   *
   * TODO technically this works now, but I think it might still be a code smell
   * Clients who dispatch loadLayout should take care to only dispatch it if the grid is initialized. See
   * MyNinetyPageEffects for an example.
   *
   * Note, clients can request loadLayout with non-visible models - they are filtered out. This filtering is propagated
   * to the store - the view model will not contain non-visible models.
   */
  loadLayout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.loadLayout),
      switchMap(action =>
        this.store.select(GridLayoutSelectors.selectIsGridInitialized).pipe(
          filter(isInitialized => {
            if (!isInitialized) {
              // TODO use new FE logging
              // TODO consider if its actually fine now to dispatch loadLayout when the grid is not initialized.
              console.warn({
                message: 'Load dispatched when grid not initalized.',
                data: { action, type: 'Implementation error' },
              });
            }

            return isInitialized;
          }),
          take(1),
          map(() => {
            // Note, this action comes from the loadLayout action from the top level observable flow (actions$)
            const { models } = action;

            const visibleModels = models.filter(m => m.visible);
            this.ngGridStackService.load(visibleModels);

            // GridStack will correct invalid grid settings when it loads them. For example, if a component has x = 10,
            // but GridStack rendered it at x = 3 because there was nothing in 3 <= x < 10, it will emit x: 3. Future
            // use cases should respond to the loadedLayout action and update the backing model if it's different.
            const updatedModels = this.ngGridStackService.getUpdatedModels();

            // Merge by object NOT array to ensure we merge array elements by id.
            const updatedVm = GridWidgetById.from(updatedModels);
            const initialVM = GridWidgetById.from(models);
            const finalVm = merge({}, initialVM, updatedVm);

            return GridLayoutActions.loadedLayout({ viewModel: finalVm });
          }),
          catchError((error: unknown) => this.dispatchFailure(error, 'Failed to load grid'))
        )
      )
    )
  );

  /**
   * Change the visibility of a widget TODO forward to patch
   */
  removeWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.removeWidget),
      concatLatestFrom(() => this.store.select(GridLayoutSelectors.selectCurrentGridModelMap)),
      exhaustMap(([{ id }, initialViewModel]) => {
        const updatedViewModel = { ...initialViewModel };
        updatedViewModel[id] = { ...updatedViewModel[id], visible: false };

        return of(GridLayoutActions.loadLayout({ models: Object.values(updatedViewModel) }));
      })
    )
  );

  /**
   * Change the visibility of a widget TODO forward to patch
   */
  showWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.showWidget),
      concatLatestFrom(() => this.store.select(GridLayoutSelectors.selectCurrentGridModelMap)),
      exhaustMap(([{ id }, initialViewModel]) => {
        const updatedViewModel = { ...initialViewModel };
        updatedViewModel[id] = GridWidget.transformWidgetToAddToGrid(updatedViewModel[id]);

        return of(GridLayoutActions.loadLayout({ models: Object.values(updatedViewModel) }));
      })
    )
  );

  /**
   * Change the visibility of a widget
   */
  patchWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.patchWidget),
      concatLatestFrom(() => this.store.select(GridLayoutSelectors.selectCurrentGridModelMap)),
      map(([{ id, patch }, initialViewModel]) => {
        const updatedViewModel = merge({}, initialViewModel, { [id]: patch });
        return GridLayoutActions.loadLayout({ models: Object.values(updatedViewModel) });
      })
    )
  );

  /**
   * Enter layout mode on request from filters-toolbar
   */
  enterLayoutMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridFilterBarActions.enterLayoutMode),
      exhaustMap(() => of(GridLayoutActions.modifyEditState({ editLayoutEnabled: true })))
    )
  );

  /**
   * Emit updated widgets for persistence on request from filters-toolbar
   */
  emitUpdatedModelsOnRequestPersist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridFilterBarActions.requestPersistChanges),
      exhaustMap(() => {
        const currentWidgets = this.ngGridStackService.getUpdatedModels();
        return of(GridLayoutActions.persistLayout({ widgets: currentWidgets }));
      }),
      catchError((error: unknown) =>
        of(
          GridLayoutActions.persistLayoutFailure({
            error,
            message: 'Failed to construct layout update',
          })
        )
      )
    )
  );

  markAsFetching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.persistLayout),
      map(() => FilterBarActions.disabled())
    )
  );

  markAsDoneFetching$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.persistLayoutSuccess, GridLayoutActions.persistLayoutFailure),
      map(() => FilterBarActions.enabled())
    )
  );

  notifyOnPersistSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.persistLayoutSuccess),
      exhaustMap(() => of(NotificationActions.notify({ message: 'Saved layout' })))
    )
  );

  notifyOnPersistFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.persistLayoutFailure),
      exhaustMap(({ error, message }) => this.dispatchFailure(error, message))
    )
  );

  exitOnCancelOrSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridFilterBarActions.cancelChanges, GridLayoutActions.persistLayout),
      exhaustMap(() => of(GridFilterBarActions.leaveLayoutMode()))
    )
  );

  exitLayoutMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridFilterBarActions.leaveLayoutMode),
      exhaustMap(() => of(GridLayoutActions.modifyEditState({ editLayoutEnabled: false })))
    )
  );

  disableOneColumnModeOnEnterLayoutMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GridFilterBarActions.enterLayoutMode),
        tap(() => this.ngGridStackService.disableOneColumnMode())
      ),
    { dispatch: false }
  );

  reEnableOneColumnModeOnExitLayoutMode = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GridFilterBarActions.leaveLayoutMode),
        tap(() => this.ngGridStackService.reEnableOneColumnMode())
      ),
    { dispatch: false }
  );

  showSingleton$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.showSingleton),
      concatLatestFrom(() => this.store.select(GridLayoutSelectors.selectAllowSingletonHandling)),
      map(([{ id }, shouldAllowSingleton]) => {
        if (!shouldAllowSingleton) return GridLayoutActions.showSingletonIgnored();

        this.ngGridStackService.setSingleton(id);
        return GridLayoutActions.showSingletonSuccess({ id });
      })
    )
  );

  clearSingleton$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.clearSingleton),
      concatLatestFrom(() => [
        this.store.select(GridLayoutSelectors.selectIsSingletonActive),
        this.store.select(GridLayoutSelectors.selectAllowSingletonHandling),
      ]),
      map(([_, isSingletonActive, allowSingleton]) => {
        if (!isSingletonActive) {
          console.debug(
            'GridLayoutActions.clearSingleton emitted when singleton was not active. Dispatchers should not ' +
              'dispatch this action when singleton is not active. No action taken.'
          );
          return GridLayoutActions.clearSingletonIgnored();
        }

        if (!allowSingleton) return GridLayoutActions.clearSingletonIgnored();

        this.ngGridStackService.clearSingleton();
        return GridLayoutActions.clearSingletonSuccess();
      })
    )
  );

  clearSingletonOnDetailClose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailViewActions.closed, PersonalTodoActions.deselect, TeamTodoActions.deselect),
      concatLatestFrom(() => [
        this.store.select(GridLayoutSelectors.selectIsGridInitialized),
        this.store.select(GridLayoutSelectors.selectIsSingletonActive),
      ]),
      filter(([, isInitialized, isSingletonActive]) => isInitialized && isSingletonActive),
      map(() => GridLayoutActions.clearSingleton())
    )
  );

  /**
   * When the grid is resized, assuming the grid is initialized and not in edit layout mode, enter or exit one column
   * mode depending on the current breakpoint. My90 will use Ninety-controlled one column mode, while VTO will use
   * GridStack one column mode.
   */
  respondToResize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.lessThanBreakpoint, GridLayoutActions.greaterThanBreakpoint),
      concatLatestFrom(() => this.store.select(GridLayoutSelectors.selectCurrentGrid)),
      filter(([, currentGrid]) => !!currentGrid && !currentGrid.editLayoutEnabled),
      map(([action, currentGrid]) => {
        const isLessThan = action.type === GridLayoutActions.lessThanBreakpoint.type;
        if (currentGrid.opts.useNinetyOneColumnMode) {
          // Modern approach For My90
          return isLessThan ? GridLayoutActions.enterOneColumnMode() : GridLayoutActions.exitOneColumnMode();
        } else {
          // Legacy approach for VTO - skip Ninety-controlled 1 column mode and skip straight to success actions
          return isLessThan
            ? GridLayoutActions.successfullyEnteredOneColumnMode()
            : GridLayoutActions.successfullyExitedOneColumnMode();
        }
      })
    )
  );

  markAsReadyToDestroySkeleton$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.lessThanBreakpoint, GridLayoutActions.greaterThanBreakpoint),
      switchMap(action =>
        this.actions$.pipe(
          ofType(
            action.type === GridLayoutActions.lessThanBreakpoint.type
              ? GridLayoutActions.successfullyEnteredOneColumnMode
              : GridLayoutActions.successfullyExitedOneColumnMode
          ),
          map(() => GridLayoutActions.firstPaintComplete())
        )
      )
    )
  );

  /** Enter Ninety-controlled one column mode */
  enterOneColumnMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.enterOneColumnMode),
      concatLatestFrom(() => this.store.select(GridLayoutSelectors.selectIsSingletonActive)),
      filter(([, isSingletonActive]) => !isSingletonActive),
      tap(() => this.ngGridStackService.enterOneColumMode()),
      map(() => GridLayoutActions.successfullyEnteredOneColumnMode()),
      catchError((error: unknown) => of(GridLayoutActions.failedToEnterOneColumnMode({ error })))
    )
  );

  /** Exit Ninety-controlled one column mode */
  exitOneColumnMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.exitOneColumnMode),
      concatLatestFrom(() => this.store.select(GridLayoutSelectors.selectIsOneColumnMode)),
      tap(([, isOneColumnMode]) => {
        // Always want to dispatch success action, but only want to call method to exit one column mode if its actually
        // active
        if (isOneColumnMode) this.ngGridStackService.exitOneColumnMode();
      }),
      map(() => GridLayoutActions.successfullyExitedOneColumnMode()),
      catchError((error: unknown) => of(GridLayoutActions.failedToExitOneColumnMode({ error })))
    )
  );

  handleFailuresOfOneColumnSwitch = createEffect(() =>
    this.actions$.pipe(
      ofType(GridLayoutActions.failedToEnterOneColumnMode, GridLayoutActions.failedToExitOneColumnMode),
      exhaustMap(action => {
        const errorCode = action.type === GridLayoutActions.failedToEnterOneColumnMode.type ? 0 : 1;
        const message = `One Column Mode failure. Please refresh the page and contact support if issue persists. Error Code: ${errorCode}`;
        return this.dispatchFailure(action.error, message);
      })
    )
  );

  changeColumnCount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GridLayoutActions.changeColumnCount),
        concatLatestFrom(() => this.store.select(GridLayoutSelectors.selectIsGridInitialized)),
        filter(([, isInitialized]) => isInitialized),
        tap(([{ columnCount }]) => this.ngGridStackService.updateColumnCount(columnCount)),
        catchError((error: unknown) => this.dispatchFailure(error, 'Failed to change column count'))
      ),
    { dispatch: false }
  );

  // Debounce rapid submissions of this action
  changeCellHeight$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GridLayoutActions.changeCellHeight),
        concatLatestFrom(() => this.store.select(GridLayoutSelectors.selectIsGridInitialized)),
        filter(([, isInitialized]) => isInitialized),
        tap(([{ cellHeight }]) => this.ngGridStackService.updateCellHeight(cellHeight)),
        catchError((error: unknown) => this.dispatchFailure(error, 'Failed to change cell height'))
      ),
    { dispatch: false }
  );

  changeFloat$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GridLayoutActions.changeFloat),
        concatLatestFrom(() => this.store.select(GridLayoutSelectors.selectIsGridInitialized)),
        filter(([, isInitialized]) => isInitialized),
        tap(([{ float }]) => this.ngGridStackService.updateFloatMode(float)),
        catchError((error: unknown) => this.dispatchFailure(error, 'Failed to float'))
      ),
    { dispatch: false }
  );

  compact$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GridLayoutActions.compact),
        concatLatestFrom(() => this.store.select(GridLayoutSelectors.selectIsGridInitialized)),
        filter(([, isInitialized]) => isInitialized),
        tap(() => this.ngGridStackService.compact()),
        catchError((error: unknown) => this.dispatchFailure(error, 'Failed to change cell height'))
      ),
    { dispatch: false }
  );

  private dispatchFailure(error: unknown, message: string) {
    return of(NotificationActions.notifyError({ error, message }));
  }
}
