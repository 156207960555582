import { createSelector } from '@ngrx/store';

import { QuickFilterOption } from '@ninety/ui/legacy/components/index';
import { Team, User } from '@ninety/ui/legacy/shared/index';
import { TeamSelectors, selectAllNonObserverUsers, selectCurrentUser } from '@ninety/ui/legacy/state/index';

import { selectFeedbackState } from '../feedback-state';

import { WeeklyStateKey } from './weekly.model';
import { initialWeeklyState } from './weekly.reducer';

const allSelected = { id: 'all', displayText: 'All', selected: true };

const allSelectedFalse = { id: 'all', displayText: 'All', selected: false };

export const selectWeeklyState = createSelector(selectFeedbackState, state =>
  state ? state[WeeklyStateKey] : initialWeeklyState
);

export const selectSelectedTeam = createSelector(TeamSelectors.selectFilterBarTeam, team => team);

export const selectWeeklyTeams = createSelector(selectWeeklyState, state =>
  state.teams.filter(team => team.users.length === 2)
);

export const selectStoredWeeklyConversationId = createSelector(
  selectWeeklyState,
  state => state.inProgressWeeklyConversationId
);

export const selectUsers = createSelector(selectAllNonObserverUsers, users => users);

export const selectCurrentUserHideWeeklyMeetingsAlert = createSelector(
  selectCurrentUser,
  user => user?.tutorialsHidden?.weeklyConversationAlert
);

export const selectUserFilterOptions = createSelector(
  selectUsers,
  selectCurrentUser,
  (users, currentUser): QuickFilterOption<User>[] => {
    if (currentUser.isManagerOrAbove) {
      return [
        allSelected,
        ...users.map(user => ({
          displayText: user.fullName,
          id: user._id,
          item: user,
          selected: false,
        })),
      ];
    }
    return [
      ...users.map(user => ({
        displayText: user.fullName,
        id: user._id,
        item: user,
        selected: currentUser._id === user._id,
      })),
    ];
  }
);

export const selectFitCheckUserFilterOptions = createSelector(
  selectUsers,
  selectCurrentUser,
  (users, currentUser): QuickFilterOption<User>[] => {
    return [
      allSelectedFalse,
      ...users.map(user => ({
        displayText: user.fullName,
        id: user._id,
        item: user,
        selected: currentUser._id === user._id,
      })),
    ];
  }
);

export const selectTeamFilterOptions = createSelector(
  selectSelectedTeam,
  selectWeeklyTeams,
  (selectedTeam, teams): QuickFilterOption<Team>[] => {
    if (selectedTeam?._id) {
      return teams.map(team => ({
        displayText: team.name,
        id: team._id,
        item: team,
        selected: team._id === selectedTeam._id,
      }));
    }
    return [];
  }
);

export const selectShowCompletedConversations = createSelector(
  selectWeeklyState,
  state => state.filter.showCompletedConversations
);
