<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="data" />
      <span data-cy="scorecard-language_card-title">{{ scorecard?.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="scorecard">
      <terra-form-field>
        <terra-form-label>Page Name</terra-form-label>
        <terra-text-input
          formControlName="route"
          required
          [attr.aria-label]="scorecard.route + ' Page Name'"
          data-cy="scorecard-language_page-name-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('route') !== resetLanguage?.route">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('route')"
              data-cy="scorecard-language_page-name-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ scorecard.item }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="item"
          required
          [attr.aria-label]="scorecard.item"
          data-cy="scorecard-language_singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('item') !== resetLanguage?.item">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('item')"
              data-cy="scorecard-language_singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field appearance="outline">
        <terra-form-label>{{ scorecard.items }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="items"
          required
          [attr.aria-label]="scorecard.items"
          data-cy="scorecard-language_plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('items') !== resetLanguage?.items">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('items')"
              data-cy="scorecard-language_plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
    </div>
  </mat-card-content>
</mat-card>
