import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraOptionModule } from '../terra-option';

import { TerraOptgroupComponent } from './terra-optgroup.component';

@NgModule({
  imports: [CommonModule, TerraOptgroupComponent, TerraOptionModule],
  exports: [TerraOptgroupComponent, TerraOptionModule],
})
export class TerraOptgroupModule {}
