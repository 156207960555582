import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, of, switchMap, tap } from 'rxjs';

import { GuideActions } from '@ninety/getting-started/guide/_state/guide.actions';
import { MazActions } from '@ninety/getting-started/maz/_state/maz.actions';
import { MazChatService } from '@ninety/getting-started/maz/services/maz-chat.service';
import { MazStatus } from '@ninety/ui/legacy/shared/models/maz/maz-status.enum';
import { CurrentPersonStateActions } from '@ninety/ui/legacy/state/app-global/current-person/current-person.actions';

@Injectable()
export class MazEffects {
  getConversationHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GuideActions.init),
      switchMap(() =>
        this.mazChatService
          .getConversationHistory()
          .pipe(map(mazMessages => MazActions.getConversationHistorySuccess({ mazMessages })))
      )
    )
  );

  sendMessageToMaz$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MazActions.sendMessageToMaz),
      switchMap(({ userInput }) =>
        this.mazChatService.sendMazMessage(userInput).pipe(
          map(response => MazActions.receiveMessageFromMaz({ response })),
          catchError((error: unknown) =>
            of(
              MazActions.failedToReceiveMessageFromMaz({
                error,
                message: 'Failed to receive response from Maz',
              })
            )
          )
        )
      )
    )
  );

  sendMessageFeedback$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MazActions.sendMessageFeedback),
        switchMap(({ feedback }) => this.mazChatService.submitFeedback(feedback))
      ),
    { dispatch: false }
  );

  showWaitingForReply$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MazActions.sendMessageToMaz),
      delay(1000),
      map(() => MazActions.waitingForMazReply())
    )
  );

  keepScrollOnBottom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MazActions.sendMessageToMaz, MazActions.waitingForMazReply, MazActions.sendMessageFeedback),
        tap(() => {
          setTimeout(() => {
            const conversation = document.getElementById('maz-conversation');
            conversation.scrollTop = conversation.scrollHeight;
          });
        })
      ),
    { dispatch: false }
  );

  scrollToTopOfLastMazMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MazActions.receiveMessageFromMaz),
        tap(() => {
          setTimeout(() => {
            const conversation = document.getElementById('maz-conversation');
            const messages = document.getElementsByClassName('maz-message');
            const lastMessagePosition = (messages[messages.length - 1] as HTMLElement)?.offsetTop;
            conversation.scrollTop = lastMessagePosition - 100;
          });
        })
      ),
    { dispatch: false }
  );

  toggleUserMazChatStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MazActions.toggleUserMazStatus),
      map(({ enabled }) => {
        const mazStatus = enabled ? MazStatus.ENABLED : MazStatus.DISABLED;
        return CurrentPersonStateActions.updateCurrentPerson({ update: { mazStatus } });
      })
    )
  );

  constructor(private actions$: Actions, private mazChatService: MazChatService) {}
}
