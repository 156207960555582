<ng-container>
  <div class="modal">
    <div class="header">
      <div class="close">
        <ninety-button mat-dialog-close data-product="calendar-confirmation-close">
          <terra-icon icon="x" size="20" />
        </ninety-button>
      </div>
    </div>
    <div class="content">
      <div class="status-icon">
        <terra-icon icon="check" variant="bold" size="32" />
      </div>

      <div class="terra-title">Meeting Scheduled</div>

      <div class="info-box">
        <div class="record">
          <div class="label">Agenda</div>
          <div class="value">{{ data.meetingTitle }}</div>
        </div>
        <div class="record">
          <div class="label">Team</div>
          <div class="value">{{ data.teamName }}</div>
        </div>
        <div class="record">
          <div class="label">When</div>
          <div class="value">
            <div>{{ data.meetingStartDate | date : 'mediumDate' }}</div>
            <div>
              {{ data.meetingStartDate | customAmPmFormatTimePipe : 'h:mm a' }} -
              {{ data.meetingEndDate | customAmPmFormatTimePipe : 'h:mm a' }}
              {{ data.displayTimezone }}
            </div>
          </div>
        </div>
        <div class="record" *ngIf="data.cadence !== 'none'">
          <div class="label">Repeats</div>
          <div class="value">{{ data.cadence | titlecase }}</div>
        </div>
      </div>

      <div class="calendar-section">
        <div class="calendar-title terra-body-semibold">Add meeting to your calendar:</div>
        <div class="calendar-links">
          <div class="link">
            <img
              src="/assets/icons/svgs/integrations/google-calendar.svg"
              alt="Google Calendar"
              (click)="addToCalendar('google')"
              data-product="calendar-confirmation-google" />
            <div class="terra-body-tiny">Google</div>
          </div>
          <div class="link">
            <img
              src="/assets/icons/svgs/integrations/office365-calendar.svg"
              alt="Office365 Calendar"
              (click)="addToCalendar('office365')"
              data-product="calendar-confirmation-office365" />
            <div class="terra-body-tiny">Office 365<span *ngIf="data.cadence !== 'none'"> *</span></div>
          </div>
          <div class="link">
            <img
              src="/assets/icons/svgs/integrations/teams.svg"
              alt="Teams"
              (click)="addToCalendar('msTeams')"
              data-product="calendar-confirmation-teams" />
            <div class="terra-body-tiny">Teams<span *ngIf="data.cadence !== 'none'"> *</span></div>
          </div>
          <div class="link">
            <img
              src="/assets/icons/svgs/integrations/ics-calendar.svg"
              alt="ics Calendar"
              (click)="addToCalendar('ics')"
              data-product="calendar-confirmation-ics" />
            <div class="terra-body-tiny">ICS</div>
          </div>
        </div>

        <div class="disclaimer terra-body-small" *ngIf="data.cadence !== 'none'">
          * Repeating meetings are currently not supported. Please add repeating on your calendar invite.
        </div>
      </div>
    </div>
  </div>
</ng-container>
