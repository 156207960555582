import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { FilterBarActions } from '@ninety/layouts/_state/filterbar/filterbar-state.actions';
import { TerraIconModule } from '@ninety/terra';
import { NinetyCMModule } from '@ninety/ui/legacy/components/commercial-model/cm.module';
import { selectLanguageArea } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

/**
 * This is just an example tiered page for to-dos.
 * Mainly to help demonstrate how the 'hasFeatureAccessGuard' route guard  works.
 * This is meant for the team to replace with their own content or completely remove if not needed.
 * */
@Component({
  selector: 'ninety-todos-tiered-page',
  standalone: true,
  imports: [CommonModule, TerraIconModule, NinetyCMModule, LetDirective],
  template: `<ninety-cm-tiered-page *ngrxLet="todoLang$ as language">
    <div headline>Use {{ language.items }} to Get More Done</div>
    <div description>
      Get access to the {{ language.items }} tool to drive accountability and ensure the completion of every commitment.
      Keep your team focused and on track.
    </div>
    <button ninety-cm-button>
      <terra-icon icon="lightning" variant="fill" />
      Explore Essential Plan
    </button>
    <a href="https://www.ninety.io" rel="external" target="_blank">Learn more about {{ language.items }}</a>
  </ninety-cm-tiered-page>`,
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodosTieredPageComponent implements OnInit {
  private readonly store = inject(Store);
  protected readonly todoLang$ = this.store.select(selectLanguageArea('todo'));

  ngOnInit(): void {
    this.store.dispatch(FilterBarActions.hide());
  }
}
