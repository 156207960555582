<div class="language-container">
  <mat-toolbar class="mat-elevation-z2 custom-language-toolbar" *ngIf="!readonly">
    <ninety-button-row>
      <ninety-button
        *ngIf="showResetBtn$ | ngrxPush"
        flat
        data-cy="custom-language_reset-to-default"
        (click)="resetAll()"
        [disabled]="spinner$ | ngrxPush">
        <terra-icon icon="reset" />
        Reset All
      </ninety-button>
      <ninety-button
        stroked
        data-cy="custom-language_cancel-changes"
        (click)="cancel()"
        [disabled]="languageForm.pristine">
        <terra-icon icon="undo" />
        Cancel Changes
      </ninety-button>
      <ninety-button
        filled
        primary
        data-cy="custom-language_save-changes"
        (click)="onSave()"
        [disabled]="languageForm.invalid || languageForm.pristine">
        <terra-icon icon="check" />
        Save Changes
      </ninety-button>
    </ninety-button-row>
  </mat-toolbar>

  <div class="language-cards" [class.readonly]="readonly">
    <!-- My90 -->
    <ninety-my-ninety-language
      *ngIf="NinetyFeatures.my90 | isFeatureAvailable | ngrxPush"
      [form]="languageForm"
      [my90]="language.my90"
      [resetLanguage]="resetLanguage?.my90"
      [readonly]="readonly"
      (resetOne)="resetOne('my90', $event)" />

    <!-- Data (scorecard)-->
    <ninety-scorecard-language
      *ngIf="NinetyFeatures.scorecard | isFeatureAvailable | ngrxPush"
      [form]="languageForm"
      [scorecard]="language.scorecard"
      [resetLanguage]="resetLanguage?.scorecard"
      [readonly]="readonly"
      (resetOne)="resetOne('scorecard', $event)" />

    <!-- Measurable -->
    <ninety-measurable-language
      *ngIf="NinetyFeatures.scorecard | isFeatureAvailable | ngrxPush"
      [form]="languageForm"
      [measurable]="language.measurable"
      [resetLanguage]="resetLanguage?.measurable"
      [readonly]="readonly"
      (resetOne)="resetOne('measurable', $event)" />

    <!-- Rocks -->
    <ninety-rock-language
      *ngIf="NinetyFeatures.rocks | isFeatureAvailable | ngrxPush"
      [form]="languageForm"
      [rock]="language.rock"
      [resetLanguage]="resetLanguage?.rock"
      [readonly]="readonly"
      (resetOne)="resetOne('rock', $event)" />

    <!-- Milestones -->
    <ninety-milestone-language
      *ngIf="NinetyFeatures.milestones | isFeatureAvailable | ngrxPush"
      [form]="languageForm"
      [milestone]="language.milestone"
      [resetLanguage]="resetLanguage?.milestone"
      [readonly]="readonly"
      (resetOne)="resetOne('milestone', $event)" />

    <!-- To-Dos -->
    <ninety-todo-language
      *ngIf="NinetyFeatures.todos | isFeatureAvailable | ngrxPush"
      [form]="languageForm"
      [todo]="language.todo"
      [resetLanguage]="resetLanguage?.todo"
      [readonly]="readonly"
      (resetOne)="resetOne('todo', $event)" />

    <!-- Issues -->
    <ninety-issue-language
      *ngIf="NinetyFeatures.issues | isFeatureAvailable | ngrxPush"
      [form]="languageForm"
      [issue]="language.issue"
      [resetLanguage]="resetLanguage?.issue"
      [readonly]="readonly"
      (resetOne)="resetOne('issue', $event)" />

    <!-- Meetings -->
    <!-- Using least common denominator since weeklyMeetings is always there on all tiers  -->
    <ninety-meeting-language
      *ngIf="NinetyFeatures.weeklyMeetings | isFeatureAvailable | ngrxPush"
      [form]="languageForm"
      [meeting]="language.meeting"
      [resetLanguage]="resetLanguage?.meeting"
      [readonly]="readonly"
      (resetOne)="resetOne('meeting', $event)" />

    <!-- Headlines -->
    <ninety-headline-language
      *ngIf="NinetyFeatures.headlines | isFeatureAvailable | ngrxPush"
      [form]="languageForm"
      [headline]="language.headline"
      [resetLanguage]="resetLanguage?.headline"
      [readonly]="readonly"
      (resetOne)="resetOne('headline', $event)" />

    <!-- Cascading Messages -->
    <ninety-cascading-message-language
      *ngIf="NinetyFeatures.cascadingMessages | isFeatureAvailable | ngrxPush"
      [form]="languageForm"
      [cascadingMessage]="language.cascadingMessage"
      [resetLanguage]="resetLanguage?.cascadingMessage"
      [readonly]="readonly"
      (resetOne)="resetOne('cascadingMessage', $event)" />

    <ninety-vision-language
      [form]="languageForm"
      [vision]="language.vto"
      [resetLanguage]="resetLanguage?.vto"
      [readonly]="readonly"
      (resetOne)="resetOne('vto', $event)" />

    <ninety-responsibilities-language
      [form]="languageForm"
      [responsibilities]="language.acChart"
      [resetLanguage]="resetLanguage?.acChart"
      [readonly]="readonly"
      (resetOne)="resetOne('acChart', $event)" />

    <!-- 1-on-1 -->
    <ninety-one-on-one-language
      *ngIf="NinetyFeatures.oneOnOne | isFeatureAvailable | ngrxPush"
      [form]="languageForm"
      [oneOnOne]="language.feedback"
      [resetLanguage]="resetLanguage?.feedback"
      [readonly]="readonly"
      (resetOne)="resetOne('feedback', $event)" />

    <!-- Process -->
    <ninety-process-language
      *ngIf="NinetyFeatures.process | isFeatureAvailable | ngrxPush"
      [form]="languageForm"
      [process]="language.process"
      [resetLanguage]="resetLanguage?.process"
      [readonly]="readonly || (processReadOnly$ | ngrxPush)"
      (resetOne)="resetOne('process', $event)" />

    <ninety-directory-language
      [form]="languageForm"
      [directory]="language.directory"
      [resetLanguage]="resetLanguage?.directory"
      [readonly]="readonly"
      (resetOne)="resetOne('directory', $event)" />

    <!-- Mastery -->
    <ninety-mastery-language
      *ngIf="NinetyFeatures.mastery | isFeatureAvailable | ngrxPush"
      [form]="languageForm"
      [mastery]="language.mastery"
      [resetLanguage]="resetLanguage?.mastery"
      [readonly]="readonly || (masteryReadOnly$ | ngrxPush)"
      (resetOne)="resetOne('mastery', $event)" />

    <ninety-survey-language
      *ngIf="surveysEnabled$ | ngrxPush"
      [form]="languageForm"
      [survey]="language.survey"
      [resetLanguage]="resetLanguage?.survey"
      [readonly]="readonly"
      (resetOne)="resetOne('survey', $event)" />
  </div>
</div>
