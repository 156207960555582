import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NinetyFeatures } from '../../../../models/company/company-pricing-tiers';
import { MeetingLanguage } from '../../../../models/language/custom-language';

@Component({
  selector: 'ninety-meeting-language',
  templateUrl: './meeting-language.component.html',
  styles: [],
})
export class MeetingLanguageComponent {
  @Input() meeting: MeetingLanguage;
  @Input() resetLanguage: MeetingLanguage;
  @Input() readonly: boolean;
  @Input() form: FormGroup;

  @Output() resetOne = new EventEmitter<keyof MeetingLanguage>();

  protected readonly NinetyFeatures = NinetyFeatures;

  getFormControlValue(formControlName: string): string {
    return this.form.get('meeting').value[formControlName];
  }
}
