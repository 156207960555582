import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MasteryLanguage } from '../../../../models/language/custom-language';

@Component({
  selector: 'ninety-mastery-language',
  templateUrl: './mastery-language.component.html',
  styles: [],
})
export class MasteryLanguageComponent {
  @Input() mastery: MasteryLanguage;
  @Input() resetLanguage: MasteryLanguage;
  @Input() form: FormGroup;

  @Input() set readonly(isReadonly: boolean) {
    if (isReadonly) {
      this.form.get('mastery').disable();
    }
  }

  @Output() resetOne = new EventEmitter<keyof MasteryLanguage>();

  getFormControlValue(formControlName: string): string {
    return this.form.get('mastery').value[formControlName];
  }
}
