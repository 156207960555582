import { PhoneType } from './phone-type';

export class PhoneNumber {
  _id?: string;
  number: string;
  typeCode: PhoneType;

  constructor(number: string, typeCode: PhoneType) {
    this.number = number;
    this.typeCode = typeCode;
  }

  static fromFormData(data: PhoneNumber[]): PhoneNumber[] {
    return data.filter(p => Boolean(p.number?.length));
  }
}
