<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="process" />
      <span data-cy="process-language_card-title">{{ process.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="process">
      <terra-form-field>
        <terra-form-label>Page Name</terra-form-label>
        <terra-text-input
          formControlName="route"
          required
          [attr.aria-label]="process.route + ' Page Name'"
          data-cy="process-language_page-name-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('route') !== resetLanguage?.route">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('route')"
              data-cy="process-language_page-name-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ process.item }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="item"
          required
          [attr.aria-label]="process.item"
          data-cy="process-language_singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('item') !== resetLanguage?.item">
            <terra-icon icon="reset" (click)="resetOne.emit('item')" data-cy="process-language_singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ process.items }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="items"
          required
          [attr.aria-label]="process.items"
          data-cy="process-language_plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('items') !== resetLanguage?.items">
            <terra-icon icon="reset" (click)="resetOne.emit('items')" data-cy="process-language_plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ process.coreProcess }}</terra-form-label>
        <terra-text-input
          formControlName="coreProcess"
          required
          [attr.aria-label]="process.coreProcess"
          data-cy="process-language_core-process-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('coreProcess') !== resetLanguage?.coreProcess">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('coreProcess')"
              data-cy="process-language_core-process-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ process.step }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="step"
          required
          [attr.aria-label]="process.step"
          data-cy="process-language_step-singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('step') !== resetLanguage?.step">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('step')"
              data-cy="process-language_step-singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ process.steps }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="steps"
          required
          [attr.aria-label]="process.steps"
          data-cy="process-language_step-plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('steps') !== resetLanguage?.steps">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('steps')"
              data-cy="process-language_step-plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ process.subStep }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="subStep"
          required
          [attr.aria-label]="process.subStep"
          data-cy="process-language_substep-singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('subStep') !== resetLanguage?.subStep">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('subStep')"
              data-cy="process-language_substep-singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ process.subSteps }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="subSteps"
          required
          [attr.aria-label]="process.subSteps"
          data-cy="process-language_substep-plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('subSteps') !== resetLanguage?.subSteps">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('subSteps')"
              data-cy="process-language_substep-plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ process.documented }}</terra-form-label>
        <terra-text-input
          formControlName="documented"
          required
          [attr.aria-label]="process.documented"
          data-cy="process-language_documented-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('documented') !== resetLanguage?.documented">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('documented')"
              data-cy="process-language_documented-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ process.followedByAll }}</terra-form-label>
        <terra-text-input
          formControlName="followedByAll"
          required
          [attr.aria-label]="process.followedByAll"
          data-cy="process-language_followed-by-all-input-field">
          <terra-input-suffix
            *ngIf="!readonly && getFormControlValue('followedByAll') !== resetLanguage?.followedByAll">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('followedByAll')"
              data-cy="process-language_followed-by-all-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ process.effective }}</terra-form-label>
        <terra-text-input
          formControlName="effective"
          required
          [attr.aria-label]="process.effective"
          data-cy="process-language_effective-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('effective') !== resetLanguage?.effective">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('effective')"
              data-cy="process-language_effective-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
    </div>
  </mat-card-content>
</mat-card>
