<ng-container *ngIf="meetingService.meetingSchedules$ | async as schedules">
  <div class="card-wrp terrafy-main-content" data-cy="meeting-schedules-card">
    <ninety-card *ngrxLet="vm$ as vm">
      <ninety-widget-skeleton *ngIf="vm.loading" [tableBodyCount]="LOADING_SKELETON_ROWS"></ninety-widget-skeleton>
      <ninety-card-header header *ngIf="!vm.loading">
        <ninety-card-title-with-count title="Upcoming {{ vm.language.meeting.items }}"></ninety-card-title-with-count>
        <terra-icon
          class="upcoming-info-icon"
          (click)="$event.stopPropagation(); isUpcomingMeetingsPopoverOpen = !isUpcomingMeetingsPopoverOpen"
          icon="info-circle"
          size="20"
          variant="fill"
          ninetyPopoverOrigin
          #editTrigger="ninetyPopoverOrigin"
          data-product="learning-modules__sharing-tips-info-icon"></terra-icon>
        <ninety-popover
          [content]="upcomingMeetingsPopoverContent"
          [trigger]="editTrigger"
          [isOpen]="isUpcomingMeetingsPopoverOpen"
          (popoverClosed)="isUpcomingMeetingsPopoverOpen = false">
        </ninety-popover>
        <ng-template #upcomingMeetingsPopoverContent>
          <div class="upcoming-popover-content">
            <div class="popover-header">
              <div class="icon-container">
                <terra-icon icon="info-circle" variant="fill" size="20" class="info-circle-icon"></terra-icon>
              </div>
              <h2>Upcoming {{ vm.language.meeting.items }}</h2>
            </div>
            <terra-divider />
            <div class="sub-level-info">
              <p class="info-text">
                This section displays all one-off {{ vm.language.meeting.items }} plus repeating
                {{ vm.language.meeting.items }} scheduled within the next 30 days.
              </p>
            </div>
          </div>
        </ng-template>
      </ninety-card-header>
      <ng-container *ngIf="!vm.loading && !schedules.length">
        <ninety-empty-list-view
          id="scheduled-meetings-card-empty"
          data-cy="scheduled-meetings-card-empty"
          title="Your team hasn't created any scheduled {{ vm.language.meeting.items }} yet."
          customMessage="Scheduled {{ vm.language.meeting.items }} are a great way to keep your team aligned."
          iconName="meetings"
          iconVariant="fill"
          linkText="Learn more about Scheduled {{ vm.language.meeting.items }}"
          [linkUrl]="
            vm.bos === eos
              ? 'https://help.eos.ninety.io/scheduling-meetings'
              : 'https://help.ninety.io/scheduling-meetings'
          "></ninety-empty-list-view>
      </ng-container>
      <ng-container *ngIf="!vm.loading && schedules.length">
        <table mat-table [dataSource]="schedules" class="meeting-schedules" data-cy="meeting-schedules-table">
          <ng-container matColumnDef="frontSpacer">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef class="front-spacer"></td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let schedule">
              <div class="date-wrp">
                {{ schedule.scheduledDate | upcomingDate }}
                <ng-container *ngIf="schedule.cadence !== Cadence.none">
                  <terra-icon *ngIf="!vm.isMobile" class="muted" icon="clock" size="16" placement="bottom" [tp]="tpl" />
                  <ng-template #tpl let-hide>
                    <div class="text-center">
                      Repeating
                      <div class="text-invert-light">{{ schedule.cadence | titlecase }}</div>
                    </div>
                  </ng-template>
                </ng-container>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="time">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Time</th>
            <td mat-cell *matCellDef="let schedule">
              {{ schedule.scheduledDate | customAmPmFormatTimePipe : 'h:mm a' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="agenda">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Agenda</th>
            <td mat-cell *matCellDef="let schedule">
              {{ schedule.agendaName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="scribe">
            <th [hidden]="vm.isMobile" mat-header-cell mat-sort-header *matHeaderCellDef>Scribe</th>
            <td [hidden]="vm.isMobile" mat-cell *matCellDef="let schedule">
              <terra-avatar
                [initials]="schedule.scribeUser | userInitials"
                [src]="schedule.scribeUser?.metadata?.picture?.url"
                [inactive]="!schedule.scribeUser?.active"
                [ninetyContactCardHover]="schedule?.scribeId"
                [interactive]="true" />
            </td>
          </ng-container>

          <ng-container matColumnDef="presenter">
            <th [hidden]="vm.isMobile" mat-header-cell mat-sort-header *matHeaderCellDef>Facilitator</th>
            <td [hidden]="vm.isMobile" mat-cell *matCellDef="let schedule">
              <terra-avatar
                [initials]="schedule.presenterUser | userInitials"
                [src]="schedule.presenterUser.metadata?.picture?.url"
                [inactive]="!schedule.presenterUser.active"
                [ninetyContactCardHover]="schedule.presenterId"
                [interactive]="true" />
            </td>
          </ng-container>

          <ng-container matColumnDef="launch">
            <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let schedule; let i = index">
              <ninety-button
                primary
                filled
                (click)="createMeeting(schedule)"
                class="long-button"
                id="launch-meeting-btn-{{ schedule._id }}"
                data-cy="launch-meeting-btn"
                [disabled]="schedule.scheduledDate | disableLaunchSchedule : vm.meetingInProgress">
                <terra-icon icon="monitor-play" size="20" />
                <span [hidden]="vm.isMobile">Start</span>
              </ninety-button>
            </td>
          </ng-container>

          <ng-container matColumnDef="contextMenu">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let schedule"
              class="context-menu-cell"
              (click)="onClickOpenContextMenu($event, schedule)">
              <terra-icon class="context-menu-icon" icon="more" />
            </td>
          </ng-container>

          <ng-container matColumnDef="endSpacer">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef class="end-spacer"></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr
            mat-row
            *matRowDef="let schedule; columns: columns"
            id="schedule-row-{{ schedule._id }}"
            (contextmenu)="onClickOpenContextMenu($event, schedule)"></tr>
        </table>

        <ninety-meeting-schedules-context-menu (delete)="onDelete($event)" (edit)="onEdit($event)" />
      </ng-container>
    </ninety-card>
  </div>
</ng-container>
