<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="to-dos" />
      <span data-cy="todo-language_card-title">{{ todo.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="todo">
      <terra-form-field>
        <terra-form-label>Page Name</terra-form-label>
        <terra-text-input
          formControlName="route"
          required
          [attr.aria-label]="todo.route + ' Page Name'"
          data-cy="todo-language_page-name-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('route') !== resetLanguage?.route">
            <terra-icon icon="reset" (click)="resetOne.emit('route')" data-cy="todo-language_page-name-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ todo.item }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="item"
          required
          [attr.aria-label]="todo.item"
          data-cy="todo-language_singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('item') !== resetLanguage?.item">
            <terra-icon icon="reset" (click)="resetOne.emit('item')" data-cy="todo-language_singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ todo.items }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="items"
          required
          [attr.aria-label]="todo.items"
          data-cy="todo-language_plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('items') !== resetLanguage?.items">
            <terra-icon icon="reset" (click)="resetOne.emit('items')" data-cy="todo-language_plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ todo.team }}</terra-form-label>
        <terra-text-input
          formControlName="team"
          required
          [attr.aria-label]="todo.team"
          data-cy="todo-language_team-to-do-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('team') !== resetLanguage?.team">
            <terra-icon icon="reset" (click)="resetOne.emit('team')" data-cy="todo-language_team-to-do-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ todo.personal }}</terra-form-label>
        <terra-text-input
          formControlName="personal"
          required
          [attr.aria-label]="todo.personal"
          data-cy="todo-language_private-to-do-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('personal') !== resetLanguage?.personal">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('personal')"
              data-cy="todo-language_private-to-do-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
    </div>
  </mat-card-content>
</mat-card>
