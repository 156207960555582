import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isToday } from 'date-fns';

@Pipe({
  name: 'todayOrDate',
  standalone: true,
})
export class TodayOrDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(date: Date | string, dateFormat: string): string {
    if (!date) return;

    const parsedDate = new Date(date);
    const currentYear = new Date().getFullYear();
    const dateYear = parsedDate.getFullYear();

    if (isToday(parsedDate)) return 'Today';

    if (dateYear !== currentYear) {
      return this.datePipe.transform(parsedDate, `${dateFormat} yyyy`);
    }

    return this.datePipe.transform(parsedDate, dateFormat);
  }
}
