<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="responsibilities" />
      <span data-cy="responsibilities-language_card-title">{{ responsibilities.route }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="acChart">
      <terra-form-field>
        <terra-form-label>Page Name</terra-form-label>
        <terra-text-input
          formControlName="route"
          required
          [attr.aria-label]="responsibilities.route + ' Page Name'"
          data-cy="responsibilities-language_page-name-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('route') !== resetLanguage?.route">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('route')"
              data-cy="responsibilities-language_page-name-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ responsibilities.seat }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="seat"
          required
          [attr.aria-label]="responsibilities.seat"
          data-cy="responsibilities-language_seat-singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('seat') !== resetLanguage?.seat">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('seat')"
              data-cy="responsibilities-language_seat-singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ responsibilities.seats }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="seats"
          required
          [attr.aria-label]="responsibilities.seats"
          data-cy="responsibilities-language_seat-plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('seats') !== resetLanguage?.seats">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('seats')"
              data-cy="responsibilities-language_seat-plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ responsibilities.seatHolder }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="seatHolder"
          required
          [attr.aria-label]="responsibilities.seatHolder"
          data-cy="responsibilities-language_seat-holder-singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('seatHolder') !== resetLanguage?.seatHolder">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('seatHolder')"
              data-cy="responsibilities-language_seat-holder-singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ responsibilities.seatHolders }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="seatHolders"
          required
          [attr.aria-label]="responsibilities.seatHolders"
          data-cy="responsibilities-language_seat-holder-plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('seatHolders') !== resetLanguage?.seatHolders">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('seatHolders')"
              data-cy="responsibilities-language_seat-holder-plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ responsibilities.rolesAndResponsibilities }}</terra-form-label>
        <terra-text-input
          formControlName="rolesAndResponsibilities"
          required
          [attr.aria-label]="responsibilities.rolesAndResponsibilities"
          data-cy="responsibilities-language_roles-and-responsibilities-input-field">
          <terra-input-suffix
            *ngIf="
              !readonly && getFormControlValue('rolesAndResponsibilities') !== resetLanguage?.rolesAndResponsibilities
            ">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('rolesAndResponsibilities')"
              data-cy="responsibilities-language_roles-and-responsibilities-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ responsibilities.visionary }}</terra-form-label>
        <terra-text-input
          formControlName="visionary"
          required
          [attr.aria-label]="responsibilities.visionary"
          data-cy="responsibilities-language_visionary-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('visionary') !== resetLanguage?.visionary">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('visionary')"
              data-cy="responsibilities-language_visionary-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ responsibilities.integrator }}</terra-form-label>
        <terra-text-input
          formControlName="integrator"
          required
          [attr.aria-label]="responsibilities.integrator"
          data-cy="responsibilities-language_operator-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('integrator') !== resetLanguage?.integrator">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('integrator')"
              data-cy="responsibilities-language_operator-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ responsibilities.chart }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="chart"
          required
          [attr.aria-label]="responsibilities.chart"
          data-cy="responsibilities-language_chart-singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('chart') !== resetLanguage?.chart">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('chart')"
              data-cy="responsibilities-language_chart-singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ responsibilities.charts }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="charts"
          required
          [attr.aria-label]="responsibilities.charts"
          data-cy="responsibilities-language_chart-plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('charts') !== resetLanguage?.charts">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('charts')"
              data-cy="responsibilities-language_chart-plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
    </div>
  </mat-card-content>
</mat-card>
