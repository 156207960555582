import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { DirectoryLanguage } from '../../../../models/language/custom-language';

@Component({
  selector: 'ninety-directory-language',
  templateUrl: './directory-language.component.html',
  styles: [],
})
export class DirectoryLanguageComponent {
  @Input() directory: DirectoryLanguage;
  @Input() resetLanguage: DirectoryLanguage;
  @Input() readonly: boolean;
  @Input() form: FormGroup;

  @Output() resetOne = new EventEmitter<keyof DirectoryLanguage>();

  getFormControlValue(formControlName: string): string {
    return this.form.get('directory').value[formControlName];
  }
}
