import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ScorecardLanguage } from '../../../../models/language/custom-language';

@Component({
  selector: 'ninety-scorecard-language',
  templateUrl: './scorecard-language.component.html',
  styles: [],
})
export class ScorecardLanguageComponent {
  @Input() scorecard: ScorecardLanguage;
  @Input() resetLanguage: ScorecardLanguage;
  @Input() readonly: boolean;
  @Input() form: FormGroup;

  @Output() resetOne = new EventEmitter<keyof ScorecardLanguage>();

  getFormControlValue(formControlName: string): string {
    return this.form.get('scorecard').value[formControlName];
  }
}
