import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { MazMessage } from '@ninety/getting-started/maz/models/maz-message';
import { MazMessageSender } from '@ninety/getting-started/maz/models/maz-message-sender.enum';
import { UserAvatarInfo } from '@ninety/ui/legacy/state/app-entities/users/users-state.model';

import { MazFeedbackFlag } from '../../models/maz-feedback-flag.enum';

@Component({
  selector: 'ninety-maz-message',
  templateUrl: './maz-message.component.html',
  styleUrls: ['./maz-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MazMessageComponent {
  feedbackText: string;
  @Output() toggleMazFeedbackFlag = new EventEmitter<void>();
  @Output() closeMazAndOpenIntercom = new EventEmitter<void>();
  @Output() submitFeedback = new EventEmitter<string>();
  @Input() avatarInfo?: UserAvatarInfo;
  @Output() feedbackClosed = new EventEmitter<void>();
  showFeedback = false;
  protected readonly MazMessageSender = MazMessageSender;
  protected readonly MazFeedbackFlag = MazFeedbackFlag;

  private _message: MazMessage;

  get message(): MazMessage {
    return this._message;
  }

  @Input() set message(message: MazMessage) {
    this._message = message;
    this.feedbackText = message.feedback?.text;
  }

  toggleFlag(): void {
    this.showFeedback = this.message.feedback?.flag !== MazFeedbackFlag.incorrect;
    this.toggleMazFeedbackFlag.emit();
  }

  closeFeedback(): void {
    this.showFeedback = false;
    this.feedbackClosed.emit();
  }

  onSubmitFeedback(): void {
    this.submitFeedback.emit(this.feedbackText);
    this.closeFeedback();
  }
}
