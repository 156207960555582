import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { TerraIconModule } from '@ninety/terra';

import { CardModule } from '../../../cards/card.module';

@Component({
  selector: 'ninety-compare-plans',
  standalone: true,
  imports: [CommonModule, CardModule, MatExpansionModule, TerraIconModule],
  templateUrl: './compare-plans.component.html',
  styleUrls: ['./compare-plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparePlansComponent {}

export interface Plan {
  name: string;
  description: string;
  price: number;
  buttonLabel: string;
  features: string[];
}
