import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { catchError, concatMap, filter, iif, map, of, switchMap, tap } from 'rxjs';

import { CreateDialogService } from '@ninety/_layouts/services/create-dialog.service';
import { DetailViewActions } from '@ninety/detail-view/_state/detail-view.actions';
import { HeadlineService } from '@ninety/headlines/_shared/services/headline.service';
import { MeetingService } from '@ninety/meeting/_shared/services/meeting.service';
import { LocalStorageService } from '@ninety/ui/legacy/core/services/storage.service';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { OrdinalOrUserOrdinalUpdate } from '@ninety/ui/legacy/shared/models/_shared/ordinal-or-user-ordinal-update';
import { NinetyFeatures } from '@ninety/ui/legacy/shared/models/company/company-pricing-tiers';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { FromLinkedItem, LinkedItemTypeEnum } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { CascadingMessage } from '@ninety/ui/legacy/shared/models/meetings/cascading-message';
import { HeadlineMessageType } from '@ninety/ui/legacy/shared/models/meetings/headline-message-type';
import { selectCurrentUser } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectFeatureEnabled } from '@ninety/ui/legacy/state/index';

import { HeadlinesStateActions } from '../headlines/headlines-state.actions';

import { CascadingMessagesStateActions } from './cascading-messages-state.actions';
import { CascadingMessageStateKey } from './cascading-messages-state.model';
import * as cascadingMessagesSelectors from './cascading-messages-state.selectors';

@Injectable()
export class CascadingMessagesStateEffects {
  static readonly source = 'CascadingMessagesStateEffects';

  constructor(
    private actions$: Actions,
    private headlineServiceV2: HeadlineService,
    private localStorageService: LocalStorageService,
    private store: Store,
    private createDialogService: CreateDialogService,
    private meetingService: MeetingService
  ) {}

  paginationChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.paginationChange),
        tap(({ size }) => this.localStorageService.set(`${CascadingMessageStateKey}.pagination.size`, size))
      ),
    { dispatch: false }
  );

  sortChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.sortChange),
        tap(({ sort }) => {
          if (sort.direction) {
            //direction is null when sort cycle is complete
            this.localStorageService.set(`${CascadingMessageStateKey}.sort`, sort);
          } else {
            this.localStorageService.delete(`${CascadingMessageStateKey}.sort`);
          }
        })
      ),
    { dispatch: false }
  );

  clearSort$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.clearSort),
        tap(_ => this.localStorageService.delete(`${CascadingMessageStateKey}.sort`))
      ),
    { dispatch: false }
  );

  broadcastOnSortChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.sortChange),
      concatLatestFrom(() => [
        this.store.select(cascadingMessagesSelectors.selectFilterByTeamId),
        this.meetingService.currentMeeting$,
        this.store.select(selectCurrentUser),
      ]),
      filter(
        ([, , currentMeeting, currentUser]) =>
          !currentUser.isObserver && currentMeeting?.presenterUserId === currentUser._id
      ),
      map(([{ sort }, teamId]) =>
        CascadingMessagesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.sort,
            document: {
              listType: 'cascaded',
              field: sort.field as string,
              currentTeamId: teamId,
              sortDirection: sort.direction,
              screen: 'headlines',
              //TODO NEXT: handle conclude when refactored. maybe even skip this for conclude as lists are combined,
              //because lists are combined on conclude and we save sort in local storage
            },
          },
        })
      )
    )
  );

  openCreateDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.openCreateDialog),
        switchMap(() => this.createDialogService.open({ itemType: ItemType.cascadedMessage }))
      ),
    { dispatch: false }
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CascadingMessagesStateActions.filterByTeam,
        CascadingMessagesStateActions.sortChange,
        CascadingMessagesStateActions.paginationChange,
        CascadingMessagesStateActions.showArchived,
        CascadingMessagesStateActions.search,
        //archive is done with a headlines effect for cms as well
        HeadlinesStateActions.archiveAllCompletedSuccess
      ),
      concatLatestFrom(() => [this.store.select(selectFeatureEnabled(NinetyFeatures.cascadingMessages))]),
      filter(([_, enabled]) => enabled),
      concatLatestFrom(() => [this.store.select(cascadingMessagesSelectors.selectGetCascadingMessageQueryParams)]),
      switchMap(([_, params]) =>
        this.headlineServiceV2.getCascadingMessagesByTeamId(params).pipe(
          map(cascadingMessages =>
            CascadingMessagesStateActions.getSuccess({
              cascadingMessages,
              meeting: cloneDeep(this.meetingService.currentMeeting),
            })
          ),
          catchError((error: unknown) => of(CascadingMessagesStateActions.getFailed({ error })))
        )
      )
    )
  );

  setSelectedCascadingMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.getSuccess),
      concatLatestFrom(() => [this.store.select(cascadingMessagesSelectors.selectCascadingMessageIdFromDetailRoute)]),
      filter(([_, cascadingMessageId]) => !!cascadingMessageId),
      map(([_, cascadingMessageId]) => CascadingMessagesStateActions.setSelected({ selectedId: cascadingMessageId }))
    )
  );

  openInDetailView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.openInDetailView),
      map(action =>
        DetailViewActions.opened({
          config: { pathParts: [DetailType.cascadingMessage, action.cascadingMessageId] },
          source: 'Cascading Message V2 Selected',
        })
      )
    )
  );

  clearSelectedOnClosed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailViewActions.closed),
      filter(({ itemType }) => this.isCascadingMessageAction(itemType)),
      map(() => CascadingMessagesStateActions.clearSelected())
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.update),
      concatMap(({ update }) =>
        this.headlineServiceV2.updateHeadline(update.id as string, update.changes).pipe(
          map(_ => CascadingMessagesStateActions.updateSuccess({ update })),
          catchError((error: unknown) => of(CascadingMessagesStateActions.updateFailed({ error })))
        )
      )
    )
  );

  //TODO NEXT: remove/refactor when meeting is moved to store
  updateCascadedMessagesDocsInMeeting$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.updateSuccess, CascadingMessagesStateActions.setUserSuccess),
        filter(_ => this.meetingService.currentMeeting?.inProgress),
        tap(({ update }) => {
          const cascadingMessageDocs = [...(this.meetingService.currentMeeting.cascadingMessageDocs || [])];
          const indexCm = cascadingMessageDocs.findIndex(h => h._id === update.id);
          if (indexCm >= 0) {
            cascadingMessageDocs[indexCm] = { ...cascadingMessageDocs[indexCm], ...update.changes };
            this.meetingService.setCurrentMeeting({ ...this.meetingService.currentMeeting, cascadingMessageDocs });
          }

          const doneHeadlineDocs = [...(this.meetingService.currentMeeting.doneHeadlineDocs || [])];
          const indexH = doneHeadlineDocs.findIndex(h => h._id === update.id);
          if (indexH >= 0) {
            doneHeadlineDocs[indexH] = { ...doneHeadlineDocs[indexH], ...update.changes };
            this.meetingService.setCurrentMeeting({ ...this.meetingService.currentMeeting, doneHeadlineDocs });
          }
        })
      ),
    { dispatch: false }
  );

  broadcastOnUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.updateSuccess),
      map(({ update }) =>
        CascadingMessagesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.headline,
            document: { ...update.changes, _id: update.id as string },
            isCascadingMessage: true,
          },
        })
      )
    )
  );

  setTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.setTeam),
      concatMap(({ id, teamId }) =>
        this.headlineServiceV2.updateHeadline(id, { teamId }).pipe(
          map(_ => CascadingMessagesStateActions.setTeamSuccess({ id, teamId })),
          catchError((error: unknown) => of(CascadingMessagesStateActions.setTeamFailed({ error })))
        )
      )
    )
  );

  broadcastOnSetTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.setTeamSuccess),
      map(({ id, teamId }) =>
        CascadingMessagesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.headline,
            document: { teamId, _id: id },
            isCascadingMessage: true,
          },
        })
      )
    )
  );

  setUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.setUser),
      concatMap(({ id, ownedByUserId }) =>
        this.headlineServiceV2.updateHeadline(id, { ownedByUserId }).pipe(
          map(update =>
            CascadingMessagesStateActions.setUserSuccess({
              update: { id, changes: { ownedByUserId: update.ownedByUserId, ownedByUser: update.ownedByUser } },
            })
          ),
          catchError((error: unknown) => of(CascadingMessagesStateActions.setUserFailed({ error })))
        )
      )
    )
  );

  broadcastOnSetUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.setUserSuccess),
      map(({ update }) =>
        CascadingMessagesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.headline,
            document: { ...update.changes, _id: update.id as string },
            isCascadingMessage: true,
          },
        })
      )
    )
  );

  setArchived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.setArchived),
      concatMap(({ id, archived }) => this.headlineServiceV2.setArchived(id, archived)),
      map(cascadingMessage => CascadingMessagesStateActions.setArchivedSuccess({ cascadingMessage })),
      catchError((error: unknown) => of(CascadingMessagesStateActions.setArchivedFailed({ error })))
    )
  );

  broadcastSetArchivedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.setArchivedSuccess),
      map(({ cascadingMessage }) =>
        CascadingMessagesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.archive,
            document: cascadingMessage,
            isCascadingMessage: true,
          },
        })
      )
    )
  );

  setCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.setCompleted),
      concatMap(({ id, completed }) =>
        this.headlineServiceV2.setCompleted(id, completed, this.meetingService.currentMeeting?._id).pipe(
          map(update => {
            if (!update.isDone) {
              //remove isDone meta fields by setting them to undefined
              update.completedDate = undefined;
              update.completedBy = undefined;
              update.completedInMeeting = undefined;
            }
            return CascadingMessagesStateActions.setCompletedSuccess({ update: { id, changes: update } });
          }),
          catchError((error: unknown) => of(CascadingMessagesStateActions.setCompletedFailed({ error })))
        )
      )
    )
  );

  setCompletedInMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.setCompletedSuccess),
      filter(_ => this.meetingService.currentMeeting?.inProgress),
      concatMap(({ update }) =>
        iif(
          () => update.changes.isDone,
          this.meetingService.addDoneHeadline(update.id as string).pipe(map(_ => update)),
          this.meetingService.removeDoneHeadline(update.id as string).pipe(map(_ => update))
        ).pipe(
          map(update => CascadingMessagesStateActions.setCompletedInMeetingSuccess({ update })),
          catchError((error: unknown) => of(CascadingMessagesStateActions.setCompletedInMeetingFailed({ error })))
        )
      )
    )
  );

  broadcastSetCompletedInMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.setCompletedInMeetingSuccess),
      map(({ update }) =>
        CascadingMessagesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.headline,
            document: { ...update.changes, _id: update.id as string },
            isCascadingMessage: true,
          },
        })
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.delete),
      concatMap(({ id }) =>
        this.headlineServiceV2.deleteHeadline(id).pipe(
          map(_ => CascadingMessagesStateActions.deleteSuccess({ id })),
          catchError((error: unknown) => of(CascadingMessagesStateActions.deleteFailed({ error })))
        )
      )
    )
  );

  inMeetingRemoveCascadedMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.deleteSuccess),
        filter(_ => this.meetingService.currentMeeting?.inProgress),
        concatMap(({ id }) =>
          this.meetingService.removeCascadingMessage(id).pipe(
            map(() => CascadingMessagesStateActions.removeCascadedMessageSuccess()),
            catchError(() => of(CascadingMessagesStateActions.removeCascadedMessageFailure()))
          )
        )
      ),
    { dispatch: false }
  );

  //you can complete a CM in detail view, so we need to remove it from doneHeadlines as well
  //TODO NEXT: discuss feature
  inMeetingRemoveDoneHeadline$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.deleteSuccess),
        filter(_ => this.meetingService.currentMeeting?.inProgress),
        filter(({ id }) => this.meetingService.currentMeeting?.doneHeadlines.includes(id)),
        concatMap(({ id }) =>
          this.meetingService.removeDoneHeadline(id).pipe(
            map(() => CascadingMessagesStateActions.removeDoneHeadlineSuccess()),
            catchError(() => of(CascadingMessagesStateActions.removeDoneHeadlineFailure()))
          )
        )
      ),
    { dispatch: false }
  );

  broadcastOnDeleteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.deleteSuccess),
      map(({ id }) =>
        CascadingMessagesStateActions.broadcastMessage({
          message: {
            messageType: HeadlineMessageType.delete,
            document: id,
            isCascadingMessage: true,
          },
        })
      )
    )
  );

  closeDetailIfOpened$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.deleteLocal),
      concatLatestFrom(() => [this.store.select(cascadingMessagesSelectors.selectSelectedId)]),
      filter(([{ id }, selectedId]) => id === selectedId),
      map(_ => DetailViewActions.close())
    )
  );

  closeDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CascadingMessagesStateActions.delete,
        CascadingMessagesStateActions.setArchived,
        CascadingMessagesStateActions.clearSelected,
        CascadingMessagesStateActions.setTeam
      ),
      map(() => DetailViewActions.close())
    )
  );

  private isCascadingMessageAction(itemType: DetailType) {
    return itemType === DetailType.cascadingMessage;
  }

  cascade$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.cascade),
        switchMap(({ cascadingMessage }) =>
          this.createDialogService.open({
            item: { ...cascadingMessage, isCascadedMessage: true },
            itemType: ItemType.cascadedMessage,
          })
        )
      ),
    {
      dispatch: false,
    }
  );

  createIssue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.createIssue),
        concatMap(({ cascadingMessage }) => {
          const createdFrom: FromLinkedItem = {
            id: cascadingMessage._id,
            type: LinkedItemTypeEnum.cascadingMessage,
          };
          return this.createDialogService.open({
            item: {
              title: cascadingMessage.title,
              description: cascadingMessage.description,
              comments: cascadingMessage.comments,
              attachments: cascadingMessage.attachments,
              userId: cascadingMessage.ownedByUserId,
            },
            itemType: ItemType.issue,
            ...{ createdFrom },
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  createTodo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.createTodo),
        concatMap(({ cascadingMessage }) => {
          const createdFrom: FromLinkedItem = {
            id: cascadingMessage._id,
            type: LinkedItemTypeEnum.cascadingMessage,
          };
          return this.createDialogService.open({
            item: {
              title: cascadingMessage.title,
              description: cascadingMessage.description,
              comments: cascadingMessage.comments,
              attachments: cascadingMessage.attachments,
              userId: cascadingMessage.ownedByUserId,
            },
            itemType: ItemType.todo,
            ...{ createdFrom },
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  createHeadline$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.createHeadline),
        concatMap(({ cascadingMessage }) => {
          const createdFrom: FromLinkedItem = {
            id: cascadingMessage._id,
            type: LinkedItemTypeEnum.cascadingMessage,
          };
          return this.createDialogService.open({
            item: {
              title: cascadingMessage.title,
              description: cascadingMessage.description,
              comments: cascadingMessage.comments,
              attachments: cascadingMessage.attachments,
              userId: cascadingMessage.ownedByUserId,
            },
            itemType: ItemType.headline,
            ...{ createdFrom },
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  createRock$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.createRock),
        concatMap(({ cascadingMessage }) => {
          const createdFrom: FromLinkedItem = {
            id: cascadingMessage._id,
            type: LinkedItemTypeEnum.cascadingMessage,
          };
          return this.createDialogService.open({
            item: {
              title: cascadingMessage.title,
              description: cascadingMessage.description,
              comments: cascadingMessage.comments,
              attachments: cascadingMessage.attachments,
              userId: cascadingMessage.ownedByUserId,
            },
            itemType: ItemType.rock,
            ...{ createdFrom },
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  updateOrdinals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CascadingMessagesStateActions.updateOrdinals),
      concatLatestFrom(() => [
        this.store.select(cascadingMessagesSelectors.selectCascadingMessages),
        this.store.select(cascadingMessagesSelectors.selectPagination),
        this.store.select(cascadingMessagesSelectors.selectFilterBy),
        this.store.select(cascadingMessagesSelectors.selectSort),
      ]),
      map(([{ previousIndex, currentIndex }, cms, pagination, filterBy, sort]) => {
        const start = Math.min(previousIndex, currentIndex) + pagination.index * pagination.size;
        const stop = Math.max(previousIndex, currentIndex) + pagination.index * pagination.size;

        const cmsWithNewOrdinals = cms.filter(t => t.ordinal >= start && t.ordinal <= stop);

        this.headlineServiceV2
          .updateOrdinals(
            cmsWithNewOrdinals.map(
              (cm: CascadingMessage) => new OrdinalOrUserOrdinalUpdate(cm._id, cm.ordinal, 'ordinal')
            ),
            'ordinal',
            filterBy.teamId,
            false,
            filterBy.inMeetingId,
            sort.field,
            sort.direction
          )
          .subscribe();

        return CascadingMessagesStateActions.clearSort();
      })
    )
  );

  broadcastMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.broadcastMessage),
        tap(({ message }) => {
          this.headlineServiceV2.broadcastMessage(message);
        })
      ),
    { dispatch: false }
  );
}
