import { Pipe, PipeTransform } from '@angular/core';
import parsePhoneNumber from 'libphonenumber-js';

@Pipe({
  name: 'prettyPhoneNumber',
  standalone: true,
})
export class PrettyPhoneNumberPipe implements PipeTransform {
  transform(number: string, callingCode = '', formatNational = true): string {
    return PrettyPhoneNumberPipe.transform(number, callingCode, formatNational);
  }

  static transform(number: string, callingCode = '', formatNational = true): string {
    try {
      const num = parsePhoneNumber(`${callingCode}${number}`);
      return formatNational ? num.formatNational() : num.formatInternational();
    } catch (e) {
      return number;
    }
  }
}
