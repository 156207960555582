import { BidiModule } from '@angular/cdk/bidi';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraSearchInputModule } from '../inputs/terra-search-input/terra-search-input.module';
import { TerraDividerModule } from '../terra-divider';
import { TerraIconModule } from '../terra-icon';
import { TerraOptgroupModule } from '../terra-optgroup';
import { TerraOptionModule } from '../terra-option';

import { TerraSelectComponent } from './terra-select.component';
import { TerraSelectSearchComponent, TerraSelectTriggerComponent } from './terra-select.slots';

@NgModule({
  imports: [
    CommonModule,
    TerraSelectComponent,
    TerraSelectSearchComponent,
    TerraSearchInputModule,
    TerraIconModule,
    TerraOptionModule,
    TerraDividerModule,
    TerraSelectTriggerComponent,
    TerraOptgroupModule,
  ],
  exports: [
    TerraSelectComponent,
    TerraSelectSearchComponent,
    TerraIconModule,
    TerraSearchInputModule,
    BidiModule,
    TerraOptionModule,
    TerraDividerModule,
    TerraSelectTriggerComponent,
    TerraOptgroupModule,
  ],
})
export class TerraSelectModule {}
