import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
  PersonMetadata,
  myersBriggsOptions,
  MyersBriggs,
  StrengthFinder,
} from '@ninety/ui/legacy/shared/models/_shared/person-metadata';

@Component({
  selector: 'ninety-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricsComponent implements OnInit {
  @Input() metadata: PersonMetadata;

  @Output() updateMetadata = new EventEmitter<Partial<PersonMetadata>>();

  updateMetadata$ = new Subject<void>();
  myersBriggsOptions = myersBriggsOptions;
  kolbeNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateMetadata$.pipe(debounceTime(1000)).subscribe({ next: () => this.updateMetadata.emit(this.metadata) });
  }

  emitUpdate(): void {
    this.updateMetadata.emit(this.metadata);
  }

  debounceUpdate(): void {
    this.updateMetadata$.next();
  }

  updateMyersBriggs(mbString: string): void {
    const mb = mbString.split('');
    this.metadata.myersBriggs = {
      attitude: mb[0],
      perceivingFunction: mb[1],
      judgingFunction: mb[2],
      lifestylePreference: mb[3],
    };
    this.emitUpdate();
  }

  compareMyersBriggs(option: string, mb: MyersBriggs): boolean {
    return option === `${mb.attitude}${mb.perceivingFunction}${mb.judgingFunction}${mb.lifestylePreference}`;
  }

  deleteStrength(i: number, whichFive: keyof StrengthFinder): void {
    this.metadata.strengthFinder[whichFive].splice(i, 1);
    this.cdr.markForCheck();
    this.updateMetadata.emit(this.metadata);
  }

  addStrength(whichFive: keyof StrengthFinder): void {
    if (this.metadata.strengthFinder[whichFive].length >= 5) return;
    this.metadata.strengthFinder[whichFive].push('');
    this.cdr.markForCheck();
    this.updateMetadata.emit(this.metadata);
  }

  trackByIndex(i: number): number {
    return i;
  }
}
