import { Directive, TemplateRef } from '@angular/core';

/** @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket. */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ng-template.[gridItemTemplateDefault]',
})
export class GridItemTemplateDefaultDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}
