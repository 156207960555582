import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { Item } from '@ninety/ui/legacy/shared/models/_shared/item';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { FromLinkedItem } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';

import { CreateDialogService } from './create-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class NewItemService {
  constructor(private datePipe: DatePipe, private createDialogService: CreateDialogService) {}

  openUniversalCreate(newItem: Item, itemType: ItemType, createdFrom: FromLinkedItem) {
    const item = {
      userId: newItem.userId,
      title: newItem.title,
      description: newItem.description + this.getMilestoneText(newItem),
      teamId: newItem.teamId,
      isCascadedMessage: itemType === ItemType.cascadedMessage,
      attachments: newItem.attachments,
      comments: newItem.comments,
    };

    this.createDialogService.open({ item, itemType, ...{ createdFrom } }).subscribe();
  }

  getMilestoneText(rock: Rock): string {
    if (!rock.milestones || !rock.milestones.length) return '';
    const header = '\n\n<h3>Milestones:</h3>\n';
    const milestoneList = rock.milestones.map(m => {
      const dueDate = this.datePipe.transform(m.dueDate, 'M/d/yy');
      const description = m.description ? `<p class="ql-indent-1">${m.description}</p>` : '';
      return `<p>${dueDate} - <strong>${m.isDone ? 'DONE' : 'NOT DONE'}</strong> - ${m.title}</p>${description} `;
    });
    return header + milestoneList.join('');
  }
}
