import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IssueLanguage } from '../../../../models/language/custom-language';

@Component({
  selector: 'ninety-issue-language',
  templateUrl: './issue-language.component.html',
  styles: [],
})
export class IssueLanguageComponent {
  @Input() issue: IssueLanguage;
  @Input() resetLanguage: IssueLanguage;
  @Input() readonly: boolean;
  @Input() form: FormGroup;

  @Output() resetOne = new EventEmitter<keyof IssueLanguage>();

  getFormControlValue(formControlName: string): string {
    return this.form.get('issue').value[formControlName];
  }
}
