/** @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket. */
export interface ResetLayoutOptions {
  /**
   * When true, any in-memory changes to the VTO are discarded.
   * Default: false
   */
  force?: boolean;

  /**
   * When true, a cache event will also cause the update event to fire.
   * Default: true
   */
  emitUpdate?: boolean;
}

export namespace ResetLayoutOptions {
  export function Default(): ResetLayoutOptions {
    return { force: false, emitUpdate: true };
  }
}
