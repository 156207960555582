import { createReducer, on } from '@ngrx/store';

import {
  constructSharedReducersWithDistinctActions,
  SharedReducerFunctions,
} from '@ninety/todos/_state/_shared/todo-state.shared.reducers';
import {
  PersonalTabTodosActions,
  PersonalTodoActions,
  PersonalTodoInlineActions,
} from '@ninety/todos/_state/personal/personal-todo.actions';
import { initialPersonalTodoState, PersonalTodoState } from '@ninety/todos/_state/personal/personal-todo.model';
import { CompletedSelectValue } from '@ninety/ui/legacy/components/inputs/selects/completed-select/completed-select.component';

const updateTypeToShowTo =
  (typeOfTodosToShow: CompletedSelectValue) =>
  (state): PersonalTodoState => ({ ...state, typeOfTodosToShow, loading: true });

export const personalTodoReducer = createReducer<PersonalTodoState>(
  initialPersonalTodoState,
  ...constructSharedReducersWithDistinctActions<PersonalTodoState>('personal'),
  on(PersonalTodoActions.getManySuccess, SharedReducerFunctions.updateTodosOnGETManySuccess),
  on(PersonalTodoActions.getManyFailure, SharedReducerFunctions.setErrorOnApiFailure),
  on(PersonalTodoActions.selectComplete, updateTypeToShowTo(CompletedSelectValue.complete)),
  on(PersonalTodoActions.selectIncomplete, updateTypeToShowTo(CompletedSelectValue.incomplete)),
  on(PersonalTodoActions.toggleCompleted, (state: PersonalTodoState, action) => {
    const addToList =
      // Viewing completed todos and the _todo was just completed
      (state.typeOfTodosToShow === CompletedSelectValue.complete && action.isCompleted) ||
      // Viewing incomplete todos and the _todo was just marked as incomplete
      (state.typeOfTodosToShow === CompletedSelectValue.incomplete && !action.isCompleted);

    /*
       Note, the addToList branch is prefaced on the fact that the detail dispatches the entire _todo with
       toggleCompleted. It does not matter that the inline update only dispatches a partial _todo during toggleCompleted
       as you will never need to add a _todo to a list from an inline toggleCompleted update, you will only ever remove
       (you can only see one type of list at a time).
     */
    const todos = addToList ? [...state.todos, action.todo] : state.todos.filter(t => t._id !== action.todo._id);
    return {
      ...state,
      todos,
      todoCount: addToList ? state.todoCount + 1 : state.todoCount - 1,
    };
  }),
  on(PersonalTodoActions.updateSuccess, PersonalTodoActions.updateUserSuccess, (state, { todo: updateResponse }) => {
    const todo = state.todos.find(t => t._id === updateResponse._id);
    let todos = [...state.todos];
    if (todo && (!updateResponse.isPersonal || todo.userId !== updateResponse.userId)) {
      todos = todos.filter(t => t._id !== todo._id);
    }

    return {
      ...state,
      todos,
    };
  }),
  on(PersonalTodoActions.addToPersonal, (state, { todo: updateResponse }) => {
    const todo = state.todos.find(t => t._id === updateResponse._id);
    let todos = [...state.todos];
    if (!todo && updateResponse.isPersonal) {
      todos = [...todos, updateResponse];
    }

    return {
      ...state,
      todos,
    };
  }),
  on(PersonalTodoActions.removeFromPersonal, (state, { todo: updateResponse }) => {
    const todo = state.todos.find(t => t._id === updateResponse._id);
    let todos = [...state.todos];
    if (todo && !updateResponse.isPersonal) {
      todos = todos.filter(t => t._id !== todo._id);
    }

    return {
      ...state,
      todos,
    };
  }),
  on(
    PersonalTodoActions.resetState,
    (_state, { overrides }): PersonalTodoState => ({ ...initialPersonalTodoState, ...overrides })
  ),
  /** Updating reducer optimistically for TeamTodoActions.update which is Partial<Todo>  */
  on(PersonalTodoActions.update, PersonalTodoActions.updateInline, (state, { id, todo }): PersonalTodoState => {
    const todos = state.todos.map(t => {
      if (t._id == id) {
        return Object.assign({}, t, todo);
      }
      return t;
    });

    return {
      ...state,
      todos,
      loading: false,
    };
  }),

  //NEXT: there is a edge case when updating a series todo title inline, the opened todo title is not updated ...
  // if the update is done for another todo in the series
  // We need to add a new action for inline updates in series, do team todos as well
  on(PersonalTodoActions.updateDisplayedTodosInSeries, (state, { todo }) => {
    const todos = state.todos.reduce((acc, t) => {
      //update some fields for todos in series &
      //avoid updating opened todo again and/or causing jumping cursor
      //NEXT: also filter by user personal to team switch, pass current user id from effect(backlog ticket)
      if (t.seriesId === todo.seriesId && t._id !== state.selectedTodoId) {
        if (!todo.isPersonal) {
          //remove any series todos that are now not part of the list
          //they are now a team todo
          return acc;
        }

        return [
          ...acc,
          Object.assign({}, t, {
            userId: todo.userId,
            user: todo.user,
            title: todo.title,
            description: todo.description,
            teamId: todo.teamId,
            isPersonal: todo.isPersonal,
          }),
        ];
      }
      return [...acc, t];
    }, []);

    return { ...state, todos };
  }),

  on(
    PersonalTodoInlineActions.addOne,
    (state, { todo }): PersonalTodoState => ({
      ...state,
      todos: [...state.todos, todo],
      listControlsDisabled: true,
      focusOnInlineAddTodo: false,
    })
  ),
  on(PersonalTodoInlineActions.cancelAddOne, (state): PersonalTodoState => {
    const todosWithIds = state.todos.filter(todo => todo._id);
    return {
      ...state,
      todos: [...todosWithIds],
      listControlsDisabled: false,
      focusOnInlineAddTodo: false,
    };
  }),
  on(PersonalTodoInlineActions.createOneSuccess, (state, { response }): PersonalTodoState => {
    const todosWithIds = state.todos.filter(todo => todo._id);
    const newTodo = response.todos[0];
    return {
      ...state,
      todos: [...todosWithIds, newTodo],
      todoCount: state.todoCount + 1,
      listControlsDisabled: false,
      focusOnInlineAddTodo: true,
    };
  }),
  on(PersonalTodoInlineActions.createOneFailure, (state): PersonalTodoState => {
    const todosWithIds = state.todos.filter(todo => todo._id);
    return {
      ...state,
      todos: [...todosWithIds],
      listControlsDisabled: false,
      focusOnInlineAddTodo: false,
    };
  }),
  on(PersonalTodoActions.addManySuccess, (state, { response }): PersonalTodoState => {
    const newTodos = [...state.todos, ...response.todos];
    return {
      ...state,
      todos: newTodos,
      todoCount: newTodos.length,
    };
  }),
  on(
    PersonalTabTodosActions.init,
    (state): PersonalTodoState => ({
      ...state,
      ordinalKey: 'userOrdinal',
    })
  ),
  on(
    PersonalTabTodosActions.hydrate,
    (state, { pageSize, sort }): PersonalTodoState => ({
      ...state,
      pageSize: pageSize || state.pageSize,
      sortDirection: sort?.direction ?? null,
      sortField: sort?.direction ? sort.field : null,
    })
  ),
  on(
    PersonalTabTodosActions.updateOrdinalsSuccess,
    PersonalTabTodosActions.moveToBottom,
    PersonalTabTodosActions.moveToTop,
    (state): PersonalTodoState => ({
      ...state,
      sortDirection: null,
      sortField: null,
    })
  ),
  /** Attachments */
  on(PersonalTodoActions.attachmentUploadedSuccess, (state, { event }) => {
    let todos = [...state.todos];
    event.attachments.forEach(attachment => {
      const todo = todos.find(t => t._id === attachment.parentId);
      if (todo) {
        const update = Object.assign({}, todo, {
          _id: attachment.parentId,
          attachments: [...todo.attachments, attachment],
        });
        todos = SharedReducerFunctions.mergeUpdatedTodoIntoReferenceInList(state.todos, update);
      }
    });
    return {
      ...state,
      todos,
    };
  })
);
