<ninety-card>
  <terra-icon class="all-in-discount-frame" icon="percent-seal" size="40" />

  <div class="ninety-card-content">
    <div class="go-all-in-text">Go All In on Ninety and Take 50% Off</div>

    <div class="card-text-inner">
      We’re committed to being your partner in growth. If you’re all in to help your team focus, align and thrive, so
      are we. Add your entire company to Ninety and save 50%.
    </div>

    <button ninety-cm-button>Learn More</button>
  </div>

  <ninety-card-footer footer>
    <div class="card-text-bottom">
      <a href="#" class="representative-link">Click here</a> to speak to a Ninety representative
    </div>
  </ninety-card-footer>
</ninety-card>
