<mat-card [formGroup]="form">
  <mat-card-header>
    <mat-card-title>
      <terra-icon icon="future-goal" />
      <span data-cy="measurable-language_card-title">{{ measurable.items }}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="inputs-div" formGroupName="measurable">
      <terra-form-field>
        <terra-form-label>{{ measurable.item }} (singular):</terra-form-label>
        <terra-text-input
          formControlName="item"
          required
          [attr.aria-label]="measurable.item"
          data-cy="measurable-language_singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('item') !== resetLanguage?.item">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('item')"
              data-cy="measurable-language_singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ measurable.items }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="items"
          required
          [attr.aria-label]="measurable.items"
          data-cy="measurable-language_plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('items') !== resetLanguage?.items">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('items')"
              data-cy="measurable-language_plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ measurable.goal }} (singular)</terra-form-label>
        <terra-text-input
          formControlName="goal"
          required
          [attr.aria-label]="measurable.goal"
          data-cy="measurable-language_goal-singular-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('goal') !== resetLanguage?.goal">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('goal')"
              data-cy="measurable-language_goal-singular-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
      <terra-form-field>
        <terra-form-label>{{ measurable.goals }} (plural)</terra-form-label>
        <terra-text-input
          formControlName="goals"
          required
          [attr.aria-label]="measurable.goals"
          data-cy="measurable-language_goal-plural-input-field">
          <terra-input-suffix *ngIf="!readonly && getFormControlValue('goals') !== resetLanguage?.goals">
            <terra-icon
              icon="reset"
              (click)="resetOne.emit('goals')"
              data-cy="measurable-language_goal-plural-reset-button" />
          </terra-input-suffix>
        </terra-text-input>
      </terra-form-field>
    </div>
  </mat-card-content>
</mat-card>
