import { GridStackNode, GridStackPosition, GridStackWidget } from 'gridstack';

/**
 * Defines the x/y coordinates of the top left part of a widget, as well as its width and height.
 * @deprecated DEV-14518 Legacy global dashboard is deprecated. Replacement under development. See ticket.
 */
export type GridWidgetPosition = Omit<GridStackWidget, 'autoPosition' | 'resizeHandles' | 'content' | 'subGrid'>;

export namespace GridWidgetPosition {
  type DimensionKey = Extract<keyof GridWidgetPosition, 'w' | 'h'>;
  type MinDimensionKey = Extract<keyof GridWidgetPosition, 'minW' | 'minH'>;
  export const DIMENSION_TO_MIN_KEY: Readonly<Record<DimensionKey, MinDimensionKey>> = {
    w: 'minW',
    h: 'minH',
  };

  export function Default(id: string): GridWidgetPosition {
    return { id, w: 1, h: 1 };
  }

  /**
   * Sanitizes models that will be passed into GridStack. Any defined keys set to undefined in a GridStackWidget object
   * (ie {x: undefined} not {}) will produce GridStack errors.
   */
  export function sanitizeInput(config: GridWidgetPosition, opts: { columnCount: number }): GridWidgetPosition {
    const withoutFalseyValues: GridWidgetPosition = Object.entries(config).reduce((acc, [k, v]) => {
      if (!v && v !== 0) return acc;

      acc[k] = v;
      return acc;
    }, {});

    withoutFalseyValues.w = normalizeWidth(withoutFalseyValues.w, opts.columnCount);

    return withoutFalseyValues;
  }

  /** Sanitizes GridStackNode objects returned by GridStack and converts them to the Ninety GridWidgetPosition type. */
  export function sanitizeOutput(node: GridStackNode): GridWidgetPosition {
    const w = ensureWOrHArePresent('w', node);
    const h = ensureWOrHArePresent('h', node);

    const sanitized: GridWidgetPosition = { w, h };

    // Optimized pattern to pick
    const keys = ['x', 'y', 'minW', 'maxW', 'minH', 'maxH', 'locked', 'noMove', 'noResize', 'id'];
    for (const key of keys) {
      if (node[key] === undefined || node[key] === null) continue;
      sanitized[key] = node[key];
    }

    return sanitized;
  }

  /**
   * Ensures valid values of w/h are returned. Note, when minW/minH are defined AND w/h are the min, w/h is not
   * returned by GridStack. However, we want to store it in the DB for easier querying, so we ensure it exists here.
   */
  function ensureWOrHArePresent(key: DimensionKey, node: GridStackNode): number {
    if (node[key]) return node[key]; // Defined, return value

    const minKey = DIMENSION_TO_MIN_KEY[key];
    if (node[minKey]) return node[minKey]; // Used min, return it

    return 1; // Identity
  }

  export function equalByPosition(a: GridStackPosition, b: GridStackPosition) {
    if (!a && !b) return true;
    if (!a && b) return false;
    if (a && !b) return false;

    // Both are defined
    const keys: (keyof GridStackPosition)[] = ['x', 'y', 'w', 'h'];
    for (const key of keys) {
      if (a[key] !== b[key]) return false;
    }

    return true;
  }

  /**
   * There is some problem in Gridstack around rendering items with a column count greater than the current column count
   * limit. See https://traxion.atlassian.net/browse/DEV-3308 for more info.
   */
  export function normalizeWidth(width: number, max = 3): number {
    if (width === null || width === undefined) return 1;
    if (width <= 0) return 1;
    if (width > max) return max;
    return width;
  }
}
