import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, EMPTY, Observable, Subscription, catchError, filter, map, of, switchMap, tap } from 'rxjs';

import { IssueService } from '@ninety/issues/_shared/issue.service';
import { ChannelService } from '@ninety/ui/legacy/core/services/channel.service';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { HelperService } from '@ninety/ui/legacy/core/services/helper.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { WarningConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/warning-confirm-dialog.component';
import type {
  RealtimeMessage,
  ReceivedRealtimeMessage,
} from '@ninety/ui/legacy/shared/models/meetings/realtime-message';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { RockLevelCode } from '@ninety/ui/legacy/shared/models/rocks/rock-level-code';
import { CoreValue } from '@ninety/ui/legacy/shared/models/vto/core-value';
import { Vto } from '@ninety/ui/legacy/shared/models/vto/vto';
import { VtoLabels } from '@ninety/ui/legacy/shared/models/vto/vto-labels';
import { VtoMessageType } from '@ninety/ui/legacy/shared/models/vto/vto-message-type';
import { VtoCustomSectionSettings } from '@ninety/ui/legacy/shared/models/vto/vto-sections';
import { TeamSelectors } from '@ninety/ui/legacy/state/index';
import { extractValueFromStore } from '@ninety/ui/legacy/state/state-util';
import { VtoStateActions } from '@ninety/vto/_state/vto-state.actions';
import { BosDefaultSectionIdToTitleMap } from '@ninety/vto/logic/bos-default-section-title-extraction.logic';
import { enrichCustomVtoSections, enrichDefaultVtoSections } from '@ninety/vto/logic/sections-enrichment.logic';
import { VtoCascaderOpts, VtoCascaderService } from '@ninety/vto/services/vto-cascader.service';
import { VtoNormalizerService, VtoPair } from '@ninety/vto/services/vto-normalizer.service';

import { RockService } from '../../rocks/_shared/rock.service';

import { VtoGridManagedProperties } from './vto-grid-reducers.service';

@Injectable({
  providedIn: 'root',
})
export class VtoService {
  vtoApi = '/api/v4/Vto';
  vtoId: string;

  /**
   * @deprecated use teamVto$
   */
  teamVto: Vto;
  /**
   * @deprecated use sltVto$
   */
  sltVto: Vto;
  teamVto$ = new BehaviorSubject<Vto>(null);
  sltVto$ = new BehaviorSubject<Vto>(null);
  archivedVto$ = new BehaviorSubject<Vto>(null);
  viewingSltVto = false;
  toggleSltVto$ = new BehaviorSubject<string>(null);
  archivedDate: string;
  previouslyFetchedArchivedVtos: { [key: string]: Vto } = {};
  sltCompanyRocks: Rock[];
  sltTeamId: string;

  messageSubscription = new Subscription();
  channelId: string;
  shouldBroadcast = false;

  constructor(
    public stateService: StateService,
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private filterService: FilterService,
    private errorService: ErrorService,
    private datePipe: DatePipe,
    private helperService: HelperService,
    private issueService: IssueService,
    private rockService: RockService,
    private dialog: MatDialog,
    private channelService: ChannelService,
    private vtoNormalizerService: VtoNormalizerService,
    private vtoCascaderService: VtoCascaderService,
    private store: Store
  ) {
    this.stateService.currentCompanyUser$
      .pipe(
        filter(user => !!user?.company),
        tap(companyUser => {
          this.sltTeamId = companyUser.company.seniorLeadershipTeamId;
        })
      )
      .subscribe();
  }

  /**
   * Determines whether the actively viewed vto is cascaded
   * // TODO this ^^^^ isn't accurate, is it supposed to mean shared?
   */
  isCascaded() {
    const selectedTeamId = extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId);
    return this.viewingSltVto && selectedTeamId !== this.sltTeamId;
  }

  getVtoById(vtoId: string, skipLocal = true): Observable<Vto> {
    if (skipLocal) {
      return this.http
        .get<Vto>(`${this.vtoApi}/${vtoId}`)
        .pipe(switchMap(vto => this.vtoNormalizerService.getOptsAndNormalize(vto)));
    }

    switch (vtoId) {
      case this.teamVto?._id:
        return of(this.teamVto);
      case this.sltVto?._id:
        return of(this.sltVto);
      case this.archivedVto$.value?._id:
        return of(this.archivedVto$.value);
      default:
        return this.getVtoById(vtoId, true);
    }
  }

  // TODO fold sltOnly and Sinner into opts
  getTeamVtoAndSetSltVto(
    teamId: string,
    sltOnly = false,
    spinner = true,
    opts?: Partial<VtoCascaderOpts>
  ): Observable<Vto> {
    this.toggleSltVto(true);
    this.archivedVto$.next(null);

    if (!teamId || !this.stateService.company.seniorLeadershipTeamId) {
      return this.errorService.notify(
        new Error('No Senior Leadership Team set.'),
        'No Senior Leadership Team has been set. Please set a SLT in team settings or contact your admin.'
      );
    }

    const request = this.http.get<VtoPair>(`${this.vtoApi}/${teamId}/TeamAndSltVto`).pipe(
      switchMap((vtoPair: VtoPair) => this.vtoCascaderService.getOptsAndCascade(vtoPair, opts)),
      map(({ sltVto, teamVto }: VtoPair) => {
        this.sltVto = sltVto;
        this.sltVto$.next(sltVto);

        if (!sltOnly) {
          this.vtoId = teamVto._id;
          this.teamVto = teamVto;
          this.teamVto$.next(teamVto);
        }

        return teamVto;
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not get ${this.stateService.language.vto.item}.  Please try again.`)
      )
    );

    if (!spinner) return request;

    return this.spinnerService.spinWhile(request);
  }

  // TODO deprecate
  getCoreValues(): Observable<CoreValue[]> {
    return this.http
      .get<CoreValue[]>(`${this.vtoApi}/CoreValues`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e, `Could not get Core Values. Please try again.`)));
  }

  viewTeamVto(): void {
    this.archivedVto$.next(null);
  }

  toggleSltVto(shouldReset?: boolean) {
    if (shouldReset) {
      this.viewingSltVto = false;
    }

    this.toggleSltVto$.next(this.viewingSltVto ? this.sltTeamId : null);

    if (this.viewingSltVto) {
      this.getRocks().subscribe();
    }
  }

  getRocks() {
    this.rockService.retrievedRocks = false;
    this.sltCompanyRocks = [];

    const request = this.rockService.getRocksByTeamId(this.sltTeamId, false).pipe(
      tap((rocks: { [teamId: string]: Rock[] }) => {
        this.sltCompanyRocks = rocks[this.sltTeamId].filter(
          rock => rock.levelCode === RockLevelCode.companyAndDepartment || rock.levelCode === RockLevelCode.company
        );
        this.rockService.retrievedRocks = true;
      }),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not get Leadership team's ${this.stateService.language.rock.items}. Please try again.`
        )
      )
    );

    return this.spinnerService.spinWhile(request);
  }

  update(vtoId: string, update: Partial<Vto>, isSlt = false): Observable<Vto> {
    if (isSlt) {
      this.sltVto = { ...this.sltVto$.value, ...update };
      this.sltVto$.next({ ...this.sltVto$.value, ...update });
    }

    if (vtoId === this.teamVto$.value?._id) {
      this.teamVto = { ...this.teamVto$.value, ...update };
      this.teamVto$.next({ ...this.teamVto$.value, ...update });
    }

    return this.http.patch<Vto>(`${this.vtoApi}/${vtoId}`, update).pipe(
      tap(_ => {
        this.store.dispatch(VtoStateActions.vtoUpdated({ vtoId, vtoUpdate: cloneDeep(update) }));
        this.broadcastMessage({
          messageType: VtoMessageType.vto,
          document: Object.assign({ _id: vtoId }, update) as Vto,
        }).subscribe();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not update ${this.stateService.language.vto.item}.  Please try again.`)
      )
    );
  }

  updateSltSectionSettings(vto: Vto, managedProperties: VtoGridManagedProperties): Observable<Vto> {
    // Log any updates to the settings to try and track down VTO issues. Note, custom language is not used for "VISION"
    // because that would prevent easy lookup of this log in DataDog. Removing VISION would make it hard to distinguish
    // from other grids, such as the v2 My90 grid.
    console.log(
      `VISION LAYOUT UPDATE - to ${vto._id} - by ${this.stateService.companyId}.${this.stateService.currentUser._id}`,
      managedProperties
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { pinnacleTitles, ...otherUpdates } = managedProperties;
    const update: Partial<Vto> = { ...otherUpdates, ...Vto.pickPinnacleSections(vto) };

    return this.update(vto._id, update, true);
  }

  updateTeamLabelsInMemory(labels: VtoLabels, customSectionSettings: VtoCustomSectionSettings[]) {
    this.teamVto.labels = labels;

    customSectionSettings.forEach(update => {
      const fromTeam = this.teamVto.customSectionSettings.find(teamSection => teamSection._id === update._id);
      if (fromTeam) fromTeam.title = update.title;
    });

    this.teamVto$.next(this.teamVto);
  }

  /**
   * In both places this method is called, its expected that custom section that was deleted/hidden is not in the
   * updates' parameter. Thus, we don't need to splice or handle removing them in any way - by updating the entire object
   * we handle this.
   */
  applyInMemoryVtoSectionAndLabelUpdates(vto: Vto, updates: VtoGridManagedProperties) {
    // Enrich the updates with the other properties of the section from the VTO
    const parsedUpdates: Partial<Vto> = {
      ...updates,
      ...BosDefaultSectionIdToTitleMap.enrich(vto, updates.pinnacleTitles),
      customSectionSettings: enrichCustomVtoSections(updates.customSectionSettings, vto.customSectionSettings),
      sectionSettings: enrichDefaultVtoSections(updates.sectionSettings, vto.sectionSettings),
    };

    // Update this services state
    if (vto._id === this.sltVto?._id) {
      Object.entries(parsedUpdates).forEach(([k, v]) => (this.sltVto[k] = v));
      this.sltVto$.next(this.sltVto);
    }

    if (vto._id === this.teamVto?._id) {
      Object.entries(parsedUpdates).forEach(([k, v]) => (this.teamVto[k] = v));
      this.teamVto$.next(this.teamVto);
    }

    // If the vto passed in is not the same reference as either of these services managed vtos, update it too
    if (!(Object.is(vto, this.teamVto) || Object.is(vto, this.sltVto))) {
      Object.entries(parsedUpdates).forEach(([k, v]) => (vto[k] = v));
    }
  }

  archiveVtoCopy(vto = this.teamVto): void {
    vto.rocks = this.rockService.companyRocks || [];
    vto.issues = this.issueService.issues || [];

    const request = this.http.post<string>(`${this.vtoApi}/ArchiveVtoCopy`, vto).pipe(
      tap((archivedId: string) => {
        this.teamVto.archivedVtoIds
          ? this.teamVto.archivedVtoIds.unshift(archivedId)
          : (this.teamVto.archivedVtoIds = [archivedId]);
      }),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not archive a copy of this ${this.stateService.language.vto.item}.  Please try again.`
        )
      )
    );

    this.spinnerService.spinWhile(request).subscribe();
  }

  getArchivedVto(vtoId: string): void {
    if (this.previouslyFetchedArchivedVtos[vtoId])
      return this.setArchivedVto(this.previouslyFetchedArchivedVtos[vtoId]);

    const request = this.http.get<Vto>(`${this.vtoApi}/${vtoId}/ArchivedVto`).pipe(
      switchMap(vto => this.vtoNormalizerService.getOptsAndNormalize(vto)),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not get the archived ${this.stateService.language.vto.item}.  Please try again.`
        )
      )
    );

    this.spinnerService.spinWhile(request).subscribe({
      next: (vto: Vto) => {
        this.setArchivedVto(vto);
        this.previouslyFetchedArchivedVtos[vto._id] = vto;
      },
    });
  }

  setArchivedVto(vto: Vto): void {
    this.toggleSltVto(true);
    this.archivedDate = this.datePipe.transform(this.helperService.getCreatedDate(vto._id), 'shortDate');
    this.archivedVto$.next(vto);
  }

  removeArchivedVto(archivedVtoId: string): void {
    const confirmDeleteDialogRef = this.dialog.open<WarningConfirmDialogComponent, ConfirmDialogData>(
      WarningConfirmDialogComponent,
      {
        data: {
          title: `Are you sure you want to delete this archived ${this.stateService.language.vto.item}?`,
        },
      }
    );

    confirmDeleteDialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          const archivedVtoIds = this.teamVto.archivedVtoIds.filter(id => id !== archivedVtoId);
          this.teamVto.archivedVtoIds = archivedVtoIds;

          const request = this.http
            .patch<Vto>(`${this.vtoApi}/${this.vtoId}`, { archivedVtoIds })
            .pipe(
              catchError((e: unknown) =>
                this.errorService.notify(
                  e,
                  `Could not delete archived ${this.stateService.language.vto.item}.  Please try again.`
                )
              )
            );

          this.spinnerService.spinWhile(request).subscribe();
        }
      },
    });
  }

  broadcastMessage(message: RealtimeMessage): Observable<any> {
    if (this.shouldBroadcast) {
      return this.channelService.sendMessage(this.channelId, message);
    } else {
      return EMPTY;
    }
  }

  subscribeToVtoChannel(teamId: string): void {
    this.channelId = `vto-${this.stateService.companyId}-${teamId}`;
    this.shouldBroadcast = true;
    this.subscribeToMessages();
  }

  subscribeToMessages(): void {
    this.messageSubscription = this.channelService.messageReceived$.subscribe({
      next: message => {
        switch (message.messageType) {
          case VtoMessageType.vto:
            this.executeVtoMessage(message);
            break;
          case VtoMessageType.fetch:
            this.handleMessageWithApiGet(message);
            break;
        }
      },
      error: (err: unknown) => console.error(err),
    });
  }

  executeVtoMessage(message: ReceivedRealtimeMessage) {
    const vto = message.document as Vto;
    if (this.teamVto?._id === vto._id) {
      Object.assign(this.teamVto, vto);
      this.teamVto$.next(this.teamVto);
    } else if (this.sltVto?._id === vto._id) {
      Object.assign(this.sltVto, vto);
      this.sltVto$.next(this.sltVto);
    }
  }

  handleMessageWithApiGet(message: ReceivedRealtimeMessage): void {
    const id = (message.document as Vto)._id;
    if (message.originalMessageType === VtoMessageType.vto && (this.teamVto._id === id || this.sltVto._id === id)) {
      this.getVtoById(id, true).subscribe({
        next: vto => {
          if (this.teamVto._id === id) {
            this.teamVto = vto;
            this.teamVto$.next(vto);
          } else if (this.sltVto._id === id) {
            this.sltVto = vto;
            this.sltVto$.next(vto);
          }
        },
      });
    }
  }

  destroyVtoChannel() {
    this.messageSubscription.unsubscribe();
  }
}
