<button
  class="menu-button"
  mat-menu-item
  [matMenuTriggerFor]="settingsMenu"
  *ngIf="stateService.isManagerOrAbove; else nonManager">
  <terra-icon icon="settings" class="menu-icon" />
  <span class="menu-text">{{ SettingsMenuItem.Settings }}</span>
</button>

<ng-template #nonManager>
  <button class="menu-button" mat-menu-item [matMenuTriggerFor]="userSettingsMenu">
    <terra-icon icon="settings" class="menu-icon" />
    <span class="menu-text">{{ SettingsMenuItem.Settings }}</span>
  </button>
</ng-template>

<mat-menu #settingsMenu="matMenu" class="settings-menu">
  <button mat-menu-item [matMenuTriggerFor]="userSettingsMenu">{{ SettingsMenuItem.UserSettings }}</button>
  <button mat-menu-item [matMenuTriggerFor]="companySettingsMenu">{{ SettingsMenuItem.CompanySettings }}</button>
</mat-menu>

<mat-menu #userSettingsMenu="matMenu" class="settings-menu">
  <ng-container *ngFor="let route of userSettingsRoutes">
    <a
      mat-menu-item
      (click)="onMenuClick(route.path)"
      class="secondary-menu-item"
      routerLinkActive="active"
      [class.feature-unavailable]="route.data.featureName && !(route.data.featureName | isFeatureAvailable | ngrxPush)">
      {{ route.title }}
    </a>
  </ng-container>
</mat-menu>

<mat-menu #companySettingsMenu="matMenu" class="settings-menu">
  <ng-container *ngFor="let route of companySettingsRoutes">
    <a mat-menu-item (click)="onMenuClick(route.path)" class="secondary-menu-item" routerLinkActive="active">
      {{ route.title }}
    </a>
  </ng-container>
</mat-menu>
