import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';

import { ButtonComponent } from '../../../../../buttons/button/button.component';
import { CardModule } from '../../../../../cards/card.module';
import { Plan } from '../../../compare-plans/compare-plans.component';

@Component({
  selector: 'ninety-pricing-card-free',
  standalone: true,
  imports: [CommonModule, CardModule, TerraIconModule, ButtonComponent],
  templateUrl: './pricing-card-free.component.html',
  styleUrls: ['./pricing-card-free.component.scss'],
})
export class PricingCardFreeComponent {
  plan: Plan = {
    name: 'Free',
    price: 0,
    description:
      'Good for teams just starting their journey, the Free plan gives you the tools to learn, strategize, and build the foundation of your organization.',
    features: [
      'Baseline + Org Fit Assessments',
      'Directory',
      'KST: Mastery',
      'Maz for Onboarding',
      'Org Chart',
      'Vision + Strategy',
    ],
    buttonLabel: 'Choose Free',
  };

  selectPlanBtnClick() {
    console.log('clicked');
  }
}
